import React from "react";
import PropTypes from "prop-types";

const RedX = ({ viewBox, className, height, width, fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    width={width}
    height={height}
    className={className}
    viewBox={viewBox}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill="#E85944"
    />
    <rect x="1" y="1" width="8" height="8" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.45105 7.05544C2.30978 7.19671 2.30978 7.42576 2.45105 7.56703C2.59232 7.70831 2.82137 7.70831 2.96265 7.56703L5.00904 5.52064L7.05544 7.56704C7.19671 7.70831 7.42576 7.70831 7.56703 7.56704C7.70831 7.42576 7.70831 7.19671 7.56703 7.05544L5.52064 5.00904L7.56704 2.96265C7.70831 2.82137 7.70831 2.59232 7.56704 2.45105C7.42576 2.30977 7.19671 2.30977 7.05544 2.45105L5.00904 4.49744L2.96265 2.45105C2.82137 2.30978 2.59232 2.30978 2.45105 2.45105C2.30977 2.59232 2.30977 2.82137 2.45105 2.96265L4.49744 5.00904L2.45105 7.05544Z"
      fill="white"
    />
  </svg>
);

RedX.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  id: PropTypes.string.isRequired
};

RedX.defaultProps = {
  className: "",
  height: "10",
  width: "10",
  viewBox: "0 0 10 10",
  fill: "none"
};

export default RedX;
