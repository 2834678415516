import { MOCK } from "utils/constants";
import * as documents from "./documents";
import * as documentsMock from "./documents.mock";

export const getDocuments = MOCK
  ? documentsMock.getDocuments
  : documents.getDocuments;

export const getDocumentsType = MOCK
  ? documentsMock.getDocumentsType
  : documents.getDocumentsType;

export const getBalancete = MOCK
  ? documentsMock.getBalancete
  : documents.getBalancete;

export const searchBalancetes = MOCK
  ? documentsMock.searchBalancetes
  : documents.searchBalancetes;
