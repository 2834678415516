import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const circleIcon = (active, id) => {
  if (active === null)
    return (
      <Styled.GrayCircle id={`${id}-no-icon`} data-testid={`${id}-no-icon`} />
    );
  if (active)
    return (
      <Styled.Check
        id={`${id}-success-icon`}
        data-testid={`${id}-success-icon`}
      />
    );
  return (
    <Styled.RedX id={`${id}-error-icon`} data-testid={`${id}-error-icon`} />
  );
};

const showItens = (showOnlyError, active) => {
  if (!showOnlyError) return true;
  if (showOnlyError && active !== false) return false;
  return true;
};

const ErrorInput = ({ children, active, noIcon, showOnlyError, id }) => (
  <>
    {showItens(showOnlyError, active) && (
      <Styled.Container
        id={`${id}-cotainer-error`}
        data-testid={`${id}-cotainer-error`}
      >
        {!noIcon && circleIcon(active, id)}
        <Styled.TextError
          active={active}
          noMargin={noIcon}
          id={`${id}-text-error`}
          data-testid={`${id}-text-error`}
        >
          {children}
        </Styled.TextError>
      </Styled.Container>
    )}
  </>
);

ErrorInput.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  active: PropTypes.bool,
  noIcon: PropTypes.bool,
  showOnlyError: PropTypes.bool,
  id: PropTypes.string
};

ErrorInput.defaultProps = {
  active: null,
  noIcon: false,
  showOnlyError: false,
  id: "icon"
};

export default ErrorInput;
