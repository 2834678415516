/* eslint-disable no-unused-vars */
import { axiosInstance } from "../servicesSetupMock";

export const getExtract = async (empreendimentoId, contratoSap) => {
  const { extract } = await axiosInstance();
  return extract;
};

export const getEmpreendimentos = async (contratoSap) => {
  const { empreendimentos } = await axiosInstance();
  return empreendimentos;
};

export const getPerfil = async (empreendimentoId, contratoSap) => {
  const { perfil } = await axiosInstance();
  return perfil;
};

export const getDados = async (empreendimentoId, contratoSap) => {
  const { usuario } = await axiosInstance();
  return usuario;
};

export const getTermInformation = async () => {
  const { term } = await axiosInstance();
  return { status: 200, data: term };
};

const sleep = (time) => {
  return new Promise((ac, re) => setTimeout(() => {ac()}, time));
}

export const sendEmailToSignatarios = async () => {
  const { term } = await axiosInstance();
  await sleep(5000);
  return { status: 200, data: term };
};

export const getTermInformationUrlAssinatura = async (contratoSap) => {
  const { termUrlAssinatura } = await axiosInstance();
  return termUrlAssinatura;
};

export const getBoletoPDF = async (contratoSap) => {
  const { termUrlAssinatura } = await axiosInstance();
  return termUrlAssinatura;
};

