import React from "react";
import PropTypes from "prop-types";

const Check2 = ({ viewBox, height, width, fill, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.29997 5.96439L1.98747 4.65189C1.91815 4.58171 1.82361 4.54221 1.72497 4.54221C1.62633 4.54221 1.53179 4.58171 1.46247 4.65189C1.31622 4.79814 1.31622 5.03064 1.46247 5.17689L3.03372 6.74814C3.17997 6.89439 3.41622 6.89439 3.56247 6.74814L7.53747 2.77689C7.68372 2.63064 7.68372 2.39814 7.53747 2.25189C7.46815 2.18171 7.37361 2.14221 7.27497 2.14221C7.17633 2.14221 7.08179 2.18171 7.01247 2.25189L3.29997 5.96439Z"
      fill="white"
      stroke="white"
      strokeWidth="1.2"
    />
  </svg>
);

Check2.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string
};

Check2.defaultProps = {
  height: "9",
  width: "9",
  viewBox: "0 0 9 9",
  fill: "none"
};

export default Check2;
