import styled from "styled-components";
import { Accordion as AccordionBase } from "@company-mrv/mrv-design-system/components";

export const Accordion = styled(AccordionBase)`
  > button > div {
    color: #162c2b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
  }

  > div {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 0;
    &:last-of-type {
      border-bottom-right-radius: 0;
    }
  }
`;

export const AccordionItem = styled(AccordionBase.Item)``;

export const TitleInternal = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

export const Link = styled.a`
  color: rgb(7, 157, 86);
  &:visited,
  &:hover,
  &:active {
    color: rgb(7, 157, 86);
  }
`;

export default {
  Accordion,
  AccordionItem,
  TitleInternal,
  Link
};
