import React from "react";
import PropTypes from "prop-types";
import { CurveWrapper } from "components";
import * as Styled from "./styles";

const Page = ({
  curve,
  curvePositionMobile,
  curvePositionTablet,
  curvePositionDesktop,
  children,
  ...props
}) => {
  return (
    <Styled.Page {...props}>
      {curve && (
        <CurveWrapper
          curvePositionDesktop={curvePositionDesktop}
          curvePositionTablet={curvePositionTablet}
          curvePositionMobile={curvePositionMobile}
        >
          {children}
        </CurveWrapper>
      )}
      {!curve && children}
    </Styled.Page>
  );
};

Page.propTypes = {
  curve: PropTypes.bool,
  children: PropTypes.node.isRequired,
  curvePositionMobile: PropTypes.string,
  curvePositionTablet: PropTypes.string,
  curvePositionDesktop: PropTypes.string
};

Page.defaultProps = {
  curve: true,
  curvePositionMobile: "50%",
  curvePositionTablet: "50%",
  curvePositionDesktop: "50%"
};

export default Page;
