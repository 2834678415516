import React from "react";
import PropTypes from "prop-types";

const Icon = ({ pathFill, ...rest }) => {
  return (
    <svg
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={pathFill}
        fillRule="evenodd"
        d="M24.1559 30.2027H21.4189V29.5552C21.4189 23.5226 26.3263 18.6152 32.3589 18.6152H37.0856C36.3762 25.1221 30.8483 30.2027 24.1559 30.2027ZM27.1105 2.83775H28.2971C27.6246 6.60629 24.3233 9.47714 20.3637 9.47714H19.1771C19.8496 5.70814 23.1509 2.83775 27.1105 2.83775ZM18.5811 20.0426H15.8436C9.15128 20.0426 3.62334 14.9616 2.91437 8.45508H7.64112C13.6732 8.45508 18.5811 13.3625 18.5811 19.3946V20.0426ZM32.3589 15.7774C27.9041 15.7774 23.9388 17.9043 21.4189 21.1937V19.3946V19.3246H21.417C21.4042 16.764 20.6896 14.3666 19.4552 12.3149H20.3637C26.3722 12.3149 31.2602 7.42687 31.2602 1.41888V0H27.1105C21.8242 0 17.4068 3.78414 16.4211 8.78568C14.0355 6.80777 10.975 5.61733 7.64112 5.61733H0V7.0362C0 15.7722 7.10762 22.8803 15.8436 22.8803H18.5811V29.5552V33.0404V37.4867H21.4189V33.0404H24.1559C32.8924 33.0404 40 25.9328 40 17.1963V15.7774H32.3589Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

Icon.propTypes = {
  pathFill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Icon.defaultProps = {
  pathFill: "#242625",
  width: "40",
  height: "38"
};

export default Icon;
