import styled from "styled-components";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { ButtonBase } from "components";
import { media } from "utils/styles/breakpoints";

const BORDER_LEFT_WIDTH = "4px";

export const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${tokens.shadowLevel1};
  border-radius: ${tokens.borderRadiusMedium};
`;

export const EndContent = styled.div`
  display: flex;
`;

export const Content = styled.div.attrs(props => ({
  transitionDelay: props.transitionDelay,
  state: props.state,
  isOpen: props.isOpen
}))`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 0;
  padding: 0 ${tokens.spacingInsetSmall} 0
    calc(${tokens.spacingInsetSmall} - ${BORDER_LEFT_WIDTH});

  background-color: ${tokens.colorNeutralXxlight};
  transition: max-height ${props => props.transitionDelay}ms ease-in-out,
    padding ${props => props.transitionDelay}ms linear;

  &:last-of-type {
    border-bottom: none;
    border-bottom-right-radius: ${tokens.borderRadiusMedium};
  }

  ${props =>
    props.isOpen &&
    `
    padding: ${tokens.spacingInsetSmall};
    border-bottom: ${tokens.borderWidthThin} solid ${tokens.colorNeutralLight};
    padding-left: calc(${tokens.spacingInsetSmall} - ${BORDER_LEFT_WIDTH});
    max-height: 130rem;
  `}

  ${props => `border-left: ${BORDER_LEFT_WIDTH} solid ${props.borderColor};`};
`;

export const ArrowIcon = styled.img`
  transform: rotate(0deg);
  transition: transform ${props => props.transitionDelay}ms
    cubic-bezier(1, 0.38, 0, 0.73);
`;

export const Toggle = styled(ButtonBase).attrs(props => ({
  transitionDelay: props.transitionDelay,
  isOpen: props.isOpen
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border: none;
  cursor: pointer;
  outline: none;

  padding: ${tokens.spacingSquishSmall};
  background-color: ${tokens.colorNeutralXxlight};
  border-bottom: ${tokens.borderWidthThin} solid ${tokens.colorNeutralLight};

  &:hover {
    background-color: ${tokens.colorNeutralXlight};
  }

  [data-whatintent="keyboard"] &:focus {
    background-color: ${tokens.colorNeutralXlight};
  }

  &:first-of-type {
    border-top-left-radius: ${tokens.borderRadiusMedium};
    border-top-right-radius: ${tokens.borderRadiusMedium};
  }

  &:last-of-type {
    border-bottom: ${tokens.borderWidthThin} solid transparent;
    border-bottom-left-radius: ${tokens.borderRadiusMedium};
    border-bottom-right-radius: ${tokens.borderRadiusMedium};

    transition: border-bottom-left-radius ${props => props.transitionDelay}ms
        ease-in-out ${props => props.transitionDelay},
      border-bottom-right-radius ${props => props.transitionDelay}ms ease-in-out
        ${props => props.transitionDelay},
      border-bottom ${props => props.transitionDelay} ease-in-out
        ${props => props.transitionDelay};

    ${props =>
      props.isOpen &&
      `
      transition: border-bottom-left-radius 0ms, border-bottom-right-radius 0ms, border-bottom;
      border-bottom: ${tokens.borderWidthThin} solid ${tokens.colorNeutralLight};
      border-bottom-left-radius: ${tokens.borderRadiusNone};
      border-bottom-right-radius: ${tokens.borderRadiusNone};
    `}
  }

  ${props =>
    props.isOpen &&
    `
    ${ArrowIcon} {
      transform: rotate(180deg);
    }
  `}
`;

export const TitleContainer = styled.div`
  width: 100%;
  font-weight: ${tokens.fontWeightSemibold};
  font-size: ${tokens.fontSizeSmall};
  color: ${tokens.colorNeutralXxdark};

  ${media.forTabletUp} {
    width: 100%;
  }
`;
