import React from "react";
import { ButtonBase as Button } from "@company-mrv/mrv-design-system/components";
import PropTypes from "prop-types";

const ButtonBase = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
};

ButtonBase.propTypes = {
  children: PropTypes.node.isRequired
};

ButtonBase.defaultProps = {};

export default ButtonBase;
