import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const Checkbox = React.forwardRef(({ className, checked, ...props }, ref) => (
  <Styled.CheckboxContainer className={className}>
    <Styled.HiddenCheckbox checked={checked} {...props} ref={ref} />
    <Styled.StyledCheckbox checked={checked}>
      <Styled.Icon viewBox="0 0 22 22">
        <polyline points="16 6 10 14 6 10" />
      </Styled.Icon>
    </Styled.StyledCheckbox>
  </Styled.CheckboxContainer>
));

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string
};

Checkbox.defaultProps = {
  className: ""
};

export default Checkbox;
