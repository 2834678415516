import styled from "styled-components/macro";
import { Button as BaseButton } from "@company-mrv/mrv-design-system/components";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { getThemePropertyValue } from "utils/pluginHelpers";

export const Button = styled(BaseButton)`
  flex: none;
  white-space: nowrap;
  overflow: initial;
  padding: ${tokens.spacingInlineSmall} 0 15px 0;
  margin-right: ${tokens.spacingInsetSmall};

  span {
    ${props =>
      !props.active &&
      `color: ${getThemePropertyValue(
        "tab.Button.color",
        tokens.colorNeutralXdark
      )}`}
  }

  [data-whatintent="mouse"] &:hover,
  [data-whatinput="keyboard"] &:focus {
    border-color: transparent;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const FakeButton = styled.div`
  flex: none;
  white-space: nowrap;
  overflow: initial;
  cursor: default;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  padding: ${tokens.spacingInlineSmall} 0 15px 0;
  margin-right: ${tokens.spacingInsetSmall};
  color: ${tokens.colorNeutralMedium};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  [data-whatintent="mouse"] &:hover,
  [data-whatinput="keyboard"] &:focus {
    border-color: transparent;
    outline: 0;
    background-color: transparent;
  }
`;
