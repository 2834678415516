import styled from "styled-components/macro";
import tokens from "@company-mrv/mrv-design-system/tokens";

const colorsMap = {
  primary: tokens.colorBrandPrimaryPure,
  orange: tokens.colorBrandSecondaryPure,
  default: tokens.colorNeutralLight
};

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${tokens.colorNeutralXxlight};
  border-radius: 100%;
  border: ${tokens.borderWidthMedium} solid;
  border-color: ${props => props.colorHex || colorsMap[props.color]};
`;
