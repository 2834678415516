import React from "react";
import { LoadingIndicator } from "@company-mrv/mrv-design-system/components";

import * as Styled from "./styles";

const LoadingWrapper = () => (
  <Styled.Container>
    <LoadingIndicator />
  </Styled.Container>
);

export default LoadingWrapper;
