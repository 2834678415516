import React from "react";
import { ThemeWrapper } from "@company-mrv/core-components-design-system";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const Header = ({ onClickBack, title, mobileBreak, mobileBreakMax }) => {
  return (
    <ThemeWrapper>
      <Styled.Background role="background">
        <Styled.Container
          mobileBreak={mobileBreak}
          mobileBreakMax={mobileBreakMax}
        >
          <Styled.ButtonBack role="buttonBack">
            <Styled.ButtonContainer
              mobileBreak={mobileBreak}
              role="buttonBackOnClick"
              onClick={onClickBack}
            >
              <Styled.ButtonBackIcon role="buttonBackIcon" />
              <Styled.ButtonBackLabel role="buttonBackLabel">
                {title}
              </Styled.ButtonBackLabel>
            </Styled.ButtonContainer>
          </Styled.ButtonBack>
        </Styled.Container>
      </Styled.Background>
    </ThemeWrapper>
  );
};

Header.propTypes = {
  onClickBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  mobileBreak: PropTypes.string,
  mobileBreakMax: PropTypes.string
};

Header.defaultProps = {
  mobileBreak: "312px",
  mobileBreakMax: undefined
};

export default Header;
