import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const Label = React.forwardRef(({ children, ...props }, ref) => (
  <Styled.Label ref={ref} {...props}>
    {children}
  </Styled.Label>
));

Label.propTypes = {
  children: PropTypes.node.isRequired
};

export default Label;
