import styled, { css } from "styled-components";
import { media } from "utils/styles/breakpoints";
import { wrapper, horizontalContainerPadding } from "utils/styles/grid";

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  flex: 1;
  ${wrapper().styles.maxWidth()}
  ${media.forDesktopUp} {
    ${props =>
      props.spaceGrid &&
      css`
        padding-left: ${horizontalContainerPadding};
        padding-right: ${horizontalContainerPadding};
      `}
  }
`;
