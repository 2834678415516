import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import ModalTermPending from "../ModalTermPending";
import TermPendingContext from "contexts/finance/termPending";
import HomeContext from "contexts/home";
import ContractContext from "contexts/contract";
import AuthContext from "../../contexts/auth";
import moment from "moment/moment";
import {ROUTES, STATUS_TERM_PENDING} from "utils/constants";
import { useHistory } from "react-router-dom";
import { isInHiddenList } from "utils/pluginHelpers";
const AppContent = ({ children }) => {

  const [ isOpen, setIsOpen ] = useState(false);
  const { hasTerm, termInformationLoaded,
    term} = useContext(TermPendingContext);
  const { loadedOnboarding, showOnboarding } = useContext(HomeContext);
  const { selectedContract } = useContext(ContractContext);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const verifyIfDatePass12Hours = (date) => {
    if(!date) return true;
    const dateSaved = moment(date);
    const dateNow = moment();
    return (dateNow.diff(dateSaved, 'hours') > 12);
  }

  const verifyIfTermCanShow = () => {
    return ((term.statusRenegociacao === STATUS_TERM_PENDING.PENDENTE) && hasTerm);
  }

  useEffect(() => {
    if(!isInHiddenList("NegotiationSection") && user && loadedOnboarding && selectedContract && selectedContract.empreendimentoId && termInformationLoaded){
      const canShow = verifyIfDatePass12Hours(localStorage.getItem(`${user.profile.preferred_username}-${selectedContract.empreendimentoId}-term-pending`));
      if(!showOnboarding && canShow && hasTerm && verifyIfTermCanShow(term)) {
        setIsOpen(true);
      }
    }
  }, [term, termInformationLoaded, loadedOnboarding, showOnboarding, selectedContract, user]);

  const updateDateTermPending = () => {
    localStorage.setItem(`${user.profile.preferred_username}-${selectedContract.empreendimentoId}-term-pending`, new Date().toString());
  }

  return (
    <>
      <>{children}</>
      <ModalTermPending isOpen={isOpen} onClose={() => {
        setIsOpen(false);
        updateDateTermPending();
      }} onClickDetails={() => {
        updateDateTermPending();
        setIsOpen(false);
        history.push(ROUTES.TERM_ONBOARDING+"?openModal=true");
      }}></ModalTermPending>
    </>
  );
};

AppContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppContent;
