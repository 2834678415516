/* eslint-disable no-unused-vars */
import { axiosInstance } from "../servicesSetupMock";

const RETURNED_NOTIFICATIONS = 5;
const TYPES = {
  DEFAULT: "todos",
  UNREAD: "nao-lidos"
};

export const getDocumentsType = async () => {
  const { documentsType } = await axiosInstance();

  return documentsType;
};

export const getDocuments = async () => {
  const { documents } = await axiosInstance();

  return documents;
};

export const getBalancete = async () => {
  const { getBalancete } = await axiosInstance();

  return getBalancete;
};

export const searchBalancetes = async () => {
  const { searchBalancetes } = await axiosInstance();

  return searchBalancetes;
};
