import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import instanceAuth from "services/auth";

const AuthContext = React.createContext();

export const AuthContextProvider = ({ enabled, children }) => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (enabled) {
      instanceAuth.getUser().then(userR => {
        setUser(userR);
      });
      instanceAuth.getEvents().addUserLoaded(userR => {
        setUser(userR);
      });
    }
  }, [enabled]);

  const value = React.useMemo(() => {
    const logout = async () => {
      await instanceAuth.logout();
    };

    const signoutRedirectCallback = async () => {
      return instanceAuth.signoutRedirectCallback();
    };

    const login = async () => {
      return instanceAuth.login();
    };

    const loginCallback = async () =>
      new Promise((go, rej) => {
        instanceAuth
          .loginCallback()
          .then(userR => {
            go();
            setUser(userR);
          })
          .catch(err => {
            rej(err);
          });
      });

    const { renewCallback } = instanceAuth;

    const verifyLogin = async () => {
      const userR = await instanceAuth.getUser();
      return !!userR && !!userR.id_token && !userR.expired;
    };

    const getUser = () => instanceAuth.getUser();

    const clear = () => instanceAuth.clear();

    return {
      login,
      logout,
      user,
      getUser,
      verifyLogin,
      loginCallback,
      renewCallback,
      signoutRedirectCallback,
      clear
    };
  }, [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default AuthContext;
