import React from "react";
import PropTypes from "prop-types";

const Icon = ({ pathFill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 32"
      {...rest}
    >
      <path
        fill={pathFill}
        fillRule="evenodd"
        d="M25.77 0c-.271 0-.55.007-.82.03-3.273.226-5.724 2.34-7.076 3.85-1.41-1.452-3.944-3.464-7.24-3.56l-.33-.001C4.653.319.227 4.502.009 10.048c-.2 5.038 3.237 9.295 5.84 12.062 3.393 3.607 8.415 7.256 11.658 9.13a1.782 1.782 0 001.81-.047c3.15-1.986 8.022-5.832 11.288-9.587 2.49-2.871 5.756-7.27 5.364-12.289h-.002C35.548 4.003 31.166 0 25.77 0zm0 3.6c3.498 0 6.338 2.58 6.608 5.996.288 3.674-2.428 7.269-4.492 9.648-2.609 3-6.589 6.282-9.555 8.291-3.062-1.904-7.159-5.02-9.862-7.893-2.159-2.293-5.01-5.77-4.864-9.452.144-3.634 2.96-6.271 6.682-6.271h.243c2.116.06 3.89 1.573 4.759 2.47l2.69 2.771 2.577-2.876c.833-.934 2.547-2.517 4.689-2.666.174-.014.353-.018.525-.018z"
        clipRule="evenodd"
      />
    </svg>
  );
};

Icon.propTypes = {
  pathFill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Icon.defaultProps = {
  pathFill: "#242625",
  width: "36",
  height: "32"
};

export default Icon;
