import TagManager from "react-gtm-module";
import PropTypes from "prop-types";

const pushGTM = (page, category, label, action, value = 0) => {
  const dataLayerObject = {
    event: "userInteraction",
    userInteraction: {
      page,
      action,
      label,
      value,
      category,
    },
  };
  TagManager.dataLayer({
    dataLayer: dataLayerObject,
    dataLayerName: "PageDataLayer",
  });
};

pushGTM.propTypes = {
  page: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.oneOf(["Click"]).isRequired,
  value: PropTypes.number,
};

export default pushGTM;
