import styled from "styled-components/macro";
import colors from "@company-mrv/mrv-design-system/colors";
import tokens from "@company-mrv/mrv-design-system/tokens";

export const Bar = styled.div`
  height: 3px;
  background-color: ${props =>
    props.colorBar ? props.colorBar : colors.greenRegular};
  width: ${props => props.percent}%;
  border-radius: ${tokens.borderRadiusMedium};
`;

export const Container = styled.div`
  height: 7px;
  border: solid 2px ${colors.greyDetails};
  border-radius: ${tokens.borderRadiusMedium};
  background-color: ${colors.greyDetails};
`;
