import React from "react";
import PropTypes from "prop-types";
import StyledIcon from "./styledIcon";

const DownloadOutline = ({ fill, ...props }) => (
  <StyledIcon viewBox="0 0 24 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0591 3.84827C11.0591 3.37979 11.4804 3 12 3C12.5196 3 12.9409 3.37979 12.9409 3.84827V11.1053L15.8929 8.44168C16.2606 8.11022 16.8566 8.11022 17.2243 8.44168C17.5919 8.77314 17.5919 9.31053 17.2243 9.64199L12.661 13.7519L12.6445 13.7667C12.4705 13.9157 12.24 13.9992 12 14C11.7584 14.0003 11.526 13.9167 11.3508 13.7667L11.3343 13.7519L6.77575 9.64199C6.53793 9.42757 6.44505 9.11506 6.5321 8.82216C6.61915 8.52927 6.8729 8.30049 7.19777 8.22201C7.52265 8.14353 7.86928 8.22727 8.1071 8.44168L11.0591 11.1053V3.84827ZM18.3488 13.9396C18.3488 13.4207 18.7184 13 19.1744 13C19.6304 13 20 13.4207 20 13.9396V17.1812C20 18.738 18.8911 20 17.5232 20H6.47678C5.10889 20 4 18.738 4 17.1812V13.9396C4 13.4207 4.36963 13 4.82559 13C5.28156 13 5.65119 13.4207 5.65119 13.9396V17.1812C5.65119 17.7001 6.02082 18.1208 6.47678 18.1208H17.5232C17.9792 18.1208 18.3488 17.7001 18.3488 17.1812V13.9396Z"
      fill={fill}
    />
  </StyledIcon>
);

DownloadOutline.propTypes = {
  fill: PropTypes.string
};

DownloadOutline.defaultProps = {
  fill: "#162C2B"
};

export default DownloadOutline;
