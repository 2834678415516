import styled from "styled-components/macro";
import typography from "@company-mrv/mrv-design-system/typography";
import colors from "@company-mrv/mrv-design-system/colors";
import tokens from "@company-mrv/mrv-design-system/tokens";

// TODO
// eslint-disable-next-line import/prefer-default-export
export const Label = styled.label`
  margin: 0;
  font-weight: ${tokens.fontWeightRegular};
  color: ${colors.greyRegular};
  ${typography.small.styles};
`;
