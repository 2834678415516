import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@company-mrv/mrv-design-system/components";
import { getThemeName } from "utils/pluginHelpers";
import * as Styled from "./styles";

const Tab = React.forwardRef(
  ({ index, label, active, onClick, disabled, ...props }, ref) => {
    if (disabled) {
      return (
        <Tooltip content="Em breve" placement="bottom">
          <Styled.FakeButton>{label}</Styled.FakeButton>
        </Tooltip>
      );
    }

    return (
      <Styled.Button
        index={index}
        ref={ref}
        role="tab"
        variant="underline"
        onClick={event => onClick(event, index)}
        active={active}
        theme={getThemeName()}
        {...props}
      >
        {label}
      </Styled.Button>
    );
  }
);

Tab.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

Tab.defaultProps = {
  active: false,
  onClick: undefined,
  disabled: false
};

export default Tab;
