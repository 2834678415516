import styled from "styled-components/macro";
import tokens from "@company-mrv/mrv-design-system/tokens";

export const Spinner = styled.div`
  @keyframes loading {
    0% {
      -webkit-transform: translate(0%, 0%) rotate(0deg);
      transform: translate(0%, 0%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(0%, 0%) rotate(360deg);
      transform: translate(0%, 0%) rotate(360deg);
    }
  }
  animation: loading 1.5s linear infinite;
  border-color: ${tokens.colorNeutralXdark};
  width: 32px;
  height: 32px;
  border-width: 2px;
  border-style: solid;
  border-top-color: transparent;
  border-radius: 50%;
  margin: 8px;
`;
