import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const H1 = React.forwardRef(({ children, ...props }, ref) => (
  <Styled.H1 ref={ref} {...props}>
    {children}
  </Styled.H1>
));

H1.propTypes = {
  children: PropTypes.node.isRequired
};

export default H1;
