import styled, { keyframes } from 'styled-components';
import tokens from '@company-mrv/mrv-design-system/tokens';
import { Modal as BaseModal, Button } from '@company-mrv/mrv-design-system/components';
import { WhatsApp } from '@company-mrv/mrv-design-system/icons';
import { NoteSearch } from 'assets/svg';

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

const TransformAnimation = keyframes`
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
`;

export const Modal = styled(BaseModal)`
  .modal-content {
    position: relative;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    width: 328px;
    padding: 24px;
    text-align: center;
    animation: ${TransformAnimation} 0.2s ease-out;
    display: flex;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  font-weight: ${tokens.fontWeightBold};
  font-size: ${tokens.fontSizeSmall};
  line-height: ${tokens.lineHeightMedium};
  margin-bottom: ${({ marginB }) => marginB && marginB};
`;

export const SubTitle = styled.div`
  font-weight: ${tokens.fontWeightRegular};
  font-size: ${tokens.fontSizeXxSmall};
  line-height: ${tokens.lineHeightDefault};
  margin: 5px 6px 24px 6px;
  justify-self: flex-start;
`;

export const ButtonCustom = styled(Button)`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  > span > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  margin-bottom: 8px;
`;

export const ButtonCustomVariantG = styled(Button)`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 8px;
  background-color: rgba(84, 188, 130, 0.16);
  color: #079D56;
  [data-whatintent='mouse'] &:hover, [data-whatintent='keyboard'] &:focus, &:focus, &:hover {
    background-color: rgba(84, 188, 130, 0.50) !important;
  }
`;

export const ContainerImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
`;

export const WhatsAppCustom = styled(WhatsApp)`
  display: inline-block !important;
  margin: 0 0 0 10px !important;
`;

export const HeaderCustom = styled(BaseModal.Header)`
  > button > svg > path {
    fill: white;
  }
`;

export const ContentTop = styled.div``;

export const ImageTop = styled.img.attrs(() => ({
  src: NoteSearch,
}))`
  width: 140px;
  height: 140px;
  margin-bottom: 16px;
  margin-top: 16px;
`;
