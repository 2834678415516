import styled from "styled-components/macro";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { getThemePropertyValue } from "utils/pluginHelpers";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${getThemePropertyValue(
    "appContent.Container.backgroundColor",
    tokens.colorBackground01
  )};
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;
