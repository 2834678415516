import styled from "styled-components/macro";
import colors from "@company-mrv/mrv-design-system/colors";

export const Container = styled.div`
  background: ${colors.white};
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
