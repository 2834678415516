import React from "react";
import Helmet from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { MediaQueryProvider } from "react-media-query-hoc";
import { queries } from "utils/styles/breakpoints";
import { AppContent, FullPageLoading, MeuApeWrapper, ModalTermPendingControll } from "components";
import { Modal } from "@company-mrv/mrv-design-system/components";
import Routes from "routes";
import ErrorBoundary from "utils/errorBoundary";
import plugin from "utils/currentPlugin";
import "what-input";
import "intersection-observer";
import { reactPlugin } from "services/appInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import config from "./default";
import MainContexts from "./mainContexts";
import { ThemeProvider, getTheme } from "@company-mrv/core-components-design-system";

// sets Modal.appElement to accessibility
Modal.setAppElement(document.getElementById("root"));

const pluginHasConfigHelmet = plugin?.config?.app;
const configHelmet =
  (pluginHasConfigHelmet && { ...config.app, ...pluginHasConfigHelmet }) ||
  config.app;

const App = () => (
  <>
    <Helmet {...configHelmet} />
    <BrowserRouter>
      <AppInsightsErrorBoundary
        onError={() => <h1>Something is wrong</h1>}
        appInsights={reactPlugin}
      >
        <MediaQueryProvider queries={queries}>
          <React.Suspense fallback={<FullPageLoading />}>
            <MainContexts>
              <AppContent>
                <ErrorBoundary>
                  <ThemeProvider themeSelected={getTheme('MRV')}>
                    <MeuApeWrapper>
                      <ModalTermPendingControll>
                        <Routes />
                      </ModalTermPendingControll>
                    </MeuApeWrapper>
                  </ThemeProvider>
                </ErrorBoundary>
              </AppContent>
            </MainContexts>
          </React.Suspense>
        </MediaQueryProvider>
      </AppInsightsErrorBoundary>
    </BrowserRouter>
  </>
);

export default App;
