import React, { useEffect } from "react";
import PropTypes from "prop-types";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { useInView } from "react-intersection-observer";
import { getThemePropertyValue } from "utils/pluginHelpers";
import * as Styled from "./styles";
import AnimatedBorderClickedContext from "./animatedBorderClickedContext";

const CLICKED_STATUS = {
  NOT_CLICKED: "not_clicked",
  CLICKED: "clicked"
};

const SvgBorder = ({ width, height, strokeWidth, color, delay, animate }) => {
  const translation = strokeWidth / 2;
  const transform = `translate(${translation}, ${translation})`;
  const rectWidth = width - strokeWidth;
  const rectHeight = height - strokeWidth;
  const radius = 8;

  const borderRadiusCircleLength = 2 * 3.14 * radius;
  const borderLength =
    rectWidth * 2 + rectHeight * 2 - radius * 8 + borderRadiusCircleLength;
  return (
    <Styled.LinearBorderSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      borderLength={borderLength}
      delay={delay}
      animate={animate}
    >
      <g transform={transform}>
        <rect
          x="0"
          y="0"
          width={rectWidth}
          height={rectHeight}
          stroke={color}
          rx={radius}
          strokeWidth={strokeWidth}
          fill="transparent"
        />
      </g>
    </Styled.LinearBorderSvg>
  );
};

const AnimatedBorder = ({
  children,
  elementId,
  onEnable,
  animatedBorderWidth,
  animatedBorderColor,
  animatedBorderDelay,
  className,
  dimensionChange,
  rendered,
  onAnimate,
  pointer,
  continueShow,
  ignoreInView
}) => {
  const {
    elementsIdsClicked,
    setElementsIdsClicked,
    elementsIdsAlreadyShowed,
    setElementsIdsAlreadyShowed
  } = AnimatedBorderClickedContext();

  const [ref, inView] = useInView({
    triggerOnce: true
  });

  const containerRef = React.useRef();

  const elementsIdsClickedRef = React.useRef();
  elementsIdsClickedRef.current = elementsIdsClicked;

  const [shouldAnimate, setShouldAnimate] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  const newClickedStatus = (elmId, oldClickedIds, clickedStatus) => {
    const newClickedIds = {
      ...oldClickedIds,
      [elmId]: clickedStatus
    };
    return newClickedIds;
  };

  useEffect(() => {
    if (continueShow && shouldAnimate) {
      setElementsIdsAlreadyShowed(olds => ({
        ...olds,
        [elementId]: true
      }));
    }
  }, [continueShow, shouldAnimate]);

  const onAnimateRef = React.useRef();
  onAnimateRef.current = onAnimate;

  const newClickedStatusRef = React.useRef();
  newClickedStatusRef.current = newClickedStatus;

  const setClicked = () => {
    setElementsIdsClicked(oldClickedIds => {
      if (oldClickedIds[elementId] === CLICKED_STATUS.NOT_CLICKED) {
        return newClickedStatus(
          elementId,
          oldClickedIds,
          CLICKED_STATUS.CLICKED
        );
      }
      return oldClickedIds;
    });
  };

  React.useEffect(() => {
    const initClickedStatus = () => {
      setElementsIdsClicked(oldClickedIds => {
        if (oldClickedIds[elementId]) {
          return oldClickedIds;
        }
        setInitialized(true);
        return newClickedStatusRef.current(
          elementId,
          oldClickedIds,
          CLICKED_STATUS.NOT_CLICKED
        );
      });
    };
    initClickedStatus();
  }, [elementId, setInitialized, setElementsIdsClicked]);

  React.useEffect(() => {
    const showAnimatedBorder =
      initialized &&
      elementsIdsClickedRef.current[elementId] === CLICKED_STATUS.NOT_CLICKED;
    if (showAnimatedBorder && (inView || ignoreInView)) {
      setTimeout(() => {
        setShouldAnimate(true);
      }, 50);
    }
  }, [initialized, elementId, setShouldAnimate, inView, ignoreInView]);

  React.useEffect(() => {
    onAnimateRef.current();
  }, [shouldAnimate]);

  React.useEffect(() => {
    if (inView) {
      onEnable();
    }
  }, [inView, onEnable]);

  React.useEffect(() => {
    const reRenderBorder = () => {
      if (rendered) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        setWidth(containerWidth);
        setHeight(containerHeight);
      }
    };
    reRenderBorder();
  }, [dimensionChange, rendered, setWidth, setHeight]);

  return (
    <div className={className} ref={ref} style={{ width: "100%" }}>
      <Styled.LinearBorderContainer
        ref={containerRef}
        onClick={() => {
          setClicked();
        }}
        pointer={pointer}
      >
        {rendered && width > 0 && height > 0 && (
          <SvgBorder
            width={width}
            height={height}
            color={animatedBorderColor}
            strokeWidth={animatedBorderWidth}
            delay={animatedBorderDelay}
            animate={
              shouldAnimate ||
              (continueShow && elementsIdsAlreadyShowed[elementId])
            }
          />
        )}
        {children}
      </Styled.LinearBorderContainer>
    </div>
  );
};

AnimatedBorder.propTypes = {
  children: PropTypes.node.isRequired,
  elementId: PropTypes.string.isRequired,
  animatedBorderWidth: PropTypes.number,
  animatedBorderColor: PropTypes.string,
  animatedBorderDelay: PropTypes.number,
  onEnable: PropTypes.func,
  className: PropTypes.string,
  dimensionChange: PropTypes.string,
  rendered: PropTypes.bool.isRequired,
  onAnimate: PropTypes.func,
  pointer: PropTypes.bool,
  continueShow: PropTypes.bool,
  ignoreInView: PropTypes.bool
};

AnimatedBorder.defaultProps = {
  continueShow: false,
  animatedBorderWidth: 2,
  animatedBorderColor: getThemePropertyValue(
    "animatedBorder.LinearBorderSvg.animatedBorderColor",
    tokens.colorBrandSecondaryPure
  ),
  animatedBorderDelay: 0.5,
  onEnable: () => {},
  className: "",
  onAnimate: () => {},
  dimensionChange: "",
  pointer: false,
  ignoreInView: false
};

SvgBorder.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  animate: PropTypes.bool
};

SvgBorder.defaultProps = {
  animate: false
};

export default AnimatedBorder;
