import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  trackException,
  trackTraceException
} from "services/trackInsights";
import { getOnboardingInformation } from "services/home";

const HomeContext = React.createContext({
  loading: true,
  setLoading: () => {},
  loadOnboarding: () => {},
  loadedOnboarding: false,
  showOnboarding: false,
  showIndicacao: false
});

const TRY_RETRIES_COUNT_TOTAL = 2;

export const HomeContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showIndicacao, setShowIndicacao] = useState(false);
  const [loadedOnboarding, setLoadedOnboarding] = useState(false);
  const [countTryOnboarding, setCountTryOnboarding] = useState(0);

  const loadOnboarding = async () => {
    try {
      const onboardingResponse = await getOnboardingInformation();
      if (onboardingResponse.status !== 204) {
        setShowOnboarding(onboardingResponse.data.onboarding);
        setShowIndicacao(onboardingResponse.data.indicacao);
      }
      setLoadedOnboarding(true);
    } catch (error) {
      const newCount = countTryOnboarding+1;
      setCountTryOnboarding(newCount);
      if(newCount === TRY_RETRIES_COUNT_TOTAL) {
        setShowOnboarding(false);
      }
      trackException(error);
      trackTraceException("loadOnboarding", error);
    }
  };

  useEffect(() => {
    if(countTryOnboarding < TRY_RETRIES_COUNT_TOTAL && !loading && !loadedOnboarding) {
      loadOnboarding();
    }
  }, [countTryOnboarding, setCountTryOnboarding, loading, loadedOnboarding]);

  const value = React.useMemo(
    () => ({
      loading,
      setLoading,
      loadOnboarding,
      showOnboarding,
      loadedOnboarding,
      showIndicacao
    }),
    [loading, loadOnboarding, showOnboarding, loadedOnboarding, showIndicacao]
  );

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

HomeContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default HomeContext;
