import AuthService from "services/auth";
import { appInsights } from "services/appInsights";

export const trackException = async ex => {
  appInsights.trackException({ exception: ex });
};

export const trackTrace = async (functionName, message) => {
  const user = await AuthService.getUser();
  if (user && user.profile) {
    appInsights.trackTrace({
      message: `CPF: ${user.profile.preferred_username} - Função: ${functionName} - Mensagem: ${message}`
    });
  } else {
    appInsights.trackTrace({
      message: `Função: ${functionName} - Mensagem: ${message}`
    });
  }
};

export const trackTraceException = async (functionName, ex) => {
  const user = await AuthService.getUser();
  if (user && user.profile) {
    appInsights.trackTrace({
      message: `CPF: ${user.profile.preferred_username} - Função: ${functionName} - Erro: ${ex.message}`,
      properties: { ex }
    });
  } else {
    appInsights.trackTrace({
      message: `Função: ${functionName} - Erro: ${ex.message}`,
      properties: { ex }
    });
  }
};
