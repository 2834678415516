import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./App.css";
import "./fonts.css";
import TagManager from "react-gtm-module";
import { GTM_ID } from "utils/constants";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

const tagManagerArgs = {
  gtmId: GTM_ID,
  dataLayerName: "PageDataLayer"
};
TagManager.initialize(tagManagerArgs);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
