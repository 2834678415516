import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const P = React.forwardRef(({ children, ...props }, ref) => (
  <Styled.P ref={ref} {...props}>
    {children}
  </Styled.P>
));

P.propTypes = {
  children: PropTypes.node.isRequired
};

export default P;
