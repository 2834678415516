import styled from "styled-components/macro";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { media } from "utils/styles/breakpoints";

export const Container = styled.div`
  ${media.forTabletUp} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  align-items: start;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`;

export const ContainerH1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TextMessage = styled.div`
  font-size: ${tokens.fontSizeXSmall};
  margin-top: 16px;
  text-align: center;
  line-height: 28px;
  color: ${tokens.colorNeutralXxdark};
`;

export const ContainerMessage = styled.div`
  width: auto;
  height: auto;
  padding: 40px;
  max-width: 480px;
`;
