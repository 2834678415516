import { API_FINANCEIRO_NAME } from "utils/constants";
import { axiosInstance } from "../servicesSetup";

export const getExtract = async (empreendimentoId, contratoSap) => {
  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  const { data } = await instance.get(
    `/empreendimentos/${empreendimentoId}/extrato`,
    {
      params: {
        refreshCache: true,
        contratoSap
      }
    }
  );

  return data;
};

export const getEmpreendimentos = async (contratoSap) => {
  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  const { data } = await instance.get(
    `/empreendimentos`,
    {
      params: {
        refreshCache: true,
        codigoSap: contratoSap
      }
    }
  );

  return data;
};

export const getPerfil = async (empreendimentoId, contratoSap) => {
  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  const { data } = await instance.get(
    `/contratos/${contratoSap}/perfil-financeiro`,
    {
      params: {
        refreshCache: true,
        idEmpreendimento: empreendimentoId
      },
      headers: {
      "api-version": "2.0"
      }
    }
  );
  return data;
};

export const getDados = async () => {
  try {
    const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
    const res = await instance.get(
      'usuario/dados'
    );
    return { status: res.status, usuario: { ...res.data } };
  } catch (error) {
    throw error.response;
  }
}

export const getTermInformation = async (numeroContratoSap, empreendimentoId) => {
  try {
    const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
    const res = await instance.get(
      `/confissao-divida/${numeroContratoSap}/?empreendimentoId=${empreendimentoId}`
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    throw error.response;
  }
}

export const getTermInformationUrlAssinatura = async (numeroContratoSap, empresa, documento, exercicio, redirect = null) => {
  try {
    const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
    const res = await instance.post(
      `/contratos/${numeroContratoSap}/termo_renegociacao`, {
        empresa,
        documento,
        exercicio,
        url_callback: redirect
      }
    );
    return res.data;
  } catch (error) {
    throw error.response;
  }
}

export const getBoletoPDF = async (
  numeroContratoSap,
  documento,
  empresa,
  exercicio
) => {

  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
    const response = await instance.post(
    `contratos/${numeroContratoSap}/download-termo`,
    {
      documento,
      empresa,
      exercicio
    },
    {
      responseType: 'blob'
    }
  );
  return response.data;
}

export const sendEmailToSignatarios = async (
  numeroContratoSap,
  empresa,
  documento,
  exercicio,
  signatatios,
  redirect = null
) => {

  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  const res = await instance.post(
    `contratos/${numeroContratoSap}/termo_renegociacao/signatarios`,
    {
      termo: {
        documento,
        exercicio,
        empresa,
        url_callback: redirect
      },
      signatarios: signatatios,
      reenviaEmails: true
    }
  );
  return res.data;
}
