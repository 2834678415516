import React from "react";
import PropTypes from "prop-types";

const Curve = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="-140 480 1560 99"
    preserveAspectRatio="none"
    style={{ width: "100%" }}
  >
    <defs>
      <clipPath id="_clipPath_XrwJuYj6ybWlqWh1M6vlz4GeT2EUD0Yp">
        <rect x="-140" y="480" width="1560" height="99" />
      </clipPath>
    </defs>
    <g clipPath="url(#_clipPath_XrwJuYj6ybWlqWh1M6vlz4GeT2EUD0Yp)">
      <path
        d=" M 1420 510.581 C 1313.232 550.275 1004.182 579 640 579 C 275.818 579 -33.232 550.275 -140 510.581 L -140 510.581 L -140 480 L 1420 480 L 1420 510.581 L 1420 510.581 Z "
        fill={fill}
      />
    </g>
  </svg>
);

Curve.propTypes = {
  fill: PropTypes.string
};

Curve.defaultProps = {
  fill: "white"
};

export default Curve;
