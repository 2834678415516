import { MOCK } from "utils/constants";
import * as finance from "./finance";
import * as financeMock from "./finance.mock";

export const getExtract = MOCK ? financeMock.getExtract : finance.getExtract;

export const getEmpreendimentos = MOCK ? financeMock.getEmpreendimentos : finance.getEmpreendimentos;

export const getPerfil = MOCK ? financeMock.getPerfil : finance.getPerfil;

export const getDados = MOCK ? financeMock.getDados : finance.getDados;

export const getTermInformation = MOCK ? financeMock.getTermInformation : finance.getTermInformation;

export const getTermInformationUrlAssinatura = MOCK ? financeMock.getTermInformationUrlAssinatura : finance.getTermInformationUrlAssinatura;

export const getBoletoPDF = MOCK ? financeMock.getBoletoPDF : finance.getBoletoPDF;

export const sendEmailToSignatarios = MOCK ? financeMock.sendEmailToSignatarios : finance.sendEmailToSignatarios;


