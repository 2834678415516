import React, {useCallback, useState, useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {getTermInformation, getTermInformationUrlAssinatura, sendEmailToSignatarios} from "services/finance";
import FinanceContext from "contexts/finance";
import ContractContext from "contexts/contract";
import { APP_URL_HOSTNAME } from 'utils/constants';
import { trackException, trackTraceException } from "services/trackInsights";

const TermPendingContext = React.createContext({
  loadTermInformation: () => {},
  termInformationLoaded: false,
  term: {
    dataRealizacao: null,
    statusRenegociacao: null,
    statusTermo: null,
    tipoOperacao: null,
    tipoNegociacao: null,
    documentoSAP: {
      empresa: null,
      documento: null,
      exercicio: null,
      item: null
    },
    termo: {
      codRenTermo: null,
      validadeDocumento: null,
      idEnvelope: null,
      urlAssinatura: null,
      dataAssinaturaTermo: null,
      horaAssinaturaTermo: null,
      signatarios: []
    }
  },
  urlAssinatura: null,
  errorUrlAssinatura: false,
  isLoadedUrlAssinatura: false,
  hasTerm: false,
  errorTermInformation: false,
  loadTermInformationUrlAssinatura: () => {},
  doSendEmailToSignatarios: () => {}
});

const TRY_RETRIES_COUNT_TOTAL = 2;
export const TermPendingContextProvider = ({ children }) => {
  const [ loadingTermInformation, setLoadingTermInformation ] = useState(false);
  const [ termInformationLoaded, setTermInformationLoaded ] = useState(false);
  const { selectedContract } = useContext(ContractContext);
  const { perfilLoaded, empreendimentos, perfilErrorCriFlex, errorPerfil, } = useContext(FinanceContext);
  const [ term, setTerm ] = useState({
    dataRealizacao: null,
    statusRenegociacao: null,
    statusTermo: null,
    tipoOperacao: null,
    tipoNegociacao: null,
    documentoSAP: {
      empresa: null,
      documento: null,
      exercicio: null,
      item: null
    },
    termo: {
      codRenTermo: null,
      validadeDocumento: null,
      idEnvelope: null,
      urlAssinatura: null,
      dataAssinaturaTermo: null,
      horaAssinaturaTermo: null,
      signatarios: []
    }
  });
  const [ hasTerm, setHasTerm ] = useState(false);
  const [ errorTermInformation, setErrorTermInformation ] = useState(false);
  const [ urlAssinatura, setUrlAssinatura ] = useState(null);
  const [ errorUrlAssinatura, setErrorUrlAssinatura ] = useState(false);
  const [ isLoadedUrlAssinatura, setIsLoadedUrlAssinatura ] = useState(false);
  const [countTryTerm, setCountTryTerm] = useState(0);
  const [lastContractSelected, setLastContractSelected] = useState(null);

  const loadTermInformation = async () => {
    setLoadingTermInformation(true);
    try {
      const empreendimentoSelecionado = empreendimentos.find(empreendimento => empreendimento.idEmpreendimento === selectedContract.empreendimentoId);
      if(empreendimentoSelecionado && empreendimentoSelecionado.id) {
        const termInformation = await getTermInformation(selectedContract.contratoSap, empreendimentoSelecionado.id);
        if(termInformation.status !== 204) {
          setHasTerm(true);
          setTerm(termInformation.data);
        }
        setCountTryTerm(0);
        setTermInformationLoaded(true);
      }
    } catch (error) {
      const count = countTryTerm+1;
      setCountTryTerm(count);

      if(countTryTerm === TRY_RETRIES_COUNT_TOTAL) {
        setErrorTermInformation(true);
      }
      trackException(error);
      trackTraceException("loadTermInformation", error);
    } finally {
      setLoadingTermInformation(false);
    }
  };

  const loadTermInformationUrlAssinatura = useCallback(() => {
    const load = async () => {
      try {
        const termUrlInformation = await getTermInformationUrlAssinatura(selectedContract.contratoSap,
          term.documentoSAP.empresa, term.documentoSAP.documento, term.documentoSAP.exercicio,
          `https://${APP_URL_HOSTNAME}/relacionamento/term-onboarding-success`);
        setUrlAssinatura(termUrlInformation.url_assinatura);
        setIsLoadedUrlAssinatura(true);
      } catch (error) {
        setErrorUrlAssinatura(true);
      }
    };
    if(!isLoadedUrlAssinatura && selectedContract.bloco && termInformationLoaded){
      load();
    }
  }, [setUrlAssinatura, selectedContract, setIsLoadedUrlAssinatura, termInformationLoaded, term]);

  useEffect(() => {
    if(countTryTerm < TRY_RETRIES_COUNT_TOTAL && perfilLoaded && !termInformationLoaded && !loadingTermInformation){
      loadTermInformation();
    } else if(!perfilLoaded && (errorPerfil || perfilErrorCriFlex)){
      setErrorTermInformation(true);
    }
  }, [perfilLoaded, countTryTerm, errorPerfil, perfilErrorCriFlex, loadingTermInformation, termInformationLoaded]);

  useEffect(() => {
    if(lastContractSelected === null || lastContractSelected.idEmpreendimento == null || (lastContractSelected.idEmpreendimento !== selectedContract.idEmpreendimento)){
      setTermInformationLoaded(false);
      setLastContractSelected(selectedContract);
    }
  }, [selectedContract]);

  const doSendEmailToSignatarios = async (signatarios) => {
    return sendEmailToSignatarios(selectedContract.contratoSap, term.documentoSAP.empresa,
      term.documentoSAP.documento, term.documentoSAP.exercicio, signatarios,
      `https://${APP_URL_HOSTNAME}/relacionamento/term-onboarding-success`);
  }

  const value = React.useMemo(
    () => ({
      doSendEmailToSignatarios,
      loadingTermInformation,
      termInformationLoaded,
      loadTermInformation,
      term,
      loadTermInformationUrlAssinatura,
      urlAssinatura,
      errorUrlAssinatura,
      isLoadedUrlAssinatura,
      hasTerm,
      errorTermInformation
    }),
    [
      sendEmailToSignatarios,
      loadingTermInformation,
      termInformationLoaded,
      loadTermInformation,
      term,
      loadTermInformationUrlAssinatura,
      urlAssinatura,
      errorUrlAssinatura,
      isLoadedUrlAssinatura,
      hasTerm,
      errorTermInformation
    ]
  );

  return <TermPendingContext.Provider value={value}>{children}</TermPendingContext.Provider>;
};

TermPendingContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TermPendingContext;
