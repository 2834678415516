import { UserManager, WebStorageStateStore } from "oidc-client";
import {
  LOGIN_AUTHORITY_URL,
  LOGIN_REDIRECT_URL,
  LOUGOUT_URL,
  LOGIN_CLIENT_ID,
  LOGIN_AUTHORITY_SCOPE,
  SILENT_URL
} from "utils/constants";

class AuthService {
  constructor() {
    const settings = {
      authority: LOGIN_AUTHORITY_URL,
      client_id: LOGIN_CLIENT_ID,
      redirect_uri: LOGIN_REDIRECT_URL,
      post_logout_redirect_uri: LOUGOUT_URL,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      response_type: "code",
      scope: LOGIN_AUTHORITY_SCOPE,
      automaticSilentRenew: false,
      silent_redirect_uri: SILENT_URL,
      response_mode: "query",
      revokeAccessTokenOnSignout: true,
      clockSkew: 36000
    };
    this.userManager = new UserManager(settings);

    this.eventTokenExpired = false;
  }

  tokenExpired = async () => {
    if (!this.eventTokenExpired) {
      this.eventTokenExpired = true;

      this.getEvents().addAccessTokenExpired(() => {
        this.renew()
          .then()
          .catch(async () => {
            this.getEvents().removeAccessTokenExpired();
            this.eventTokenExpired = false;
            await this.login();
          });
      });
    }
  };

  login = async () => {
    return this.userManager.signinRedirect();
  };

  renew = async () => {
    return this.userManager.signinSilent();
  };

  renewCallback = async () => {
    return this.userManager.signinSilentCallback();
  };

  logout = async () => {
    await this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token")
    });
  };

  signoutRedirectCallback = async () => {
    return new Promise((accept, rej) => {
      this.userManager
        .signoutRedirectCallback()
        .then(() => {
          localStorage.clear();
          this.userManager.removeUser();
          this.userManager.clearStaleState();
          accept();
        })
        .catch(err => {
          rej(err);
        });
    });
  };

  getUser = async () => {
    return this.userManager.getUser();
  };

  getEvents = () => {
    return this.userManager.events;
  };

  loginCallback = async () => {
    return this.userManager.signinRedirectCallback();
  };

  clear = async () => {
    return this.userManager.clearStaleState();
  };
}

export default new AuthService();
