import styled, { css } from 'styled-components';
import {
  Modal as ModalCustom,
  Button as ButtonCustom
} from '@company-mrv/mrv-design-system/components';
import tokens from '@company-mrv/mrv-design-system/tokens';
import { media } from 'utils/styles/breakpoints';

export const IllustrationImage = styled.img`
  ${props =>
    props.iv &&
    css`
      transform: rotateY(180deg);
    `}
`;

export const ContainerImage = styled.div`
  width: ${props => props.w && `${props.w}px`};
  height: ${props => props.h && `${props.h}px`};
  margin-top: ${props => props.pt && `${props.pt}px`};
`;

export const Modal = styled(ModalCustom)`
  .modal-content {
    width: auto;
    height: auto;
    .modal-header {
      align-items: flex-end;
      justify-content: stretch;
    }
    ${media.forDesktopUp} {
      max-width: 1032px;
      overflow-y: visible;
    }
  }
  ${media.forDesktopUp} {
    .modal-header button {
      margin-right: 4px;
      margin-top: 4px;
      transition: transform 0.3s ease;
      [data-whatintent='mouse'] &:hover {
        opacity: 1;
        transform: rotate(90deg);
      }
    }
  }
`;

export const Container = styled.div`
  width: 312px;
  padding: 24px;
  ${media.forTabletUp} {
    width: 440px;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  width: 100%;
  line-height: 28px;
  font-weight: ${tokens.fontWeightBold};
  text-align: center;
  color: ${tokens.colorNeutralXxdark};
  font-size: ${tokens.fontSizeSmall};
  letter-spacing: 0;
  margin-bottom: 16px;
  margin-top: 40px;
`;

export const Text = styled.div`
  width: 100%;
  margin: 8px 27px 0 27px;
  text-align: center;
  min-height: 66px;
  font-weight: ${tokens.fontWeightRegular};
  color: ${tokens.colorNeutralXxdark};
  font-size: 14px ${tokens.fontSizeXxSmall};
  line-height: ${tokens.lineHeightSmall};
  letter-spacing: 0;
`;

export const Footer = styled.div`
  width: 100%;
`;

export const Link = styled.a`
  font-size: ${tokens.fontSizeXxSmall};
  font-weight: ${tokens.fontWeightBold};
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: ${tokens.colorBrandPrimaryPure};
  border-bottom: 1px dotted ${tokens.colorBrandPrimaryPure};
  cursor: pointer;
`;

export const ButtonRefresh = styled(ButtonCustom)`
  width: 100%;
`;

export default {
  ContainerImage,
  ButtonRefresh,
  Footer,
  Link,
  Modal,
  Title,
  Container,
  Content,
  Text,
  IllustrationImage
};
