import React from "react";
import PropTypes from "prop-types";

const TabPanel = React.forwardRef(
  ({ index, value, children, ...props }, ref) => {
    return (
      index === value && (
        <div ref={ref} {...props}>
          {children}
        </div>
      )
    );
  }
);

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default TabPanel;
