import styled from "styled-components/macro";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { media } from "utils/styles/breakpoints";

export const Container = styled.div`
  width: auto;
  margin: 0 auto;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  min-width: auto;
  ${media.forTabletUp} {
    background: ${tokens.colorBackground04};
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: ${tokens.borderRadiusMedium};
    min-width: 480px;
  }
`;
