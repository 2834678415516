import styled, { keyframes } from "styled-components";
import tokens from "@company-mrv/mrv-design-system/tokens";
import {
  Modal as BaseModal,
  Button as BaseButton
} from "@company-mrv/mrv-design-system/components";
import { media } from "utils/styles/breakpoints";

const TransformAnimation = keyframes`
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
`;

export const Modal = styled(BaseModal)`
  .modal-content {
    position: relative;
    background-color: ${tokens.colorNeutralXxlight};
    border-radius: ${tokens.borderRadiusLarge};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 320px;
    padding: ${tokens.spacingInlineLarge};
    text-align: center;
    animation: ${TransformAnimation} 0.2s ease-out;
    align-items: center;

    @media (max-width: 500px) {
      margin: ${tokens.spacingInlineLarge};
      padding: ${tokens.spacingInlineLarge};
    }
  }
`;

export const IconWraper = styled.div`
  display: inline-block;
  margin-bottom: ${tokens.spacingInlineXSmall};
`;

export const Title = styled.div`
  font-size: ${tokens.fontSizeSmall};
  line-height: ${tokens.lineHeightMedium};
  font-weight: ${tokens.fontWeightBold};
  color: ${tokens.colorNeutralXxdark};
  margin: 0;

  ${media.forDesktopUp} {
    font-size: ${tokens.fontSizeMedium};
    line-height: ${tokens.lineHeightMedium};
  }
`;

export const Description = styled.p`
  font-size: ${tokens.fontSizeXxSmall};
  line-height: ${tokens.lineHeightDefault};
  color: ${tokens.colorNeutralDark};
  margin: ${tokens.spacingInlineXSmall} 0 ${tokens.spacingInlineSmall} 0;

  ${media.forDesktopUp} {
    margin: ${tokens.spacingInlineXSmall} auto ${tokens.spacingInlineSmall};
    font-size: ${tokens.fontSizeXSmall};
    line-height: ${tokens.lineHeightDefault};
    max-width: 216px;
  }
`;

export const Button = styled(BaseButton)`
  &:last-child {
    margin-top: ${tokens.spacingInlineXSmall};

    ${media.forDesktopUp} {
      margin-top: ${tokens.spacingInlineSmall};
    }
  }
`;
