import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const Dot = ({ color, colorHex }) => (
  <Styled.Dot color={color} colorHex={colorHex} data-testid="dot" />
);

Dot.propTypes = {
  color: PropTypes.oneOf(["primary", "orange", "default"]),
  colorHex: PropTypes.string
};

Dot.defaultProps = {
  color: "default",
  colorHex: undefined
};

export default Dot;
