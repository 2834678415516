import styled from "styled-components/macro";
import colors from "@company-mrv/mrv-design-system/colors";

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  background: ${props => (props.checked ? colors.greenRegular : colors.white)};
  border-style: solid;
  border-width: 2px;
  border-color: ${props =>
    props.checked ? colors.greenRegular : colors.greyDetails};
  border-radius: 6px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    [data-whatinput="touch"] &,
    [data-whatinput="mouse"] & {
      border-color: ${props =>
        props.checked ? colors.greenRegular : colors.greyDetails};
    }
    border: solid 2px ${colors.greyRegular};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")};
  }
`;
