/* eslint-disable no-unused-vars */
import { axiosInstance } from "../servicesSetupMock";

export const getOnboardingInformation = async (isIndicacao = false) => {
  const { onboarding } = await axiosInstance();
  return onboarding;
};

export const getEnterprises = async () => {
  const { enterprises } = await axiosInstance();
  return enterprises;
};

export const getPDFExtract = async empreendimentoId => {
  const { extract } = await axiosInstance();
  return extract;
};
