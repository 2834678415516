import axios from "axios";
import auth from "services/auth";
import {
  API_URL,
  FINANCIAL_API_URL,
  API_FINANCEIRO_NAME,
  MDC_API_URL,
  API_MDC_NAME
} from "utils/constants";

const getApi = apiName => {
  switch (apiName) {
    case API_FINANCEIRO_NAME:
      return FINANCIAL_API_URL;
    case API_MDC_NAME:
      return MDC_API_URL;
    default:
      return API_URL;
  }
};

export const getToken = async () => {
  const user = await auth.getUser();
  if (user && user.access_token) {
    return user.access_token;
  }
  return undefined;
};

export const axiosInstance = async (defaultTokenParam, apiName) => {
  let defaultToken = defaultTokenParam;

  if (!defaultToken) defaultToken = await getToken();

  if (defaultToken)
    axios.defaults.headers.common = {
      Authorization: `Bearer ${defaultToken}`,
      "Cache-Control": "no-cache"
    };
  const baseURL = getApi(apiName);
  return axios.create({
    baseURL
  });
};
