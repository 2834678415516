import React from "react";
import { Button as BaseButton } from "@company-mrv/mrv-design-system/components";
import PropTypes from "prop-types";

const Button = ({ children, ...props }) => {
  return <BaseButton {...props}>{children}</BaseButton>;
};

Button.propTypes = {
  children: PropTypes.node.isRequired
};

Button.defaultProps = {};

export default Button;
