import { MOCK } from "utils/constants";
import * as trackWork from "./trackWork";
import * as trackWorkMock from "./trackWork.mock";

export const getUnidade = MOCK
  ? trackWorkMock.getUnidade
  : trackWork.getUnidade;

export const getGarageContracts = MOCK
  ? trackWorkMock.getGarageContracts
  : trackWork.getGarageContracts;

export const getPercents = MOCK
  ? trackWorkMock.getPercents
  : trackWork.getPercents;

export const getNarrativa = MOCK
  ? trackWorkMock.getNarrativa
  : trackWork.getNarrativa;

export const getMilestones = MOCK
  ? trackWorkMock.getMilestones
  : trackWork.getMilestones;
