import { MOCK } from "utils/constants";
import * as home from "./home";
import * as homeMock from "./home.mock";

export const getOnboardingInformation = MOCK
  ? homeMock.getOnboardingInformation
  : home.getOnboardingInformation;

export const getPDFExtract = MOCK ? homeMock.getPDFExtract : home.getPDFExtract;

export const getEnterprises = MOCK
  ? homeMock.getEnterprises
  : home.getEnterprises;
