import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

const AnimatedBorderClickedContext = createContext();

export const AnimatedBorderClickedContextProvider = ({ children }) => {
  const [elementsIdsClicked, setElementsIdsClicked] = useState({});
  const [elementsIdsAlreadyShowed, setElementsIdsAlreadyShowed] = useState({});

  return (
    <AnimatedBorderClickedContext.Provider
      value={{
        elementsIdsClicked,
        setElementsIdsClicked,
        elementsIdsAlreadyShowed,
        setElementsIdsAlreadyShowed
      }}
    >
      {children}
    </AnimatedBorderClickedContext.Provider>
  );
};

AnimatedBorderClickedContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default () => {
  return useContext(AnimatedBorderClickedContext);
};
