import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ArrowDown from "assets/svg/arrow-down.svg";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { getThemePropertyValue } from "utils/pluginHelpers";
import * as Styled from "./styles";

const DEFAULT_TRANSITION_DELAY = 400;

export const COLORS = {
  PRIMARY: "primary",
  ORANGE: "orange",
  DEFAULT: "default",
  NONE: "none"
};

export const COLORS_MAP = {
  [COLORS.PRIMARY]: getThemePropertyValue(
    "milestones.steps.status.primary",
    tokens.colorBrandPrimaryPure
  ),
  [COLORS.ORANGE]: getThemePropertyValue(
    "milestones.steps.status.orange",
    tokens.colorBrandSecondaryPure
  ),
  [COLORS.DEFAULT]: tokens.colorNeutralMedium,
  [COLORS.NONE]: tokens.colorNeutralXxlight
};

const Item = ({ title, borderColor, isOpen, children, onClick }) => {
  const [open, setOpened] = useState(isOpen);

  useEffect(() => {
    setOpened(isOpen);
  }, [isOpen]);

  return (
    <>
      <Styled.Toggle
        transitionDelay={DEFAULT_TRANSITION_DELAY}
        onClick={() => {
          setOpened(!open);
          onClick && onClick();
        }}
        isOpen={open}
      >
        <Styled.TitleContainer>{title}</Styled.TitleContainer>
        <Styled.EndContent>
          <Styled.ArrowIcon
            alt={`Indicador de conteúdo ${isOpen ? "aberto" : "fechado"}.`}
            transitionDelay={DEFAULT_TRANSITION_DELAY}
            src={ArrowDown}
          />
        </Styled.EndContent>
      </Styled.Toggle>
      <Styled.Content
        borderColor={COLORS_MAP[borderColor]}
        transitionDelay={DEFAULT_TRANSITION_DELAY}
        isOpen={open}
      >
        {children}
      </Styled.Content>
    </>
  );
};

const Accordion = ({ children, ...props }) => {
  return <Styled.Accordion {...props}>{children}</Styled.Accordion>;
};

Item.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  borderColor: PropTypes.oneOf([...Object.values(COLORS)]),
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

Item.defaultProps = {
  borderColor: COLORS.DEFAULT,
  isOpen: false,
  onClick: null
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired
};

Accordion.Item = Item;
export default Accordion;
