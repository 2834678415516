import plugin from "utils/currentPlugin";

export const isInHiddenList = target =>
  plugin && plugin.hidden && plugin.hidden.includes(target);

export const setColor = (color, defaultColor) =>
  (plugin && plugin.colors[color]) || defaultColor;

export const visible = nameComponent => {
  if (!plugin || !plugin.componentsVisibility) {
    return false;
  }

  const validation =
    Object.prototype.hasOwnProperty.call(
      plugin.componentsVisibility,
      nameComponent
    ) && plugin.componentsVisibility[nameComponent];

  return validation;
};

/**
 * Get a custom property in theme based on a
 * json key value path
 * @param {string} propertyKey => You can use a path like this `ComponentName.SubComponent.Property`
 * @param {string} defaultValue => The default value returned when the key is not found on theme
 */
export const getThemePropertyValue = (propertyKey, defaultValue) => {
  // Default checks
  if (!plugin || !plugin.components || !propertyKey) {
    return defaultValue;
  }

  const keys = propertyKey.split(".");

  const value = keys.reduce((o, k) => o && o[k], plugin.components);

  if (value === undefined) return defaultValue;

  return value;
};

export const getThemeName = () => {
  return plugin ? plugin.name : "";
};

export const guards = method => {
  return (
    (plugin && plugin.guards && plugin.guards[method]) ||
    ((to, from, next) => {
      next();
    })
  );
};

export const modules = moduleName => {
  return plugin && plugin.modules && plugin.modules[moduleName];
};

export const getTexts = plugin && plugin.texts;
export const getAssets = plugin && plugin.assets;

export const getComponents = (propertyKey) => {
  // Default checks
  if (!plugin || !plugin.components || !propertyKey) {
    return undefined;
  }
};