import React from "react";
import { getThemePropertyValue } from "utils/pluginHelpers";
import { TitleInternal, Link } from "./styles";

const ELEMENTS_FAQ_DEFAULT = [
  {
    title: "Abra o site em uma aba anônima",
    body: (
      <>
        <TitleInternal>iOS</TitleInternal>
        <br />
        <br />
        <ul>
          <li>
            <strong>Chrome</strong>, no ícone deste navegador, clique com o
            botão direito do mouse &gt; Nova janela anônima <strong>ou</strong>{" "}
            vá em Menu (3 pontos no canto superior direito) &gt; Nova janela
            anônima.
          </li>
          <br />
          <li>
            <strong>Safari</strong>, no ícone deste navegador, clique com o
            botão direito do mouse &gt; Nova janela com navegação privada ou vá
            em Arquivo &gt; Nova janela com navegação privada.
          </li>
        </ul>

        <br />
        <br />
        <TitleInternal>Windows / Android</TitleInternal>
        <br />
        <br />
        <ul>
          <li>
            <strong>Chrome</strong>, no ícone deste navegador, clique com o
            botão direito do mouse &gt; Nova janela anônima ou vá em Menu (3
            pontos no canto superior direito) &gt; Nova janela anônima.
          </li>
          <br />
          <li>
            <strong>Safari</strong>, no ícone deste navegador, clique com o
            botão direito do mouse &gt; Nova janela com navegação privada{" "}
            <strong>ou</strong> vá em Arquivo &gt; Nova janela com navegação
            privada.
          </li>
          <br />
          <li>
            <strong>Internet Explorer</strong>, no ícone deste navegador, clique
            com o botão direito do mouse &gt; Iniciar navegação InPrivate.
          </li>
          <br />
          <li>
            <strong>Mozilla Firefox</strong>, no ícone deste navegador, clique
            com o botão direito do mouse &gt; Nova janela privativa.
          </li>
        </ul>
      </>
    )
  },
  {
    title: "Atualize para a versão mais recente",
    body: (
      <>
        <TitleInternal>Navegador</TitleInternal>
        <br />
        <br />
        <ul>
          <li>
            <strong>Chrome</strong>, vá em menu (3 pontos no canto superior
            direito) &gt; Configurações &gt; Uma página abrirá, onde o último
            item do menu é &quot;Sobre o Google Chrome&quot; &gt; Caso o
            navegador não estiver atualizado terá um botão &quot;Reiniciar&quot;
            para fazê-lo.
          </li>
          <br />
          <li>
            <strong>Safari</strong>, é atualizado automaticamente, de acordo com
            a atualização da versão do iOS.
          </li>
          <br />
          <li>
            <strong>Internet Explorer</strong>, selecione o botão Iniciar do seu
            computador &gt; Configurações &gt; Atualizar & segurança &gt;
            Windows Update e, em seguida, selecione Verificar se há
            atualizações.
          </li>
          <br />
          <li>
            <strong>Mozilla Firefox</strong>, clique no menu &gt; depois em
            Ajuda &gt; Sobre o Firefox. A janela Sobre o Mozilla Firefox Firefox
            é aberta. O Firefox verifica se há uma atualização e começa a baixar
            automaticamente. Quando o download terminar, clique em Reiniciar o
            Firefox para atualizar.
          </li>
        </ul>
        <br />
        <br />
        <TitleInternal>Sistema Operacional</TitleInternal>
        <br />
        <br />
        <ul>
          <li>
            <strong>iOS - Computador</strong>, vá para Preferências do Sistema
            &gt; Atualização de Software &gt; Atualizar agora.
          </li>
          <br />
          <li>
            <strong>iOS - Celular</strong>, vá para Ajustes &gt; se houver
            atualização disponível, aparecerá como o primeiro item{" "}
            <strong>ou</strong> entre em Ajustes &gt; Geral &gt; Atualização de
            Software.
          </li>
          <br />
          <li>
            <strong>Android</strong>, acesse a loja de aplicativos do seu
            smartphone &gt; Procure o aplicativo MEU MRV &gt; Clique no botão
            Atualizar.
          </li>
          <br />
          <li>
            <strong>Windows</strong>, selecione o botão Iniciar do seu
            computador &gt; Configurações &gt; Atualizar & segurança &gt;
            Windows Update e, em seguida, selecione Verificar se há
            atualizações.
          </li>
        </ul>
        <br />
        <br />
        <TitleInternal>Do App</TitleInternal>
        <br />
        <br />
        <ul>
          <li>
            Atualização, caso esteja usando o aplicativo: Acesse a loja de
            aplicativos do seu smartphone &gt; Procure o aplicativo MEU MRV &gt;
            Clique no botão Atualizar.
          </li>
        </ul>
      </>
    )
  },
  {
    title: "Tente em outro Navegador",
    body: (
      <>
        Tente acessar por outros navegadores, como: Chrome, Mozilla Firefox,
        Safari, Internet Explorer.
      </>
    )
  },
  {
    title: "Verifique sua rede",
    body: (
      <>
        Verifique se você está em uma rede corporativa e tente acessar pelo
        3G/4G ou em outra rede wifi. Caso esteja em qualquer outra rede, repita
        a mesma solução.
      </>
    )
  },
  {
    title: "Tente em outro dispositivo",
    body: (
      <>
        Se você estiver no computador, tente no celular ou no tablet, e vice
        versa.
      </>
    )
  },
  {
    title: "Javascript não habilitado",
    body: (
      <>
        <ul>
          <li>
            <strong>Chrome</strong>, vá em menu (3 pontos no canto superior
            direito) &gt; Configurações &gt; Uma página abrirá &gt; opção
            Privacidade e segurança &gt; Opção Configurações do site &gt; Em
            conteúdo &gt; JavaScript &gt; Habilitar, selecionar para manter na
            opção permitido.
          </li>
          <br />
          <li>
            <strong>Safari</strong>, vá em Safari (no menu superior) &gt;
            Preferências &gt; Segurança &gt; Conteúdo da internet - marcar a
            opção &quot;Ativar JavaScript&quot;.
          </li>
        </ul>
      </>
    )
  },
  {
    title: "Tente limpar cookies do navegador",
    body: (
      <>
        <strong>Atenção:</strong> Essa opção pode apagar algumas senhas, login,
        dados e etc.
        <br />
        <br />
        <ul>
          <li>
            <strong>Chrome - Computador</strong>, vá em menu (3 pontos no canto
            superior direito) &gt; Configurações &gt; Limpar dados de navegação.
          </li>
          <br />
          <li>
            <strong>Chrome - Celular</strong>, vá em menu (3 pontos no canto
            inferior direito) &gt; Histórico &gt; Limpar dados de navegação.
          </li>
          <br />
          <li>
            <strong>Safari - Computador</strong>, vá em Safari &gt; Limpar
            histórico &gt; Selecione o tempo e clique em Limpar histórico.
          </li>
          <br />
          <li>
            <strong>Safari - Celular,</strong> vá em Ajustes &gt; Safari &gt;
            Limpar histórico e dados dos sites &gt; Limpar tudo.
          </li>
          <br />
          <li>
            <strong>Mozilla Firefox</strong>, clique no menu e selecione
            Configurações &gt; Selecione o painel Privacidade e Segurança &gt;
            seção Cookies e dados de sites &gt; Clique no botão &quot;Gerenciar
            dados…&quot;. Aparece o diálogo Gerenciar cookies e dados de sites.
            No campo Pesquisar sites, digite o nome do site cujos cookies deseja
            remover. Os itens que combinam com sua busca são exibidos. Para
            remover todos os cookies e dados armazenados do site, clique em
            &quot;Remover todos os mostrados&quot;. Para remover itens
            selecionados, selecione alguns e clique em &quot;Remover
            selecionados&quot;. Clique em &quot;Salvar alterações&quot;. No
            diálogo de confirmação &quot;Remoção de cookies e dados de
            sites&quot;, clique em OK. Feche a página about:preferences.
          </li>
          <br />
          <li>
            <strong>Internet Explorer</strong>, selecione o botão Ferramentas
            &gt; Aponte para Segurança &gt; Selecione Excluir histórico de
            navegação &gt; Marque a caixa de seleção Cookies e dados do site
            &gt; Selecione Excluir.
          </li>
        </ul>
      </>
    )
  },
  {
    title: "Instabilidade momentânea",
    body: (
      <div>
        Algumas instabilidades podem ocorrer de vez em quando. Caso nenhuma das
        soluções anteriores tenham ajudado você, por favor, acesse o site
        novamente mais tarde.
        <br />
        <br />
        Se as dicas não resolveram o seu problema, entre em contato conosco{" "}
        <Link
          href="https://api.whatsapp.com/send?phone=5531999009000&text=Ol%C3%A1%2C%20como%20pode%20me%20ajudar%3F"
          target="_blank"
        >
          por aqui.
        </Link>
      </div>
    )
  }
];

const ELEMENTS_FAQ = getThemePropertyValue(
  "errorPage.ELEMENTS_FAQ",
  ELEMENTS_FAQ_DEFAULT
);

export default {
  ELEMENTS_FAQ
};
