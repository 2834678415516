import styled from "styled-components/macro";
import { css } from "styled-components";

export const LinearBorderContainer = styled.div`
  position: relative;
  cursor: ${props => (props.pointer ? "pointer" : "unset")};
`;

export const LinearBorderSvg = styled.svg`
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: 0;
  left: 0;
  & rect {
    stroke-dasharray: ${props => props.borderLength};
    stroke-dashoffset: ${props => props.borderLength};
    transition: stroke-dashoffset 1.5s ${props => props.delay}s
      cubic-bezier(0.87, 0.19, 0.06, 0.93);
  }
  ${props =>
    props.animate &&
    css`
      & rect {
        stroke-dashoffset: 0;
      }
    `}
`;
