import styled from "styled-components";
import { Modal as BaseModal } from "@company-mrv/mrv-design-system/components";
import tokens from "@company-mrv/mrv-design-system/tokens";

export const Modal = styled(BaseModal)`
  .modal-content {
    width: 100%;
    height: 100%;
    border-width: 0;
    border-radius: ${tokens.borderRadiusNone};
  }

  .modal-header {
    margin: 0;
  }
`;

export const Header = styled(Modal.Header)`
  background-color: transparent;
  margin: 0;
`;
