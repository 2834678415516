import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const ProgressBar = ({ percent, colorBar, itemId, ...props }) => (
  <Styled.Container {...props}>
    <Styled.Bar
      percent={percent}
      colorBar={colorBar}
      data-testid="card-load"
      id={`card-load-${itemId}`}
    />
  </Styled.Container>
);

ProgressBar.propTypes = {
  percent: PropTypes.number,
  colorBar: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired
};

ProgressBar.defaultProps = {
  percent: 0
};

export default ProgressBar;
