import React from "react";
import { LoadingIndicator } from "@company-mrv/mrv-design-system/components";
import { getThemeName } from "utils/pluginHelpers";
import * as Styled from "./styles";

const FullPageLoading = ({ ...props }) => (
  <Styled.Container {...props}>
    <LoadingIndicator theme={getThemeName()} />
  </Styled.Container>
);

export default FullPageLoading;
