import React from "react";
import { ButtonSecondaryCustom } from "./styles";

const ButtonSecondary = ({ ...props }) => {
  return <ButtonSecondaryCustom {...props} />;
};

ButtonSecondary.propTypes = {};
ButtonSecondary.defaultProps = {};

export default ButtonSecondary;
