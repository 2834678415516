import React from "react";
import PropTypes from "prop-types";
import BaseMaskedInput from "react-text-mask";
import Input from "../input";

const MaskedInput = React.forwardRef(({ guide, ...props }, ref) => {
  return (
    <BaseMaskedInput
      guide={guide}
      render={(internalRef, parsedProps) => (
        <Input ref={internalRef} {...parsedProps} />
      )}
      ref={ref}
      {...props}
    />
  );
});

MaskedInput.propTypes = {
  guide: PropTypes.bool
};

MaskedInput.defaultProps = {
  guide: false
};

export default MaskedInput;
