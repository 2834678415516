import React from "react";
import PropTypes from "prop-types";

const Icon = ({ pathFill, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...rest}>
      <path
        fill={pathFill}
        d="M19.856 7.185l4.117 3.095a1.263 1.263 0 001.516 0l4.117-3.095A4.029 4.029 0 0024.958.618l-.227.151-.227-.151a4.029 4.029 0 00-4.648 6.567zm.96-3.587a1.477 1.477 0 01.91-1.035 1.49 1.49 0 011.377.152l.933.618a1.263 1.263 0 001.39 0l.934-.619a1.516 1.516 0 011.377-.151 1.478 1.478 0 01.91 1.035 1.49 1.49 0 01-.557 1.566L24.73 7.69l-3.359-2.526a1.49 1.49 0 01-.555-1.566zm12.25 10.268a3.537 3.537 0 00-2.02-2.147 3.475 3.475 0 00-2.918.114l-3.953 1.97a3.398 3.398 0 00-.707-.745 3.285 3.285 0 00-2.766-.695l-3.789.745h-.82a10.608 10.608 0 00-5.899 1.781 10.28 10.28 0 00-2.64 2.627l-1.3 1.857a2.526 2.526 0 00-3.511 0L.735 21.38a2.526 2.526 0 000 3.561l8.322 8.324a2.526 2.526 0 003.562 0l2.008-2.009a2.526 2.526 0 00.19-3.334l.429-.29a4.105 4.105 0 012.26-.682 12.553 12.553 0 006.998-2.122c.687-.462 1.33-.986 1.92-1.566l5.796-5.797a3.486 3.486 0 00.846-3.6zM10.838 31.485l-8.323-8.323 2.008-2.008 8.323 8.323-2.008 2.008zm19.589-15.813L24.63 21.47a9.672 9.672 0 01-1.528 1.263 10.103 10.103 0 01-5.595 1.693 6.543 6.543 0 00-3.663 1.111l-.82.543-4.952-4.95 1.503-2.148a8.07 8.07 0 012.11-2.033 7.982 7.982 0 014.52-1.364h1.201l3.789-.77a.796.796 0 011.01.77.796.796 0 01-.442.72l-3.915 1.957a1.263 1.263 0 001.136 2.248l3.903-1.945a3.32 3.32 0 001.73-2.134l4.673-2.336a.998.998 0 011.137 1.591v-.013z"
      />
    </svg>
  );
};

Icon.propTypes = {
  pathFill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Icon.defaultProps = {
  pathFill: "#242625",
  width: "34",
  height: "34"
};

export default Icon;
