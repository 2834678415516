import {
  ENDPOINT_UNITS,
  ENDPOINT_TIMEOUT_UNITS,
  ENDPOINT_GARAGE_CONTRACTS,
  ENDPOINT_MILESTONES,
  API_FINANCEIRO_NAME
} from "utils/constants";
import { axiosInstance } from "../servicesSetup";

export const getUnidade = async () => {
  const instance = await axiosInstance();
  return instance.get(ENDPOINT_UNITS, {
    timeout: ENDPOINT_TIMEOUT_UNITS * 1000,
    withCredentials: true,
    params: {
      refreshCache: true
    }
  });
};

export const getGarageContracts = async () => {
  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  return instance.get(ENDPOINT_GARAGE_CONTRACTS);
};

export const getPercents = async contract => {
  const instance = await axiosInstance();
  return instance.get(`/bloco/${contract.blocoId}/percentual`, {
    params: {
      empreendimentoId: contract.empreendimentoId,
      unidadeId: contract.unidadeId
    },
    withCredentials: true
  });
};

export const getNarrativa = async blocoId => {
  const instance = await axiosInstance();
  return instance.get(`/bloco/${blocoId}/percentualGeral`, {
    withCredentials: true
  });
};

export const getMilestones = async contract => {
  const instance = await axiosInstance();
  return instance.get(ENDPOINT_MILESTONES, {
    params: {
      unidadeId: contract.unidadeId
    },
    withCredentials: true
  });
};
