import styled from "styled-components/macro";
import typography from "@company-mrv/mrv-design-system/typography";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { getThemePropertyValue } from "utils/pluginHelpers";

// eslint-disable-next-line import/prefer-default-export
export const H1 = styled.h1`
  color: ${getThemePropertyValue(
    "baseComponents.H1.color",
    tokens.colorNeutralXxdark
  )};
  ${typography.title2.styles};
  margin: 0;
`;
