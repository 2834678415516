import React from "react";
import PropTypes from "prop-types";

const Check = ({ viewBox, className, height, width, fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    width={width}
    height={height}
    className={className}
    viewBox={viewBox}
    {...rest}
  >
    <path
      d="M1.625 4.59172L5.08478 8.125L10.375 1.875"
      stroke="white"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Check.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  id: PropTypes.string.isRequired
};

Check.defaultProps = {
  className: "",
  height: "10",
  width: "10",
  viewBox: "0 0 11 11",
  fill: "none"
};

export default Check;
