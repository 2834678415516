import styled from "styled-components/macro";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { getThemePropertyValue } from "utils/pluginHelpers";

const colorsMap = {
  default: tokens.colorNeutralMedium,
  orange: getThemePropertyValue(
    "milestones.steps.status.orange",
    tokens.colorBrandSecondaryPure
  ),
  primary: getThemePropertyValue(
    "milestones.steps.status.primary",
    tokens.colorBrandPrimaryPure
  )
};

export const Tag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${props => colorsMap[props.color]};
  padding: ${tokens.spacingStackXxSmall} 10px;
  font-weight: ${tokens.fontWeightRegular};
  font-size: ${tokens.fontSizeXxxSmall};
  line-height: ${tokens.lineHeightMedium};
  border-radius: ${tokens.borderRadiusSmall};
  color: ${props => colorsMap[props.color]};
`;
