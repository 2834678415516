import React from "react";
import PropTypes from "prop-types";

const GiftIcon = ({ className, height, width, fill }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 3H8.91C8.965 2.845 9 2.675 9 2.5C9 1.67 8.33 1 7.5 1C6.975 1 6.52 1.27 6.25 1.675L6 2.01L5.75 1.67C5.48 1.27 5.025 1 4.5 1C3.67 1 3 1.67 3 2.5C3 2.675 3.035 2.845 3.09 3H2C1.445 3 1.005 3.445 1.005 4L1 9.5C1 10.055 1.445 10.5 2 10.5H10C10.555 10.5 11 10.055 11 9.5V4C11 3.445 10.555 3 10 3ZM7.5 2C7.775 2 8 2.225 8 2.5C8 2.775 7.775 3 7.5 3C7.225 3 7 2.775 7 2.5C7 2.225 7.225 2 7.5 2ZM4.5 2C4.775 2 5 2.225 5 2.5C5 2.775 4.775 3 4.5 3C4.225 3 4 2.775 4 2.5C4 2.225 4.225 2 4.5 2ZM10 9.5H2V8.5H10V9.5ZM10 7H2V4.5C2 4.225 2.225 4 2.5 4H4.54L3.8 5.01C3.635 5.235 3.685 5.55 3.91 5.71C4.13 5.87 4.445 5.82 4.605 5.6L6 3.7L7.395 5.6C7.555 5.82 7.87 5.87 8.09 5.71C8.315 5.55 8.365 5.235 8.2 5.01L7.46 4H9.5C9.775 4 10 4.225 10 4.5V7Z"
      fill={fill}
    />
  </svg>
);

GiftIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string
};

GiftIcon.defaultProps = {
  className: "",
  height: "12",
  width: "12",
  fill: "#D9DDDB"
};

export default GiftIcon;
