import React from "react";
import PropTypes from "prop-types";
import { gtmDataLayerPush } from "utils/functions";
import Error from "pages/error";
import { trackTraceException } from "services/trackInsights";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    gtmDataLayerPush("applicationCrash", {
      eventValue: `${error}\n${errorInfo.componentStack}`
    });
    trackTraceException("ErrorBoundary", error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <Error boundary />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
