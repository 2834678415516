// eslint-disable-next-line max-classes-per-file
import {
  LOGIN_REDIRECT_URL,
  LOUGOUT_URL,
  LOGIN_AUTHORITY_SCOPE,
  SILENT_URL
} from "utils/constants";
import { redirect } from "@company-mrv/mrv-design-system/components";
import MOCK from "utils/mock";

const LOCAL_STORAGE_KEY = "IDSAuthMock";

class UserManager {
  addUserLoadedList = [];

  events = {
    addUserLoaded: async cb => {
      // eslint-disable-next-line no-undef
      this.addUserLoadedList.push(cb);
      const user = await this.getUser();
      if (user) {
        cb(user);
      }
    },
    addAccessTokenExpired: () => {}
  };

  constructor(settings) {
    this.settings = settings;
  }

  clearStaleState = async () =>
    new Promise(acept => {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      acept(true);
    });

  getUser = async () =>
    new Promise(acept => {
      const user = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (user) {
        return acept(JSON.parse(user));
      }
      return acept(undefined);
    });

  removeUser = () => {
    const returnDefault = true;
    return returnDefault;
  };

  signinRedirect = async () => {
    redirect("/relacionamento/entrar-mock");
  };

  signinRedirectCallback = async () =>
    new Promise((acept, rej) => {
      const user = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (user) {
        acept(true);
      }
      rej();
    });

  signinSilent = async () =>
    new Promise((acept, rej) => {
      const user = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (user) {
        acept(true);
      }
      rej();
    });

  signinSilentCallback = async () =>
    new Promise(acept => {
      const user = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (user) {
        acept(JSON.parse(user));
      }
      acept(undefined);
    });

  signoutRedirect = async () => {
    await this.clearStaleState();
    redirect(this.settings.redirect_uri);
  };

  signoutRedirectCallback = async () => {
    const returnDefault = true;
    return returnDefault;
  };
}

class AuthService {
  constructor() {
    const settings = {
      // authority: LOGIN_AUTHORITY_URL,
      // client_id: LOGIN_CLIENT_ID,
      redirect_uri: LOGIN_REDIRECT_URL,
      post_logout_redirect_uri: LOUGOUT_URL,
      // userStore: new WebStorageStateStore({ store: window.localStorage }),
      // response_type: 'code',
      scope: LOGIN_AUTHORITY_SCOPE,
      automaticSilentRenew: false,
      silent_redirect_uri: SILENT_URL,
      // response_mode: 'query',
      revokeAccessTokenOnSignout: true
    };
    this.userManager = new UserManager(settings);
    this.eventTokenExpired = false;
  }

  tokenExpired = async () => {
    if (!this.eventTokenExpired) {
      this.eventTokenExpired = true;
    }
  };

  // only exists here (mock)
  setUser(cpf) {
    const user = MOCK[cpf] !== undefined ? MOCK[cpf] : MOCK["000.000.000-01"];
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user.auth));
    this.userManager.addUserLoadedList.forEach(f => f(user.auth));
    return user.auth;
  }

  getIdToken = async () => {
    const user = await this.getUser();
    return (user || {}).id_token;
  };

  login = async () => {
    return this.userManager.signinRedirect();
  };

  renew = async () => {
    return this.userManager.signinSilent();
  };

  renewCallback = async () => {
    return this.userManager.signinSilentCallback();
  };

  logout = async () => {
    const idToken = await this.getIdToken();
    await this.userManager.signoutRedirect({
      id_token_hint: idToken
    });
  };

  signoutRedirectCallback = async () => {
    return new Promise((acept, rej) => {
      this.userManager
        .signoutRedirectCallback()
        .then(() => {
          localStorage.clear();
          this.userManager.removeUser();
          this.userManager.clearStaleState();
          acept();
        })
        .catch(err => {
          rej(err);
        });
    });
  };

  getUser = async () => {
    return this.userManager.getUser();
  };

  getEvents = () => {
    return this.userManager.events;
  };

  loginCallback = async () => {
    return this.userManager.signinRedirectCallback();
  };

  clear = async () => {
    return this.userManager.clearStaleState();
  };
}

export default new AuthService();
