import React from "react";
import StyledIcon from "./styledIcon";

const ArrowRight = ({ ...props }) => (
  <StyledIcon viewBox="0 0 16 16" {...props}>
    <rect width="16" height="16" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.05904 1.6731C5.74662 1.36068 5.24009 1.36068 4.92767 1.6731C4.61525 1.98552 4.61526 2.49205 4.92767 2.80447L10.2019 8.07871L4.92767 13.3529C4.61525 13.6654 4.61525 14.1719 4.92767 14.4843C5.24009 14.7967 5.74662 14.7967 6.05904 14.4843L11.7281 8.81526C11.928 8.61532 12 8.33589 11.9441 8.07871C12 7.82152 11.928 7.54209 11.7281 7.34215L6.05904 1.6731Z"
      fill="white"
    />
  </StyledIcon>
);

export default ArrowRight;
