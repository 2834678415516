import React from "react";
import { Helmet } from "react-helmet";
import { getThemePropertyValue, isInHiddenList } from "utils/pluginHelpers";
import { GtmPageProvider } from "@company-mrv/mrv-design-system/components";
import { trackTrace } from "services/trackInsights";
import { useLocation, useHistory } from "react-router-dom";
import { MENU_REDIRECT_LINKS, ROUTES } from "utils/constants";

import * as Styled from "./styles";

function ErrorPage(origin) {
  const location = useLocation();
  const history = useHistory();
  let isBoundary = false;
  if (origin.boundary) {
    isBoundary = true;
  }
  function handleClick() {
    trackTrace("Clique no botão MIA", "Redirecionamento feito com sucesso");
    window.open(MENU_REDIRECT_LINKS.MARIA_ROSA, "_blank");
  }
  if (location.state) {
    trackTrace("Tela de FAQ", `Função: ${location.state.detail}`);
  } else if (
    history.location.pathname !== "/relacionamento/erro/homolog" &&
    isBoundary === false
  ) {
    trackTrace("Tela de FAQ", "Acesso direto pela URL");
    history.push({ pathname: ROUTES.TRACK_WORK });
  }
  return (
    <GtmPageProvider page="Página de erro">
      <Styled.Container data-testid="erro-content">
        <Helmet>
          <title>Não foi possível exibir essa página.</title>
        </Helmet>

        <Styled.Illustration />

        <Styled.Content className="gtm-class-cat-erro">
          <Styled.StatusCode>
            Não foi possível exibir essa página
          </Styled.StatusCode>
          <Styled.Title>
            Estamos com uma instabilidade e já
            <br /> atuando para solucioná-la.
          </Styled.Title>
          <Styled.Subtitle>Tente novamente mais tarde.</Styled.Subtitle>

          <Styled.Description>
            {getThemePropertyValue(
              "errorPage.description.textInfo",
              "Enquanto isso, você pode acessar o nosso WhatsApp e solicitar o que deseja para a MIA, nossa assistente virtual.\n\n"
            )}
          </Styled.Description>
        </Styled.Content>
        {!isInHiddenList("ErrorIlustration") && (
          <Styled.Button onClick={handleClick}>Iniciar conversa</Styled.Button>
        )}
      </Styled.Container>
    </GtmPageProvider>
  );
}

export default ErrorPage;
