import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tokens from "@company-mrv/mrv-design-system/tokens";

const Icon = ({ a11yTitle, ...props }) => (
  <svg aria-label={a11yTitle} {...props} />
);

Icon.propTypes = {
  a11yTitle: PropTypes.string.isRequired
};

const StyledIcon = styled(Icon)`
  display: inline-block;

  g {
    fill: inherit;
    stroke: inherit;
  }

  ${({ color }) => color && `path { fill: ${color} }`}

  ${({ size }) => {
    if (!size) return null;
    if (size.toString().indexOf("px") === -1) {
      return `
        width: ${size}px;
        height: ${size}px;
      `;
    }
    return `
      width: ${size};
      height: ${size};
    `;
  }}
`;

StyledIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  /** Título alternativo. Caso o ícone seja de apresentação, use uma string vazia */
  a11yTitle: PropTypes.string.isRequired
};

StyledIcon.defaultProps = {
  color: tokens.colorNeutralXxdark,
  size: "32px"
};

export default StyledIcon;
