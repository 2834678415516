import { breakpoints as baseBreakpoints } from "@company-mrv/mrv-design-system/metrics";
import { gridQueries, gridMedia } from "./grid";

export const breakpoints = { ...baseBreakpoints };

export const queries = {
  ...gridQueries,
  forMobileOnly: `(max-width: 640px)`,
  forSmallMobileUp: `(min-width: ${breakpoints.extraSmall})`,
  forTabletUp: `(min-width: ${breakpoints.small})`,
  forDesktopUp: `(min-width: ${breakpoints.medium})`,
  forLargeDesktopUp: `(min-width: ${breakpoints.large})`
};

export const media = {
  ...gridMedia,
  forMobileOnly: `@media ${queries.forMobileOnly}`,
  forSmallMobileUp: `@media ${queries.forSmallMobileUp}`,
  forTabletUp: `@media ${queries.forTabletUp}`,
  forDesktopUp: `@media ${queries.forDesktopUp}`,
  forLargeDesktopUp: `@media ${queries.forLargeDesktopUp}`
};
