import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import GeneralContext from "contexts/general";
import { LoadingWrapper } from "components";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import Auth from "services/auth";
import { isInHiddenList } from "utils/pluginHelpers";
import { trackException, trackTraceException } from "services/trackInsights";

const AsyncSilentCallback = React.lazy(() =>
  import("pages/loginSilentCallback")
);
const AsyncAuthentication = React.lazy(() => import("pages/authentication"));
const AsyncHome = React.lazy(() => import("pages/home"));
const AsyncTrackWork = React.lazy(() => import("pages/trackWork"));
const AsyncLogout = React.lazy(() => import("pages/logout"));
const AsyncError = React.lazy(() => import("pages/error"));
const AsyncFeed = React.lazy(() => import("pages/feed"));
const AsyncMilestones = React.lazy(() => import("pages/milestones"));
const AsyncNotifications = React.lazy(() => import("pages/notifications"));
const AsyncNotification = React.lazy(() => import("pages/notification"));
const AsyncRecommendationEmail = React.lazy(() =>
  import("pages/recommendationEmail")
);
const AsyncUtilizeCoupon = React.lazy(() => import("pages/addCoupon"));
const AsyncUtilizeCouponEmail = React.lazy(() =>
  import("pages/addCouponEmail")
);
const AsyncLoginMock = React.lazy(() => import("pages/loginMock"));
const AsyncDocumentsHome = React.lazy(() => import("pages/documents/home"));
const AsyncDocumentsFloorPlans = React.lazy(() =>
  import("pages/documents/floorPlans")
);
const AsyncTermOnboarding = React.lazy(() => import("pages/termOnboarding"));
const AsyncTermOnboardingSuccess = React.lazy(() =>
  import("pages/termOnboardingSuccess")
);
const AsyncTermOnboardingSign = React.lazy(() =>
  import("pages/termOnboardingSign")
);
const AsyncTermOnboardingFisico = React.lazy(() =>
  import("pages/termOnboardingFisico")
);

const AsyncDocumentsManuais = React.lazy(() =>
  import("pages/documents/manuais")
);
const AsyncDocumentsManualProprietario = React.lazy(() =>
  import("pages/documents/manuais_proprietario")
);
const AsyncDocumentsManuaisAreasComuns = React.lazy(() =>
  import("pages/documents/manuais_areas_comuns")
);
const AsyncDocumentsBalancetes = React.lazy(() =>
  import("pages/documents/balancetes")
);
const AsyncIndicacaoOnboarding = React.lazy(() =>
  import("pages/indicacao/onboarding")
);
const AsyncIndicacaoMain = React.lazy(() => import("pages/indicacao/main"));

const AsyncIndicacaoDetails = React.lazy(() =>
  import("pages/indicacao/details")
);

const COUNT_KEY = "count_reloads";
const MAX_RELOADS = 5;

// eslint-disable-next-line react/prop-types
export default ({ childProps }) => {
  const { generalLoading } = useContext(GeneralContext);

  const getCount = () => {
    if (typeof Storage !== "undefined") {
      const count = Number(window.localStorage.getItem(COUNT_KEY)) || 0;
      return count;
    }

    return 0;
  };

  const setCount = count => {
    if (typeof Storage !== "undefined") {
      const newCount = count !== undefined ? count : getCount() + 1;
      window.localStorage.setItem(COUNT_KEY, newCount);
    }
  };

  const requireLogin = async (to, from, next) => {
    const user = await Auth.getUser();

    if (user && user.expired !== undefined) {
      if (user.expired) {
        const count = getCount();

        if (count > MAX_RELOADS) {
          setCount(0);
          const error = {
            message: "User expirou"
          };
          trackException(error);
          trackTraceException("requireLogin", error);
          next.redirect(ROUTES.ERROR);
        } else {
          await Auth.renew()
            .then(async () => {
              setCount();
              window.location.reload();
            })
            .catch(async () => {
              await Auth.login();
            });
        }
      } else {
        setCount(0);
        await Auth.tokenExpired();
        next();
      }
    } else {
      setCount(0);
      await Auth.login();
    }
  };

  const requireNegotiation = async (to, from, next) => {
    if (isInHiddenList("NegotiationSection")) {
      next.redirect(ROUTES.HOME);
    } else {
      next();
    }
  };

  return (
    <>
      <GuardProvider loading={LoadingWrapper} error={AsyncError}>
        <Switch>
          <GuardedRoute
            path={ROUTES.SILENT}
            exact
            component={AsyncSilentCallback}
            props={childProps}
          />
          <GuardedRoute
            path={ROUTES.ERROR}
            exact
            component={AsyncError}
            props={childProps}
          />
          <GuardedRoute
            path={ROUTES.ERROR_HOMOLOG}
            exact
            component={AsyncError}
            props={childProps}
          />
          <GuardedRoute
            path={ROUTES.LOGOUT}
            exact
            component={AsyncLogout}
            props={childProps}
          />
          <GuardedRoute
            path={ROUTES.TRACK_WORK}
            exact
            component={AsyncTrackWork}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.HOME}
            exact
            component={AsyncHome}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={`${ROUTES.MILESTONES}/:section`}
            exact
            component={AsyncMilestones}
            props={childProps}
            guards={[requireLogin]}
          />
          <Route
            path={`${ROUTES.USE_COUPON}/:userId`}
            exact
            component={AsyncUtilizeCoupon}
          />
          <Route
            path={`${ROUTES.USE_COUPON_EMAIL}`}
            exact
            component={AsyncUtilizeCouponEmail}
          />
          <GuardedRoute
            path={ROUTES.FEED}
            exact
            component={AsyncFeed}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.RECOMMENDATION}
            component={props => (
              <Redirect to={ROUTES.RECOMMENDATION_NEW_MAIN} {...props} />
            )}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.RECOMMENDATION_EMAIL}
            exact
            component={AsyncRecommendationEmail}
            props={childProps}
          />
          <GuardedRoute
            path={ROUTES.NOTIFICATIONS}
            exact
            component={AsyncNotifications}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={`${ROUTES.NOTIFICATIONS}/:notificationId`}
            exact
            component={AsyncNotification}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.DOCUMENTS}
            exact
            component={AsyncDocumentsHome}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.DOCUMENTS_FLOOR_PLANS}
            exact
            component={AsyncDocumentsFloorPlans}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.DOCUMENTS_MANUAIS}
            exact
            component={AsyncDocumentsManuais}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.DOCUMENTS_MANUAIS_PROPRIETARIO}
            exact
            component={AsyncDocumentsManualProprietario}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.DOCUMENTS_MANUAIS_AREAS_COMUNS}
            exact
            component={AsyncDocumentsManuaisAreasComuns}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.DOCUMENTS_BALANCETES}
            exact
            component={AsyncDocumentsBalancetes}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.RECOMMENDATION_NEW_ONBOARDING}
            exact
            component={AsyncIndicacaoOnboarding}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.RECOMMENDATION_NEW_MAIN}
            exact
            component={AsyncIndicacaoMain}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.RECOMMENDATION_NEW_DETAILS}
            exact
            component={AsyncIndicacaoDetails}
            props={childProps}
            guards={[requireLogin]}
          />
          <GuardedRoute
            path={ROUTES.LOGIN_MOCK}
            exact
            component={AsyncLoginMock}
            props={childProps}
          />
          <Route
            path={ROUTES.LOGIN}
            exact
            component={AsyncAuthentication}
            props={childProps}
          />
          <Route
            path={ROUTES.TERM_ONBOARDING}
            exact
            component={AsyncTermOnboarding}
            props={childProps}
            guards={[requireLogin, requireNegotiation]}
          />
          <Route
            path={ROUTES.TERM_ONBOARDING_SUCCESS}
            exact
            component={AsyncTermOnboardingSuccess}
            props={childProps}
            guards={[requireLogin, requireNegotiation]}
          />
          <Route
            path={ROUTES.TERM_ONBOARDING_SIGN}
            exact
            component={AsyncTermOnboardingSign}
            props={childProps}
            guards={[requireLogin, requireNegotiation]}
          />
          <Route
            path={ROUTES.TERM_ONBOARDING_FISICO}
            exact
            component={AsyncTermOnboardingFisico}
            props={childProps}
            guards={[requireLogin, requireNegotiation]}
          />
          <GuardedRoute
            path="*"
            component={props => <Redirect to={ROUTES.HOME} {...props} />}
            props={childProps}
            guards={[requireLogin]}
          />
        </Switch>
      </GuardProvider>
      {generalLoading && <LoadingWrapper />}
    </>
  );
};
