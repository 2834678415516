import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef
} from "react";
import PropTypes from "prop-types";
import { CARD_TYPE } from "utils/constants";
import { getMilestones, getPercents } from "services/trackWork";
import { MILESTONES_LOADING_STATUS } from "utils/milestones/constants";
import ContractContext from "contexts/contract";
import { trackException, trackTraceException } from "services/trackInsights";

const TrackWorkContext = React.createContext({
  loading: false,
  setLoading: () => {},
  percentsLoaded: false,
  percentsLoading: false,
  percentsErrorStatus: false,
  setPercentsLoaded: () => {},
  setPercentsLoading: () => {},
  setPercentsErrorStatus: () => {},
  pace: "",
  setPace: () => {},
  loadPercents: () => {},
  milestones: [],
  setMilestones: () => {},
  milestonesLoaded: false,
  milestonesLoadingStatus: null,
  setMilestonesLoadingStatus: () => {},
  loadMilestones: () => {}
});

export const TrackWorkContextProvider = ({ children }) => {
  const { selectedContract } = useContext(ContractContext);
  const [isLoading, setLoading] = useState(true);
  const [pace, setPace] = useState({
    blocoId: "",
    lastUpdated: "",
    [CARD_TYPE.FUNDACAO]: {
      news: false,
      percentual: 0,
      completed: false
    },
    [CARD_TYPE.ESTRUTURA]: {
      news: false,
      percentual: 0,
      completed: false
    },
    [CARD_TYPE.ALVENARIA]: {
      news: false,
      percentual: 0,
      completed: false
    },
    [CARD_TYPE.ACABAMENTO]: {
      news: true,
      percentual: 0,
      completed: false
    },
    [CARD_TYPE.AREA_COMUM]: {
      news: false,
      percentual: 0,
      completed: false
    },
    total: {
      percentual: 0,
      completed: false
    }
  });

  const [percentsLoading, setPercentsLoading] = useState(false);
  const [percentsLoaded, setPercentsLoaded] = useState(false);
  const [percentsErrorStatus, setPercentsErrorStatus] = useState(false);

  const loadPercents = useCallback(async () => {
    const load = async () => {
      try {
        setPercentsLoading(true);
        const responsePercents = await getPercents(selectedContract);
        setPace(responsePercents.data.data);
        setPercentsLoaded(true);
        setPercentsLoading(false);
        setPercentsErrorStatus(false);
      } catch (error) {
        trackException(error);
        trackTraceException("loadPercents", error);
        setPercentsLoading(false);
        setPercentsErrorStatus(true);
      }
    };
    if (selectedContract && selectedContract.empreendimentoId) {
      await load();
    }
  }, [selectedContract]);

  const percentsLoadedRef = useRef();
  percentsLoadedRef.current = percentsLoaded;
  const percentsLoadingRef = useRef();
  percentsLoadingRef.current = percentsLoading;

  useEffect(() => {
    if (percentsLoadedRef.current && !percentsLoadingRef.current) {
      loadPercents();
    }
  }, [selectedContract, loadPercents]);

  const [milestonesLoadingStatus, setMilestonesLoadingStatus] = useState(null);

  const [milestonesLoaded, setMilestonesLoaded] = useState(false);

  const [milestones, setMilestones] = useState([
    {
      id: 1,
      status: 1
    },
    {
      id: 2,
      status: 1
    },
    {
      id: 3,
      status: 1
    }
  ]);

  const loadMilestones = useCallback(async () => {
    const load = async () => {
      try {
        setMilestonesLoadingStatus(MILESTONES_LOADING_STATUS.LOADING);
        const responseMilestones = await getMilestones(selectedContract);
        setMilestones(responseMilestones.data.data);
        setMilestonesLoaded(true);
        setMilestonesLoadingStatus(MILESTONES_LOADING_STATUS.LOADED);
      } catch (error) {
        trackException(error);
        trackTraceException("loadMilestones", error);
        setMilestonesLoadingStatus(MILESTONES_LOADING_STATUS.ERROR);
      }
    };
    if (selectedContract && selectedContract.empreendimentoId) {
      await load();
    }
  }, [selectedContract, setMilestonesLoadingStatus, setMilestones]);

  const milestonesLoadedRef = useRef();
  milestonesLoadedRef.current = milestonesLoaded;
  const milestonesLoadingStatusRef = useRef();
  milestonesLoadingStatusRef.current = milestonesLoadingStatus;

  useEffect(() => {
    if (
      milestonesLoadedRef.current &&
      milestonesLoadingStatusRef.current !== MILESTONES_LOADING_STATUS.LOADING
    ) {
      loadMilestones();
    }
  }, [selectedContract, loadMilestones]);

  const value = React.useMemo(
    () => ({
      isLoading,
      setLoading,
      percentsLoading,
      percentsLoaded,
      percentsErrorStatus,
      setPercentsLoaded,
      setPercentsLoading,
      setPercentsErrorStatus,
      pace,
      setPace,
      loadPercents,
      milestones,
      setMilestones,
      milestonesLoaded,
      milestonesLoadingStatus,
      setMilestonesLoadingStatus,
      loadMilestones
    }),
    [
      isLoading,
      percentsLoading,
      percentsLoaded,
      percentsErrorStatus,
      pace,
      loadPercents,
      milestones,
      milestonesLoaded,
      milestonesLoadingStatus,
      loadMilestones
    ]
  );

  return (
    <TrackWorkContext.Provider value={value}>
      {children}
    </TrackWorkContext.Provider>
  );
};

TrackWorkContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TrackWorkContext;
