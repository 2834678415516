import React from "react";
import StyledIcon from "./styledIcon";

const ArrowLeft = ({ ...props }) => (
  <StyledIcon viewBox="0 0 16 16" {...props}>
    <rect width="16" height="16" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5967 1.6731C10.9091 1.36068 11.4157 1.36068 11.7281 1.6731C12.0405 1.98552 12.0405 2.49205 11.7281 2.80447L6.45385 8.07871L11.7281 13.3529C12.0405 13.6654 12.0405 14.1719 11.7281 14.4843C11.4157 14.7967 10.9091 14.7967 10.5967 14.4843L4.92766 8.81526C4.72773 8.61532 4.65574 8.33589 4.7117 8.07871C4.65574 7.82152 4.72773 7.54209 4.92766 7.34215L10.5967 1.6731Z"
      fill="white"
    />
  </StyledIcon>
);

export default ArrowLeft;
