import React from "react";
import PropTypes from "prop-types";

const Icon = ({ pathFill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 26 36"
      {...rest}
    >
      <path
        fill={pathFill}
        fillRule="evenodd"
        d="M14.696 9.925c0-.577.506-1.044 1.13-1.044h9.044c.624 0 1.13.467 1.13 1.044v24.553c0 .577-.506 1.044-1.13 1.044h-9.044c-.505 0-.948-.309-1.087-.757l-1.13-3.657a.97.97 0 01.037-.675l1.048-2.422-.002-.46a325.548 325.548 0 01-.003-1.532c0-.13 0-.234.002-.31l.003-.108a.999.999 0 01.035-.257c.029-.106.059-.176.61-1.442.123-.284.25-.577.379-.87l-2.599-4.804a.973.973 0 01-.047-.834l1.624-4.001V9.925zm.666 7.776l2.606 4.817a.973.973 0 01.038.855l-1.05 2.425v.321h2.827V14.627h-3.174L15.363 17.7zm1.594-5.164h3.957c.624 0 1.13.468 1.13 1.045v13.582c0 .577-.506 1.045-1.13 1.045h-3.957a.976.976 0 01-.08.388l-.987 2.28.79 2.556h7.06V10.97h-6.783v1.567zm-5.695 21.654c.206.667-.336 1.331-1.087 1.331H1.13c-.624 0-1.13-.467-1.13-1.044V9.925c0-.577.506-1.044 1.13-1.044h9.044c.624 0 1.13.467 1.13 1.044v3.657c0 .125-.024.25-.072.367L9.71 17.7l2.605 4.817a.973.973 0 01.039.855l-1.05 2.425v2.41a.976.976 0 01-.08.389l-.987 2.28 1.024 3.314zm-5.044-8.072h2.826v-.522c0-.133.028-.265.081-.388l.942-2.176-2.599-4.805a.973.973 0 01-.047-.834l1.123-2.767H6.217v11.492zm2.826-13.582V10.97H2.261v22.463h6.414l-.718-2.325a.97.97 0 01.037-.675l.962-2.224H5.087c-.624 0-1.13-.468-1.13-1.045V13.582c0-.577.506-1.045 1.13-1.045h3.956zM6.55 5.963l-2.261-2.09c-1.066-.985.532-2.462 1.598-1.477l2.261 2.09c1.066.984-.533 2.462-1.599 1.477zM14.13 4.7c0 .578-.506 1.045-1.13 1.045-.624 0-1.13-.468-1.13-1.045V1.045C11.87.468 12.376 0 13 0c.624 0 1.13.468 1.13 1.045V4.7zm5.321 1.262a1.197 1.197 0 01-1.598 0 .99.99 0 010-1.478l2.26-2.09a1.197 1.197 0 011.6 0 .99.99 0 010 1.478l-2.262 2.09z"
        clipRule="evenodd"
      />
    </svg>
  );
};

Icon.propTypes = {
  pathFill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Icon.defaultProps = {
  pathFill: "#242625",
  width: "26",
  height: "36"
};

export default Icon;
