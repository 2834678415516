import styled from "styled-components/macro";
import typography from "@company-mrv/mrv-design-system/typography";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { media } from "utils/styles/breakpoints";

// eslint-disable-next-line import/prefer-default-export
export const P = styled.p`
  ${typography.small.styles};
  color: ${tokens.colorNeutralXxdark};

  ${media.forTabletUp} {
    ${typography.body2.styles};
  }
`;
