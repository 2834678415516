import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { usePrompt } from "@company-mrv/mrv-design-system/components";
import { useHistory } from "react-router-dom";
import { getThemeName } from "utils/pluginHelpers";
import { ROUTES } from "utils/constants";
import { getDocumentsType, getDocuments } from "services/documents";
import { getUnidade } from "services/trackWork";
import ContractContext from "contexts/contract";
import { trackException, trackTrace, trackTraceException } from "services/trackInsights";
import { PDFDocument } from 'pdf-lib';
import pushGTM from "utils/gtm/pushGTM";

const DocumentsContext = React.createContext();

const DocumentsProvider = ({ children }) => {
  const history = useHistory();
  const { showPrompt } = usePrompt();
  const { selectedContract, setContracts } = useContext(ContractContext);
  const [isContractLoaded, setIsContractLoaded] = useState(false);
  const [{ documents, status }, setState] = useState({
    documents: [],
    filesToDownload: [],
    status: "idle"
  });

  useEffect(() => {
    if (isSelectedContractInvalid()) {
      setIsContractLoaded(false);
    } else {
      setIsContractLoaded(true);
    }
  }, [selectedContract])

  const fetchDocuments = async () => {
    setState(prevState => ({
      ...prevState,
      status: "pending"
    }));
    try {
      const data = await getDocumentsType();
      setState(prevState => ({
        ...prevState,
        documents: data,
        status: "resolved"
      }));
    } catch (error) {
      trackException(error);
      trackTraceException("getDocumentsType", error);
      setState(prevState => ({
        ...prevState,
        status: "rejected"
      }));
      if (error && error.message && error.message.includes("code 403")) {
        history.push(ROUTES.ERROR, {
          text: "Parece que você não tem permissão para acessar essa página :("
        });
      } else {
        history.push({
          pathname: ROUTES.ERROR,
          state: { detail: "getDocumentsType" }
        });
      }
    }
  };

  const getUnitList = async () => {
    const getContracts = async () => {
      const responseUnidade = await getUnidade();
      return responseUnidade.data.data;
    };
    const contracts = await getContracts();
    setContracts(contracts);
  };

  const getInitialInformationsAboutDocuments = () => {
    fetchDocuments();
    getUnitList();
  };

  const merge = async (firstFile, secondFile) => {
    const pdf1 = await PDFDocument.load(firstFile);
    const pdf2 = await PDFDocument.load(secondFile);

    const mergedPdf = await PDFDocument.create();
    const copiedPagesA = await mergedPdf.copyPages(pdf1, pdf1.getPageIndices());
    copiedPagesA.forEach((page) => mergedPdf.addPage(page));
    const copiedPagesB = await mergedPdf.copyPages(pdf2, pdf2.getPageIndices());
    copiedPagesB.forEach((page) => mergedPdf.addPage(page));
    const mergedPdfFile = await mergedPdf.save();
    return mergedPdfFile;
  }

  const mergeFiles = async (firstFile, secondFile, isFirstMerge = false) => {
    if (isFirstMerge) {
      let base641 = 'data:application/pdf;base64,' + firstFile;
      let base642 = 'data:application/pdf;base64,' + secondFile;
      return await merge(base641, base642);
    }
    else {
      let base64 = 'data:application/pdf;base64,' + secondFile;
      return await merge(firstFile, base64);
    }
  }

  const baixarDocumentosBase64 = async (data) => {
    const arquivosFiltradosBase64 = data.filter(x => x.arquivoBase64 !== null);
    let merge;
    if (arquivosFiltradosBase64 !== null && arquivosFiltradosBase64 !== undefined) {
      if (arquivosFiltradosBase64.length > 1) {
        let primeiroDocumento = arquivosFiltradosBase64[0];
        if (primeiroDocumento !== null && primeiroDocumento !== undefined) {
          for (let i = 1; i < arquivosFiltradosBase64.length; i++) {
            let documento = arquivosFiltradosBase64[i];
            if (i > 1)
              merge = await mergeFiles(merge, documento.arquivoBase64);
            else
              merge = await mergeFiles(primeiroDocumento.arquivoBase64, documento.arquivoBase64, true);
          }
          doDownloadBytes(merge, primeiroDocumento.nome);
        }
      }
      else {
        let documento = arquivosFiltradosBase64[0];
        if (documento !== null && documento !== undefined) {
          doDownloadBase64(documento.arquivoBase64, documento.nome);
        }
      }
    }
  }

  const baixarDocumentosContrato = (data) => {
    const arquivosFiltradosUrl = data.filter(x => x.url !== null);
    if (arquivosFiltradosUrl !== null && arquivosFiltradosUrl !== undefined) {
      let documento = arquivosFiltradosUrl[0];
      if (documento !== null && documento !== undefined) {
        doDownloadUrl(documento.url, documento.nome);
      }
    }
  }

  const baixarDocumentos = async (data) => {
    const { id: contractId } = selectedContract;
    try {
      if (data != null) {

        //Baixar plantas
        await baixarDocumentosBase64(data);

        //Baixar contrato
        baixarDocumentosContrato(data);
      }
      else
        throw new Error("A pesquisa não retornou resultados.");
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        status: "rejected"
      }));

      trackException(error);
      trackTraceException("baixarDocumentos", error);

      const promptContent = {
        title: "Não foi possível exibir seu documento",
        text: error.message
      };

      showPrompt({
        ...promptContent,
        confirmButtonText: "Ir para o Fale Conosco",
        cancelButtonText: "Tentar mais tarde",
        theme: getThemeName()
      }).then(({ result }) => {
        if (result === "confirm") {
          window.location.href = `${window.location.origin}${ROUTES.FALE_CONOSCO}?contractId=${contractId}`;
        }
      });
    }
  }

  const doDownloadUrl = (url, filename) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          filenamePrefix: filename,
          openPopupUrl: url,
        })
      );
    } else {
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = url;
      tempLink.setAttribute("download", filename);
      tempLink.setAttribute("target", "_blank");

      document.body.appendChild(tempLink);
      tempLink.click();
      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      }, 200);
    }
  }

  const doDownloadBytes = (arquivoBytes, filename) => {
    const blob = new Blob([arquivoBytes], { type: 'application/pdf' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      const base64data = reader.result;
      if (window.ReactNativeWebView) {
        const base64 = base64data.split(',')[1];
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            base64,
            filenamePrefix: filename,
            extension: '.pdf'
          })
        );
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.pdf`;
        link.click();
      }
    };
  }

  const doDownloadBase64 = (arquivoBase64, filename) => {
    const arquivoBytes = Buffer.from(arquivoBase64, 'base64');
    doDownloadBytes(arquivoBytes, filename);
  }

  const getDocumentFile = useCallback(async (documentTypeId, documentType) => {
    const { id: contractId } = selectedContract;
    setState(prevState => ({
      ...prevState,
      status: "pending"
    }));
    try {
      pushGTM(
        "Documentos",
        "Meus Documentos",
        documentType,
        "Download"
      );
      if (isSelectedContractInvalid())
        trackTrace(`Unidade selecionada inválida, valor atual:\n${JSON.stringify(selectedContract)}`);

      const data = await getDocuments({ ...selectedContract, documentTypeId });
      await baixarDocumentos(data);
      setState(prevState => ({
        ...prevState,
        status: "resolved"
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        status: "rejected"
      }));
      trackException(error);
      trackTraceException("getDocumentFile", error);
      const isContract = documentType === "contrato";
      const promptContent = {
        title: "Não foi possível exibir seu documento",
        text: "Infelizmente esse documento não está disponível. Pode ficar tranquilo (a)! Para acessar, entre em contato pelo Fale Conosco para que possamos te ajudar."
      };

      if (error?.response?.status === 422) {
        promptContent.text = error.response.data;
      }

      if (isContract) {
        promptContent.text = `Se você assinou seu contrato de forma física, aguarde que em até 90 dias após sua assinatura, você receberá o documento via Correios, no mesmo endereço que cadastro no momento de compra, desde que não tenha nenhuma pendência.  
        Se você assinou o seu contrato de forma digital (assinatura eletrônica), favor entrar em contato pelo Fale Conosco para que possamos te ajudar.`;
      }

      showPrompt({
        ...promptContent,
        confirmButtonText: "Ir para o Fale Conosco",
        cancelButtonText: "Tentar mais tarde",
        theme: getThemeName()
      }).then(({ result }) => {
        if (result === "confirm") {
          window.location.href = `${window.location.origin}${ROUTES.FALE_CONOSCO}?contractId=${contractId}`;
        }
      });
    }
  }, [selectedContract]);

  function isSelectedContractInvalid() {
    return selectedContract == null
      || selectedContract.empreendimentoId == null
      || selectedContract.unidadeId == null;
  }

  const value = React.useMemo(
    () => ({
      documents,
      status,
      getDocumentFile,
      getInitialInformationsAboutDocuments,
      isContractLoaded
    }),
    [
      documents,
      // status,
      getDocumentFile,
      getInitialInformationsAboutDocuments,
      isContractLoaded
    ]
  );
  return (
    <DocumentsContext.Provider value={value}>
      {children}
    </DocumentsContext.Provider>
  );
};

function useDocumentsContext() {
  const context = React.useContext(DocumentsContext);
  if (!context) {
    throw new Error(
      `Toggle compound components cannot be rendered outside the Toggle component`
    );
  }
  return context;
}

DocumentsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { DocumentsProvider, useDocumentsContext };
