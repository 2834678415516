import React, { useContext, useEffect } from "react";
import {
  ContainerButtons,
  Modal,
  Title,
  SubTitle,
  ButtonCustom,
  ContentTop,
  ImageTop,
  ButtonCustomVariantG,
} from "./styles";
import pushGTM from "utils/gtm/pushGTM";
import PropTypes from "prop-types";
import { getUserIdByAccessToken } from "utils/functions";
import { useGtmEventTriggerGeneric } from "@company-mrv/mrv-design-system/components";
import AuthContext from "contexts/auth";

const ModalTermPending = ({ isOpen, onClose, onClickDetails }) => {
  const genericTrigger = useGtmEventTriggerGeneric();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (isOpen) {
      pushGTM(
        "Home Inteligente",
        "Termo Confissão de Dívida",
        "Modal Fique em Dia",
        "Visualização"
      );
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} role="modalMain">
      <ContentTop role="contentTop">
        <ImageTop role="imageTop" />
        <Title role="title" marginB="13px">
          Fique em dia com sua assinatura!
        </Title>
        <SubTitle role="subTitle">
          Sua assinatura do aditivo contratual ainda está pendente.{" "}
          <strong>É importante assinar o quanto antes,</strong> confira a seguir
          os detalhes.
        </SubTitle>
      </ContentTop>
      <ContainerButtons role="containerButtons">
        <ButtonCustom
          role="buttonCustom"
          disableGtm
          onClick={() => {
            pushGTM(
              "Home Inteligente",
              "Termo Confissão de Dívida",
              "Modal Fique em Dia - Conferir Detalhes",
              "Clique"
            );
            genericTrigger({
              eventName: "confissao_dividas",
              action: "Clique",
              userId: getUserIdByAccessToken(user?.access_token),
              parameters: {
                botao: "Conferir Detalhes",
              },
            });
            onClickDetails();
          }}
        >
          Conferir detalhes
        </ButtonCustom>
        <ButtonCustomVariantG
          role="buttonCustomVariantG"
          disableGtm
          color={"primary"}
          onClick={() => {
            pushGTM(
              "Home Inteligente",
              "Termo Confissão de Dívida",
              "Modal Fique em Dia - Mais Tarde",
              "Clique"
            );
            genericTrigger({
              eventName: "confissao_dividas",
              action: "Clique",
              userId: getUserIdByAccessToken(user?.access_token),
              parameters: {
                botao: "Mais Tarde",
              },
            });
            onClose();
          }}
        >
          Mais tarde
        </ButtonCustomVariantG>
      </ContainerButtons>
    </Modal>
  );
};

ModalTermPending.displayName = "ModalTermPending";

ModalTermPending.propTypes = {
  isOpen: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickDetails: PropTypes.func.isRequired,
};

export default ModalTermPending;
