/* eslint-disable */
import TagManager from "react-gtm-module";
import { PUBLIC_ROUTES, REACT_APP_ENV } from "utils/constants";
import jwtDecode from 'jwt-decode';
import moment from "moment/moment";
import { RuntimeEnviroment } from "@company-mrv/mrv-design-system/components";

// eslint-disable-next-line
export const cpfMask = [/\d/,/\d/,/\d/, ".",/\d/,/\d/, /\d/,".",/\d/,/\d/,/\d/,"-",/\d/,/\d/];

// eslint-disable-next-line
export const cnpjMask = [/\d/,/\d/,".",/\d/,/\d/,/\d/,".",/\d/,/\d/,/\d/,"/",/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/];

export const applyCpfCnpjMask = rawValue => {
  const valueWithoutMask = rawValue.replace(/[^\d]/g, "");
  if (valueWithoutMask.length > 11) return cnpjMask;
  return cpfMask;
};

export const gtmDataLayerPush = (event, dataLayer) => {
  TagManager.dataLayer({
    dataLayer: { event, ...dataLayer }
  });
};

export const capitalize = text => {
  const newText = text.toLowerCase();
  return newText.replace(/(?:^|\s)\S/g, a => {
    return a.toUpperCase();
  });
};

export const resolveName = userObj => {
  const getNickname = () => {
    return userObj && userObj.profile && userObj.profile.nickname
      ? userObj.profile.nickname
      : null;
  };

  const getName = () => {
    if (userObj && userObj.profile && userObj.profile.name) {
      let name = userObj.profile.name.split(" ")[0];
      name =
        name
          .split(" ")[0]
          .charAt(0)
          .toUpperCase() + name.slice(1).toLowerCase();

      return name;
    }
    return "";
  };

  return getNickname() || getName();
};

export const formatData = dataCont => {
  const data = new Date(dataCont);
  const dia = data.getDate().toString();
  const diaF = dia.length === 1 ? `0${dia}` : dia;
  const mes = (data.getMonth() + 1).toString();
  const mesF = mes.length === 1 ? `0${mes}` : mes;
  const anoF = data.getFullYear();
  return `${diaF}/${mesF}/${anoF}`;
};

/**
 * Returns the desired value (number) formated as R$ 00,00
 *
 * value - Float value representing the amount of money
 *         to be formatted.
 */
export const formatCurrency = value => {
  const resolvedValue = value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2
  });
  return `R$ ${resolvedValue}`;
};

/**
 *  isRoutePublic attempts to check if the current route is public.
 *
 *  pathname - URL to be used to compare between the public routes
 *             inside the PUBLIC_ROUTES array (utils/constants.js).
 */
export const isRoutePublic = pathname =>
  PUBLIC_ROUTES.some(route => pathname.includes(route));

export class CountTime {
  begin = 0;

  constructor() {
    this.begin = Date.now();
  }

  getTotal = () => {
    const total = Date.now() - this.begin;
    return total / 1000;
  };
}

export const doDownload = (url, filename) => {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.setAttribute('download', filename);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  setTimeout(() => {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  }, 200);
};

export const getUserIdByAccessToken = token => {
  try {
    const decoded = jwtDecode(token);
    if (decoded.client_id) {
      return decoded.client_id;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
};

const detectMob = () => {
  const toMatch = [ /Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator?.userAgent?.match(toMatchItem);
  });
}

export const isMobileOrTablet = () => {
  return (navigator?.userAgentData?.mobile || detectMob());
}

export const formatDate = (date, format = 'DD/MM/YY') => {
  const dateMoment = moment(date);
  return dateMoment.format(format);
}

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) ) {
    return 'iOS';
  }
  else if( userAgent.match( /Android/i ) ) {

    return 'Android';
  }
  else {
    return 'unknown';
  }
}

export const checkRuntimeEnviroment = () => {
  switch (REACT_APP_ENV) {
    case "DEV":
      return RuntimeEnviroment.Development;
    case "QAS":
      return RuntimeEnviroment.Qas;
    case "PRD":
      return RuntimeEnviroment.Production;
    default:
      return null;
  }
};

export const STATUS = {
  COMPLETED: 'QUITADO',
  ON_TIME: 'EM_DIA',
  SUCCESS: 'EM_DIA_PAGO',
  LIGHT_DEBT: 'ATRASADO',
  CRITICAL_DEBT: 'ATRASADO_CRITICO',
  NEGOTIATED: 'NEGOCIACAO'
};

export const isExternalLink = (value) => {
  return value.match(/https?:\/\/[^\s/$.?#].[^\s]*/g);
}

