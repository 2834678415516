import React from "react";
import PropTypes from "prop-types";
import StyledIcon from "./styledIcon";

const Certificate = ({ fill, ...props }) => (
  <StyledIcon viewBox="0 0 24 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6.25C4.31421 6.25 3.75 6.81421 3.75 7.5V17.5C3.75 17.8315 3.8817 18.1495 4.11612 18.3839C4.35054 18.6183 4.66848 18.75 5 18.75H10H10.75V20.25H10H5C4.27065 20.25 3.57118 19.9603 3.05546 19.4445C2.53973 18.9288 2.25 18.2293 2.25 17.5V7.5C2.25 5.98579 3.48579 4.75 5 4.75H19C19.7293 4.75 20.4288 5.03973 20.9445 5.55546C21.4603 6.07118 21.75 6.77065 21.75 7.5V17.5V17.5008C21.7495 17.983 21.6222 18.4567 21.3809 18.8742C21.1395 19.2917 20.7926 19.6384 20.375 19.8795L19.7255 20.2545L18.9755 18.9555L19.625 18.5805C19.8148 18.4709 19.9725 18.3133 20.0822 18.1235C20.1919 17.9337 20.2498 17.7184 20.25 17.4992V7.5C20.25 7.16848 20.1183 6.85054 19.8839 6.61612C19.6495 6.3817 19.3315 6.25 19 6.25H5ZM12.75 15.5C12.75 14.2574 13.7574 13.25 15 13.25C16.2426 13.25 17.25 14.2574 17.25 15.5C17.25 16.2068 16.9241 16.8375 16.4143 17.25H16.25V17.3711C15.8925 17.6104 15.4625 17.75 15 17.75C14.5375 17.75 14.1075 17.6104 13.75 17.3711V17.25H13.5857C13.0759 16.8375 12.75 16.2068 12.75 15.5ZM12.25 18.0495C11.6294 17.3805 11.25 16.4845 11.25 15.5C11.25 13.4289 12.9289 11.75 15 11.75C17.0711 11.75 18.75 13.4289 18.75 15.5C18.75 16.4845 18.3706 17.3805 17.75 18.0495V22.5C17.75 22.7841 17.5895 23.0438 17.3354 23.1708C17.0813 23.2979 16.7773 23.2704 16.55 23.1L15 21.9375L13.45 23.1C13.2227 23.2704 12.9187 23.2979 12.6646 23.1708C12.4105 23.0438 12.25 22.7841 12.25 22.5V18.0495ZM13.75 19.0366V21L14.55 20.4C14.8167 20.2 15.1833 20.2 15.45 20.4L16.25 21V19.0366C15.859 19.1748 15.4383 19.25 15 19.25C14.5617 19.25 14.141 19.1748 13.75 19.0366ZM6 8.75H5.25V10.25H6H18H18.75V8.75H18H6ZM5.25 11.75H6H9H9.75V13.25H9H6H5.25V11.75ZM6 14.75H5.25V16.25H6H8H8.75V14.75H8H6Z"
      fill={fill}
    />
  </StyledIcon>
);

Certificate.propTypes = {
  fill: PropTypes.string
};

Certificate.defaultProps = {
  fill: "black"
};

export default Certificate;
