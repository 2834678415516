import styled, { css } from "styled-components";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { Button as ButtonBase } from "components";
import { getThemePropertyValue, getAssets } from "utils/pluginHelpers";
import { NewErrorSvg } from "assets/svg";
import { media } from "utils/styles/breakpoints";

export const Container = styled.div`
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto 0px auto;
  padding: 48px 24px;
  max-width: 504px;
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
`;

const imgType = styled.img``;
imgType.defaultProps = {
  name: "error",
  src: NewErrorSvg,
  alt: "Imagem de erro"
};

const Logo = getAssets?.IconErrorComponent || imgType;

export const Illustration = styled(Logo)`
  position: relative;
  width: ${getAssets?.IconErrorComponent ? "64px" : "143px"}
  height: ${getAssets?.IconErrorComponent ? "64px" : "117px"}
  margin-bottom: ${tokens.spacingStackLarge};
  ${css`
    ${getThemePropertyValue("errorPage.style", false)}
  `}
`;

export const StatusCode = styled.p`
  font-family: ${tokens.fontFamily01};
  font-weight: ${tokens.fontWeightRegular};
  font-size: ${tokens.fontSizeXxxSmall};
  line-height: ${tokens.lineHeightMedium};
  color: ${tokens.colorNeutralMedium};
  margin-bottom: ${tokens.spacingStackXSmall};
  ${media.forDesktopUp} {
    margin-bottom: ${tokens.spacingStackSmall};
  }
  margin-top: 0px;
`;

export const Title = styled.h1`
  font-family: ${tokens.fontFamily01};
  font-weight: ${tokens.fontWeightSemibold};
  font-size: ${tokens.fontSizeSmall};
  line-height: ${tokens.lineHeightMedium};
  color: ${tokens.colorNeutralXxdark};
  margin-bottom: ${tokens.spacingStackXxSmall};
  ${media.forDesktopUp} {
    margin-bottom: ${tokens.spacingStackXSmall};
  }
  margin-top: 0px;
`;
export const Subtitle = styled.h1`
  font-family: ${tokens.fontFamily01};
  font-weight: ${tokens.fontWeightSemibold};
  font-size: ${tokens.fontSizeSmall};
  line-height: ${tokens.lineHeightMedium};
  color: ${tokens.colorNeutralXxdark};
  margin-bottom: ${tokens.spacingStackXSmall};
  ${media.forDesktopUp} {
    margin-bottom: ${tokens.spacingStackSmall};
  }
  margin-top: 0;
`;

export const Description = styled.p`
  font-family: ${tokens.fontFamily01};
  font-weight: ${tokens.fontWeightRegular};
  font-size: ${tokens.fontSizeXxSmall};
  line-height: ${tokens.lineHeightMedium};
  color: ${tokens.colorNeutralXdark};
  max-width: 312px;
  margin: 8px auto 0px auto;
`;

export const Button = styled(ButtonBase)`
  width: 312px;
  margin-bottom: ${tokens.spacingStackSmall};
  margin-top: ${tokens.spacingStackXLarge};
`;
