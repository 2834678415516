import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import * as Styled from "./styles";

const shouldScrollToRight = (scrollRight, activeRight) =>
  activeRight > scrollRight;

const shouldScrollToLeft = (scrollLeft, activeLeft) => activeLeft < scrollLeft;

const Tabs = ({ value, onChange, children: childrenProp }) => {
  const tabsRef = React.useRef(null);
  const containerRef = React.useRef(null);

  const [isMounted, setIsMounted] = React.useState(false);
  const [borderLeft, setIndicatorPosition] = React.useState("0px");
  const [borderWidth, setIndicatorWidth] = React.useState("0px");
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  const scrollIndicator = activeElement => {
    setIndicatorPosition(`${activeElement.offsetLeft}px`);
    setIndicatorWidth(`${activeElement.clientWidth}px`);
  };

  const children = React.Children.map(childrenProp, child => {
    const newOnChange = event => {
      onChange(event, child.props.index);
    };

    return React.cloneElement(child, {
      active: value === child.props.index,
      onClick: newOnChange
    });
  });

  const scrollElementIntoView = useCallback(activeTab => {
    const scrollToRight = (activeBoundingData, scrollBoundingData) => {
      const offsetValue = scrollBoundingData.right - activeBoundingData.right;
      containerRef.current.scrollLeft -= offsetValue;
    };

    const scrollToLeft = (activeBoundingData, scrollBoundingData) => {
      const offsetValue = activeBoundingData.left - scrollBoundingData.left;
      containerRef.current.scrollLeft += offsetValue;
    };

    const scrollBoundingData = containerRef.current.getBoundingClientRect();
    const activeBoundingData = activeTab.getBoundingClientRect();

    if (
      shouldScrollToRight(scrollBoundingData.right, activeBoundingData.right)
    ) {
      scrollToRight(activeBoundingData, scrollBoundingData);
    } else if (
      shouldScrollToLeft(scrollBoundingData.left, activeBoundingData.left)
    ) {
      scrollToLeft(activeBoundingData, scrollBoundingData);
    }
  }, []);

  useEffect(() => {
    const activeElement = tabsRef.current.children[value];
    scrollIndicator(activeElement);
    scrollElementIntoView(activeElement);
  }, [scrollElementIntoView, value]);

  useEffect(() => {
    const handleResize = () => {
      const activeElement = tabsRef.current.children[value];
      setInnerWidth(window.innerWidth);
      scrollIndicator(activeElement);
      scrollElementIntoView(activeElement);
    };
    window.addEventListener("resize", debounce(handleResize, 250));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollElementIntoView, value]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.TabsContainer
        ref={tabsRef}
        role="tablist"
        innerWidth={innerWidth}
      >
        {children}
      </Styled.TabsContainer>
      {isMounted && (
        <Styled.Indicator style={{ left: borderLeft, width: borderWidth }} />
      )}
    </Styled.Container>
  );
};
Tabs.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default Tabs;
