import styled, { keyframes } from "styled-components";

const dash = keyframes`
  0% {
    stroke-dashoffset: 1000;
    opacity: 1;
  }
  45% {
    stroke-dashoffset: 500;
    opacity: 1;
  }
  45% {
    stroke-dashoffset: 250;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
`;

export const LoadingPath = styled.path.attrs(() => ({
  d:
    "M163,338.55102 L197,338.55102 C198.656854,338.55102 200,337.207875 200,335.55102 L200,322.865674 C200,322.08666 199.696974,321.338194 199.15502,320.778597 L182.054865,303.12176 C180.914861,301.944644 179.041242,301.900059 177.846538,303.021618 L160.946692,318.886779 C160.342641,319.453847 160,320.245479 160,321.073998 L160,335.55102 C160,337.207875 161.343146,338.55102 163,338.55102 Z",
  id: "Path",
  fill: "none",
  stroke: "#09A467",
  strokeDashoffset: "1000",
  strokeDasharray: "1000"
}))`
  animation-name: ${dash};
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  opacity: 0;
`;

export const LoadingIcon = styled.div`
  path {
    animation-delay: (3/3) s;
  }
  display: absolute;
  width: 42px;
`;
