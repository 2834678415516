/* eslint-disable no-unused-vars */
import { axiosInstance } from "../servicesSetupMock";

export const getUnidade = async () => {
  const { unidades } = await axiosInstance();
  return { data: { ...unidades }, status: 200 };
};

export const getGarageContracts = async () => {
  const { vagaDeGaragem } = await axiosInstance();
  return { data: { ...vagaDeGaragem }, status: 200 };
};

export const getPercents = async contract => {
  const { percentual } = await axiosInstance();
  return { data: { ...percentual }, status: 200 };
};

export const getNarrativa = async blocoId => {
  const { percentualGeral } = await axiosInstance();
  return { data: { ...percentualGeral }, status: 200 };
};

export const getMilestones = async contract => {
  const { marco } = await axiosInstance();
  return { data: { ...marco }, status: 200 };
};
