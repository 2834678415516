module.exports = {
  app: {
    title: "Plataforma MRV",
    titleTemplate: "MRV | %s",
    meta: [
      {
        name: "msapplication-TileImage",
        content: `${process.env.PUBLIC_URL}/favicons/ms-icon-144x144.png`
      },
      {
        name: "description",
        content:
          "Mude de vida com a construtora MRV. Casas e apartamentos de qualidade em mais de 160 cidades no país e em excelentes condições de financiamento!"
      }
    ],
    link: [
      {
        rel: "shortcut icon",
        href: `${process.env.PUBLIC_URL}/favicons/favicon.ico`
      },
      {
        rel: "apple-touch-icon",
        sizes: "57x57",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-57x57.png`
      },
      {
        rel: "apple-touch-icon",
        sizes: "60x60",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-60x60.png`
      },
      {
        rel: "apple-touch-icon",
        sizes: "72x72",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-72x72.png`
      },
      {
        rel: "apple-touch-icon",
        sizes: "76x76",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-76x76.png`
      },
      {
        rel: "apple-touch-icon",
        sizes: "114x114",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-114x114.png`
      },
      {
        rel: "apple-touch-icon",
        sizes: "120x120",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-120x120.png`
      },
      {
        rel: "apple-touch-icon",
        sizes: "144x144",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-144x144.png`
      },
      {
        rel: "apple-touch-icon",
        sizes: "152x152",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-152x152.png`
      },
      {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon-180x180.png`
      },
      {
        rel: "apple-touch-icon",
        href: `${process.env.PUBLIC_URL}/favicons/apple-touch-icon.png`
      }
    ]
  }
};
