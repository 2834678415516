import styled from "styled-components/macro";
import {
  CircledX as CircledXIcon,
  CircledCheck as CircledCheckIcon
} from "assets/svg";
import colors from "@company-mrv/mrv-design-system/colors";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { border } from "@company-mrv/mrv-design-system/metrics";

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 48px;
  border-radius: ${border.radius};
  border: solid 2px ${colors.greyDetails};
  padding: 10px 24px;
  box-sizing: border-box;
  outline: 0;
  transition: border 0.2s ease;
  color: ${tokens.colorNeutralXxdark};
  font-size: inherit;

  ${props =>
    !props.success &&
    !props.error &&
    `
      &:focus {
        border-color: ${colors.greyRegular};

        ~ svg {
          color: ${colors.greyRegular};
        }
      }
    `}

  &::-ms-clear {
    display: none;
  }

  ${props =>
    props.error &&
    `
      border-color: ${colors.coralDark};
      padding-right: 38px;
    `}

  ${props =>
    props.success &&
    `
      border-color: ${colors.greenRegular};
      padding-right: 38px;
    `}

  &.input-error {
    border-color: ${colors.coralDark};
  }

  &::placeholder {
    font-size: inherit;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: ${colors.greyLight};
  }

  &[type="text"] {
    font-size: inherit;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: ${tokens.colorNeutralXxdark};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CircledX = styled(CircledXIcon)`
  position: absolute;
  right: 16px;
  top: 25%;
  color: ${colors.coralDark};
`;

export const CircledCheck = styled(CircledCheckIcon)`
  position: absolute;
  right: 16px;
  top: 25%;
  color: ${colors.coralDark};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 1.67;
  margin-top: 4px;
  color: ${colors.coralDark};
`;
