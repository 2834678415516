import React, { useState } from "react";
import PropTypes from "prop-types";

const ContractContext = React.createContext({
  loading: false,
  setLoading: () => {},
  contracts: [],
  setContracts: () => {},
  selectedContract: "",
  setSelectedContract: () => {}
});

export const ContractContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState({
    nomeEmpreendimento: "",
    bloco: "",
    nomeCompletoUnidade: ""
  });

  const value = React.useMemo(
    () => ({
      loading,
      setLoading,
      contracts,
      setContracts,
      selectedContract,
      setSelectedContract
    }),
    [contracts, loading, selectedContract]
  );

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};

ContractContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ContractContext;
