import { ENDPOINT_DOCUMENTS, ENDPOINT_DOCUMENTS_TYPES, ENDPOINT_DOCUMENTS_BALANCETES_DISPONIVEIS, ENDPOINT_DOCUMENTS_GET_BALANCETE, API_URL } from "utils/constants";
import { axiosInstance } from "../servicesSetup";

export const getDocumentsType = async () => {
  const instance = await axiosInstance();
  const { data } = await instance.get(`${ENDPOINT_DOCUMENTS_TYPES}`);

  return data;
};

export const getDocuments = async ({
  empreendimentoId,
  unidadeId,
  documentTypeId
}) => {

  const instance = await axiosInstance();
  const { data } = await instance.get(
    `${ENDPOINT_DOCUMENTS}?empreendimentoId=${empreendimentoId}&unidadeId=${unidadeId}&tipoDocumento=${documentTypeId}`
  );

  return data;
};

export const searchBalancetes = async (
  empreendimentoId
) => {
  console.log(`${ENDPOINT_DOCUMENTS_BALANCETES_DISPONIVEIS}?idEmpreendimento=${empreendimentoId}`);
  const instance = await axiosInstance();
  const { data } = await instance.get(
    `${ENDPOINT_DOCUMENTS_BALANCETES_DISPONIVEIS}?idEmpreendimento=${empreendimentoId}`
  );

  return data;
};

export const getBalancete = async ({
  codDiv,
  trimestre,
  year
}) => {
  const url = `${ENDPOINT_DOCUMENTS_GET_BALANCETE}?codDiv=${codDiv}&trimestre=${trimestre}&ano=${year}`;
  const instance = await axiosInstance();
  const { data } = await instance.get(url);

  doDownloadBase64(data, `balancete_${trimestre}_${year}.pdf`);
};

const doDownloadBase64 = (arquivoBase64, filename) => {
  const arquivoBytes = Buffer.from(arquivoBase64, 'base64');
  doDownloadBytes(arquivoBytes, filename);
}

const doDownloadBytes = (arquivoBytes, filename) => {
  const blob = new Blob([arquivoBytes], { type: 'application/pdf' });
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  reader.onloadend = () => {
    const base64data = reader.result;
    if (window.ReactNativeWebView) {
      const base64 = base64data.split(',')[1];
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          base64,
          filenamePrefix: filename,
          extension: '.pdf'
        })
      );
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}.pdf`;
      link.click();
    }
  };
}