import React from "react";
import PropTypes from "prop-types";

const InfinityScrollContext = React.createContext({
  scrollListener: () => {},
  unbindScrollListener: () => {},
  setThreshold: () => {},
  start: () => {},
  started: false,
  complete: () => {},
  finish: () => {},
  onInfinity: 0
});

const DEFAULT_THRESHOLD = 15; // percent of scroll height needed to fire onInfinity to push new items on list

export const InfinityScrollContextProvider = ({ children }) => {
  const [started, setStarted] = React.useState(true);
  const [fetchingData, setFetchingData] = React.useState(false);
  const [lastScrollTop, setLastScrollTop] = React.useState(0);
  const [threshold, setThreshold] = React.useState(DEFAULT_THRESHOLD);
  const [onInfinity, setOnInfinity] = React.useState(0);
  const [scrollElement, setScrollElement] = React.useState(null);
  const [mouseWheelListenerAdded, setMouseWheelListenerAdded] = React.useState(
    null
  );

  /**
   * Calculate pixel value of threshold percentage of scroll height needed to fire onInfinity to push new items on list.
   * @param {number} thresholdPercentage
   * @param {number} scrollHeight
   * @returns Pixel value of threshold percentage.
   */
  const calculateThreshold = (thresholdPercentage, scrollHeight) => {
    let currentThreshold = thresholdPercentage;
    const isPercentageValueValid =
      typeof thresholdPercentage === "number" &&
      thresholdPercentage >= 0 &&
      thresholdPercentage <= 100;
    if (isPercentageValueValid) {
      currentThreshold = DEFAULT_THRESHOLD;
    }
    return scrollHeight * (currentThreshold / 100);
  };

  const mouseWheelListener = e => {
    if (e.deltaY === 1) e.preventDefault();
  };

  const scrollListener = () => {
    const targetScroll = document.getElementsByTagName("html")[0];
    if (!scrollElement) {
      setScrollElement(targetScroll);
    }
    if (!mouseWheelListenerAdded) {
      setMouseWheelListenerAdded(true);
      targetScroll.addEventListener("mousewheel", mouseWheelListener);
    }
    const currentScrollTop = targetScroll.scrollTop;
    if (started && !fetchingData) {
      const thresholdValue = calculateThreshold(
        threshold,
        targetScroll.scrollHeight
      );
      const reachedScrollLimit =
        window.innerHeight + targetScroll.scrollTop >=
        targetScroll.scrollHeight - thresholdValue;
      const isScrollDirectionDown = currentScrollTop - lastScrollTop > 0;
      if (reachedScrollLimit && isScrollDirectionDown) {
        setFetchingData(true);
        setOnInfinity(value => value + 1);
      }
    }
    setLastScrollTop(currentScrollTop);
  };

  const start = () => {
    setLastScrollTop(0);
    setStarted(true);
  };

  const complete = () => {
    setFetchingData(false);
  };

  const finish = () => {
    setStarted(false);
    setFetchingData(false);
  };

  const unbindScrollListener = () => {
    scrollElement.removeEventListener("scroll", scrollListener);
    scrollElement.removeEventListener("mousewheel", mouseWheelListener);
    setMouseWheelListenerAdded(false);
  };

  const value = {
    scrollListener,
    unbindScrollListener,
    setThreshold,
    start,
    started,
    complete,
    finish,
    onInfinity
  };

  return (
    <InfinityScrollContext.Provider value={value}>
      {children}
    </InfinityScrollContext.Provider>
  );
};

InfinityScrollContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default InfinityScrollContext;
