import styled from "styled-components/macro";
import { ButtonSecondary } from "@company-mrv/core-components-design-system";
import { getThemePropertyValue } from "utils/pluginHelpers";

export const ButtonSecondaryCustom = styled(ButtonSecondary)`
  > p {
    color: ${getThemePropertyValue("buttonSecondary.color", undefined)};
    background-color: ${getThemePropertyValue(
      "buttonSecondary.backgroundColor",
      undefined
    )} !important;
  }
  background-color: ${getThemePropertyValue(
    "buttonSecondary.backgroundColor",
    undefined
  )};

  &:hover,
  &:focus,
  [data-whatintent="touch"] &:focus,
  [data-whatintent="mouse"] &:hover,
  [data-whatinput="keyboard"] &:focus {
    background-color: ${getThemePropertyValue(
      "buttonSecondary.backgroundColor",
      undefined
    )};
    > p {
      color: ${getThemePropertyValue("buttonSecondary.color", undefined)};
      background-color: ${getThemePropertyValue(
        "buttonSecondary.backgroundColor",
        undefined
      )};
    }
    opacity: 0.8;
  }
`;
