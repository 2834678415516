import React from "react";
import {
  useGtmEventTrigger,
  useGtmLabel,
  useGtmValue
} from "@company-mrv/mrv-design-system/components";
import PropTypes from "prop-types";

const GTMBase = ({
  onClick,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  children,
  ...props
}) => {
  const gtmTrigger = useGtmEventTrigger();
  const label = useGtmLabel();
  const value = useGtmValue();

  const handleClick = () => {
    gtmTrigger({
      label: label || value,
      fallbackLabel: "<Click desconhecido>",
      type: "click"
    });
    onClick();
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={event => onKeyDown(event)}
      onKeyUp={event => onKeyUp(event)}
      onKeyPress={event => onKeyPress(event)}
      onClick={handleClick}
      {...props}
    >
      {children}
    </div>
  );
};

GTMBase.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  children: PropTypes.node.isRequired
};

GTMBase.defaultProps = {
  onClick: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  onKeyPress: () => {}
};

export default GTMBase;
