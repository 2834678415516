import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const Input = React.forwardRef(
  ({ className, error, id, success, ...props }, ref) => (
    <Styled.Container
      className={className}
      id={`${id}-container`}
      data-testid={`${id}-container`}
    >
      <Styled.InputContainer>
        <Styled.Input
          ref={ref}
          error={error}
          success={success}
          id={id}
          {...props}
        />
        {error && !success && (
          <Styled.CircledX
            fill="currentColor"
            id={`${id}-error-icon`}
            data-testid={`${id}-error-icon`}
          />
        )}
        {success && !error && (
          <Styled.CircledCheck
            fill="currentColor"
            id={`${id}-correct-icon`}
            data-testid={`${id}-correct-icon`}
          />
        )}
      </Styled.InputContainer>
      {error && error.length > 0 && (
        <Styled.ErrorMessage
          id={`${id}-error-message`}
          data-testid={`${id}-error-message`}
          className="error"
        >
          {error}
        </Styled.ErrorMessage>
      )}
    </Styled.Container>
  )
);

Input.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  success: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
};

Input.defaultProps = {
  type: "text",
  error: "",
  className: "",
  id: "input",
  success: false
};

export default Input;
