import React, { useContext, useEffect, useState } from "react";
import {
  ModalNegotiation,
  RuntimeEnviroment,
  useGtmEventTriggerGeneric
} from "@company-mrv/mrv-design-system/components";
import { useLocation } from "react-router-dom";
import {
  closeModalByCallMia,
  stopTimeout,
  verifyModal,
  resetNotModal
} from "services/modalCustomNegotiation/modalCustomNegotiation";
import FinanceContext from "contexts/finance";
import {
  REACT_APP_ENV,
  STATUS_FINANCE,
  STATUS_TERM_PENDING
} from "utils/constants";
import pushGTM from "utils/gtm/pushGTM";
import PropTypes from "prop-types";
import ContractContext from "contexts/contract";
import AuthContext from "contexts/auth";
import { isInHiddenList } from "utils/pluginHelpers";
import TermPendingContext from "contexts/finance/termPending";
import { getUserIdByAccessToken } from "utils/functions";

const ModalCustomNegotiation = ({ pageOpened }) => {
  const genericTrigger = useGtmEventTriggerGeneric();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { perfilLoaded, perfil } = useContext(FinanceContext);
  const [isOpenModalFromVerify, setIsOpenModalFromVerify] = useState(false);
  const [isOpenModalFromPerfil, setIsOpenModalFromPerfil] = useState(false);
  const { selectedContract } = useContext(ContractContext);
  const { user } = useContext(AuthContext);
  const { hasTerm, termInformationLoaded, term } = useContext(
    TermPendingContext
  );

  useEffect(() => {
    if (perfilLoaded && perfil) {
      genericTrigger({
        eventName: "jornada_financeira",
        action: "Visualizacao",
        userId: getUserIdByAccessToken(user?.access_token),
        parameters: {
          jornada: `${perfil.porcentagemPaga}%`,
          status: perfil.statusFinanceiro,
          valor_pago: perfil.valorPago,
          valor_restante: perfil.valorRestante,
          valor_em_atraso: perfil.valorAtrasado
        }
      });
    }
  }, [perfilLoaded, perfil]);

  const canShowFromLoadTermInformation = () => {
    if (termInformationLoaded && term) {
      const status = term.statusRenegociacao;
      if (
        !status ||
        (status &&
          (status === STATUS_TERM_PENDING.CONCLUIDO ||
            status === STATUS_TERM_PENDING.CONCLUIDO_FISICO ||
            status === STATUS_TERM_PENDING.CANCELADO ||
            status === STATUS_TERM_PENDING.ERRO_SAP ||
            status === STATUS_TERM_PENDING.CONCLUIDO ||
            status === STATUS_TERM_PENDING.EM_BREVE))
      ) {
        return true;
      }
    }
    return termInformationLoaded && !hasTerm;
  };

  useEffect(() => {
    if (perfilLoaded && termInformationLoaded) {
      if (
        !isInHiddenList("NegotiationSection") &&
        canShowFromLoadTermInformation() &&
        perfilLoaded &&
        perfil &&
        selectedContract &&
        selectedContract.empreendimentoId &&
        user &&
        user.profile &&
        user.profile.preferred_username
      ) {
        if (
          (perfil.statusFinanceiro === STATUS_FINANCE.LIGHT_DEBT ||
            perfil.statusFinanceiro === STATUS_FINANCE.CRITICAL_DEBT) &&
          !perfil.elegivelParaCampanhas
        ) {
          setIsOpenModalFromPerfil(true);
        } else if (
          !(
            perfil.statusFinanceiro === STATUS_FINANCE.LIGHT_DEBT ||
            perfil.statusFinanceiro === STATUS_FINANCE.CRITICAL_DEBT ||
            perfil.statusFinanceiro === STATUS_FINANCE.NEGOTIATED
          )
        ) {
          resetNotModal(
            user.profile.preferred_username,
            selectedContract.empreendimentoId
          );
        }
      }
    }
    return () => {
      stopTimeout();
    };
  }, [perfilLoaded, perfil, selectedContract, user, termInformationLoaded]);

  useEffect(() => {
    if (
      selectedContract &&
      selectedContract.empreendimentoId &&
      user &&
      user.profile &&
      user.profile.preferred_username
    ) {
      verifyModal(
        user.profile.preferred_username,
        selectedContract.empreendimentoId,
        location,
        () => {
          setIsOpenModalFromVerify(true);
        }
      );
    }
  }, [selectedContract, user]);

  useEffect(() => {
    if (isOpenModalFromVerify && isOpenModalFromPerfil) {
      setIsOpen(true);
    }
  }, [isOpenModalFromPerfil, isOpenModalFromVerify]);

  const onCloseMia = () => {
    closeModalByCallMia(
      user.profile.preferred_username,
      selectedContract.empreendimentoId
    );
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      if (pageOpened === "home") {
        pushGTM(
          "Home Inteligente",
          "Renegociação Customizada MIA",
          "Pop-up Falar com a MIA",
          "Visualização"
        );
      } else {
        pushGTM(
          "Acompanhamento de Obra",
          "Renegociação Customizada MIA",
          "Pop-up Falar com a MIA",
          "Visualização"
        );
      }
    }
  }, [isOpen]);

  const checkRuntimeEnviroment = () => {
    switch (REACT_APP_ENV) {
      case "DEV":
        return RuntimeEnviroment.Development;
      case "QAS":
        return RuntimeEnviroment.Qas;
      case "PRD":
        return RuntimeEnviroment.Production;
      default:
        return null;
    }
  };

  return (
    <ModalNegotiation
      onClose={() => {
        if (pageOpened === "home") {
          pushGTM(
            "Home Inteligente",
            "Renegociação Customizada MIA",
            "Sair do pop-up",
            "Clique"
          );
          genericTrigger({
            eventName: "renegociacao",
            action: "Clique",
            userId: getUserIdByAccessToken(user?.access_token),
            parameters: {
              pagina: "Home Inteligente",
              botao: "Saida do modal"
            }
          });
        } else {
          pushGTM(
            "Acompanhamento de Obra",
            "Renegociação Customizada MIA",
            "Sair do Pop-up",
            "Clique"
          );
          genericTrigger({
            eventName: "renegociacao",
            action: "Clique",
            userId: getUserIdByAccessToken(user?.access_token),
            parameters: {
              pagina: "Acompanhamento de Obra",
              botao: "Saida do modal"
            }
          });
        }
        onCloseModal();
      }}
      isOpen={isOpen}
      onClickEvent={event => {
        if (event === ModalNegotiation.EVENT_CLICKS.CALL_MIA) {
          if (pageOpened === "home") {
            pushGTM(
              "Home Inteligente",
              "Renegociação Customizada MIA",
              "Falar com a MIA",
              "Clique"
            );
            genericTrigger({
              eventName: "renegociacao",
              action: "Clique",
              userId: getUserIdByAccessToken(user?.access_token),
              parameters: {
                pagina: "Home Inteligente",
                botao: "Falar com a MIA"
              }
            });
          } else {
            pushGTM(
              "Acompanhamento de Obra",
              "Renegociação Customizada MIA",
              "Falar com a MIA",
              "Clique"
            );
            genericTrigger({
              eventName: "renegociacao",
              action: "Clique",
              userId: getUserIdByAccessToken(user?.access_token),
              parameters: {
                pagina: "Acompanhamento de Obra",
                botao: "Falar com a MIA"
              }
            });
          }
          onCloseMia();
        }
      }}
      runtimeEnvironment={checkRuntimeEnviroment()}
    />
  );
};

ModalCustomNegotiation.propTypes = {
  pageOpened: PropTypes.string
};

ModalCustomNegotiation.defaultProps = {
  pageOpened: null
};

export default ModalCustomNegotiation;
