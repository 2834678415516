import styled from "styled-components/macro";
import { media } from "utils/styles/breakpoints";

export const CurveContainer = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
`;

export const CurveSvgContainer = styled.div`
  width: 100%;
  position: absolute;
  top: ${props => props.curvePositionMobile};
  z-index: 0;

  ${media.forTabletUp} {
    top: ${props => props.curvePositionTablet};
  }
`;

export const ChildContainer = styled.div`
  position: relative;
  height: 100%;
`;
