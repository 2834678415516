// Application routes
import { env } from '../env'

export const ROUTES = {
  LOGIN: "/relacionamento/entrar",
  LOGIN_MOCK: "/relacionamento/entrar-mock",
  SILENT: "/relacionamento/silent",
  HOME: "/",
  TRACK_WORK: "/relacionamento/acompanhe-sua-obra",
  MILESTONES: "/relacionamento/marcos-da-obra",
  FEED: "/relacionamento/fases-da-obra",
  NOTIFICATIONS: "/relacionamento/comunicados",
  RECOMMENDATION: "/relacionamento/indicacao-premiada",
  RECOMMENDATION_EMAIL: "/relacionamento/indicacao-premiada-email",
  LOGOUT: "/relacionamento/sair",
  ERROR: "/relacionamento/erro",
  ERROR_HOMOLOG: "/relacionamento/erro/homolog",
  USE_COUPON: "/relacionamento/utilizar-cupom",
  USE_COUPON_EMAIL: "/relacionamento/utilizar-cupom-email",
  DOCUMENTS: "/relacionamento/documentos",
  DOCUMENTS_FLOOR_PLANS: "/relacionamento/documentos/plantas",
  DOCUMENTS_MANUAIS: "/relacionamento/documentos/manuais",
  DOCUMENTS_MANUAIS_PROPRIETARIO:
    "/relacionamento/documentos/manuais_proprietario",
  DOCUMENTS_MANUAIS_AREAS_COMUNS:
    "/relacionamento/documentos/manuais_areas_comuns",
  DOCUMENTS_BALANCETES: "/relacionamento/documentos/balancetes",
  FINANCIAL: env.REACT_APP_FINANCIAL_FRONT_URL,
  FALE_CONOSCO: "/atendimento/fale-conosco",
  TERM_ONBOARDING: "/relacionamento/term-onboarding",
  TERM_ONBOARDING_FISICO: "/relacionamento/term-onboarding-fisico",
  TERM_ONBOARDING_SUCCESS: "/relacionamento/term-onboarding-success",
  TERM_ONBOARDING_SIGN: "/relacionamento/term-onboarding-sign",
  RECOMMENDATION_NEW_ONBOARDING: "/relacionamento/indicacao/onboarding",
  RECOMMENDATION_NEW_MAIN: "/relacionamento/indicacao/home",
  RECOMMENDATION_NEW_DETAILS: "/relacionamento/indicacao/detalhes"
};

export const APP_URL_HOSTNAME = env.REACT_APP_URL_HOSTNAME;
export const APP_PRODUCTION = env.REACT_APP_PRODUCTION === "true";
export const { REACT_APP_ENV } = env;

// API Base URL
export const API_URL = env.REACT_APP_API_URL;
export const FINANCIAL_API_URL = env.REACT_APP_FINANCIAL_API_URL;
export const ASSISTANCE_API_URL = env.REACT_APP_ASSISTANCE_API_URL;
export const MDC_API_URL = env.REACT_APP_MDC_API_URL;

// API names
export const API_FINANCEIRO_NAME = "financeiro";
export const API_MDC_NAME = "mundodacasa";

// Login urls
export const LOGIN_AUTHORITY_URL = env.REACT_APP_LOGIN_AUTHORITY_URL;
export const LOGIN_REDIRECT_URL = env.REACT_APP_LOGIN_REDIRECT_URL;
export const LOUGOUT_URL = env.REACT_APP_LOUGOUT_URL;
export const SILENT_URL = env.REACT_APP_SILENT_URL;

// Login Identity Credentials
export const LOGIN_CLIENT_ID = env.REACT_APP_LOGIN_CLIENT_ID;
export const LOGIN_AUTHORITY_SCOPE =
  env.REACT_APP_LOGIN_AUTHORITY_SCOPE;

export const TIME_DAYS_RESET = env.REACT_APP_TIME_DAYS_RESET;

// API endpoints
export const ENDPOINT_UNITS = "/unidades";
export const ENDPOINT_GARAGE_CONTRACTS = "/vagas-garagem";
export const ENDPOINT_BLOCK_PERCENTAGE = "/bloco/percentual/";
export const ENDPOINT_MILESTONES = "/marco";
export const ENDPOINT_NEW_MILESTONES = "/macro-marcos";
export const ENDPOINT_REDIRECT = "/redirect";
export const ENDPOINT_FEED = "/feed/";
export const ENDPOINT_COUPON = "/cupom";
export const ENDPOINT_COUPON_EMAIL_INFORMATION = "/indicacoes-email-informacao";
export const ENDPOINT_COUPON_EMAIL = "/cupom-email";
export const ENDPOINT_FEED_SET_POST_AS_SEEN = "/feed/posts/visualizados";
export const ENDPOINT_NOTIFICATIONS = "/comunicados";
export const ENDPOINT_RECOMMANDATIONS = "/indicacoes";
export const ENDPOINT_RECOMMANDATIONS_SHARE = "/indicacoes/compartilhamento";
export const ENDPOINT_RECOMMANDATIONS_LINK = "/indicacoes/link";
export const ENDPOINT_RECOMMANDATIONS_EMAIL = "/indicacoes-email";
export const ENDPOINT_SHARE_RECOMMANDATION = "/indicacoes/link/";
export const ENDPOINT_RECOMMANDATION_TERM_ACCEPT = "/termo/accept";
export const ENDPOINT_RECOMMANDATION_VALUES = "/indicacoes/valores";
export const ENDPOINT_RECOMMANDATION_STATUS_ONBOARDING =
  "/usuario/status-onboarding?type=novidadeIndicacao";
export const ENDPOINT_FEATURED = "/destaques";
export const ENDPOINT_ONBOARDING = "/usuario/status-onboarding";
export const ENDPOINT_DOCUMENTS = "/documentos";
export const ENDPOINT_DOCUMENTS_TYPES = "/tipo-documentos";
export const ENDPOINT_DOCUMENTS_BALANCETES_DISPONIVEIS =
  "/documentos/balancetes-disponiveis";
export const ENDPOINT_DOCUMENTS_GET_BALANCETE = "/documentos/balancete";

// API endpoints timeout (in seconds)
export const ENDPOINT_TIMEOUT_UNITS =
  env.REACT_APP_ENDPOINT_TIMEOUT_UNITS || 30;

// Sharepoint redirect base url
export const SHAREPOINT_REDIRECT_BASE_URL =
  env.REACT_APP_SHAREPOINT_REDIRECT_BASE_URL;

// Routes which can be accessed without credentials
export const PUBLIC_ROUTES = [ROUTES.USE_COUPON, ROUTES.USE_COUPON_EMAIL];

// Social networks
export const INSTAGRAM_URL = "https://www.instagram.com/mrv/";
export const FACEBOOK_URL = "https://www.facebook.com/MRV/";
export const YOUTUBE_URL = "https://www.youtube.com/mrvengenharia";

// FACEBOOK SHARER PREFIX
export const FACEBOOK_SHARER = "https://www.facebook.com/sharer/sharer.php?&u=";

// GTM ID
export const GTM_ID = env.REACT_APP_GTM_ID;

// TRACKING_KEY APP INS
export const TRACKING_KEY = env.REACT_APP_TRACKING_KEY;

// DATE PUB INDICATION OLD
export const DATE_INDICATION_OLD = env.REACT_APP_DATE_INDICATION_OLD;

// MOCK
export const MOCK = env.REACT_APP_MOCK === "true";

// Redirect Financeiro
export const CONTRACT_TYPE = {
  NORMAL: "normal",
  GARAGE: "garage"
};

export const STATUS_TERM_PENDING = {
  EM_BREVE: "EM_BREVE",
  PENDENTE: "PENDENTE",
  EM_PROCESSAMENTO: "EM_PROCESSAMENTO",
  CONCLUIDO: "CONCLUIDO",
  PENDENTE_FISICO: "PENDENTE_FISICO",
  CONCLUIDO_FISICO: "CONCLUIDO_FISICO",
  CANCELADO: "CANCELADO",
  ERRO_SAP: "ERRO_SAP"
};

export const STATUS_VA = {
  // Liberar para agendamento da VA
  VA_AGUARDANDO_AGENDAMENTO: "VA_AGUARDANDO_AGENDAMENTO",
  // Exibir a data do agendamento da VA e questionar se quer reagendar
  VA_AGENDADA: "VA_AGENDADA",

  // Liberar para agendamento da VA
  VA_AGUARDANDO_REAGENDAMENTO_SEM_CONTATO:
    "VA_AGUARDANDO_REAGENDAMENTO_SEM_CONTATO",
  // Liberar para agendamento da VA
  VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_SEM_DISPONIBILIDADE:
    "VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_SEM_DISPONIBILIDADE",
  VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_NAO_COMPARECEU:
    "VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_NAO_COMPARECEU",

  // Informar que a VA já está aprovada e deve aguardar a convocação para entrega de chaves
  VA_APROVADA: "VA_APROVADA",
  /*
    Informar que no momento a VA não está disponível para agendamento
    que o cliente deve aguardar contato para agendamento
  */
  VA_CANCELADA: "VA_CANCELADA",
  /*
    Informar que a unidade encontra-se em reparo e que,
    assim que o reparo for concluído ele será convocado novamente para realizar a nova VA
  */
  // REPROVA DO CLIENTE
  VA_REPROVADA_REPARO_EM_ANDAMENTO: "VA_REPROVADA_REPARO_EM_ANDAMENTO",
  // Liberar para agendamento da VA
  VA_REPROVADA_REPARO_REALIZADO: "VA_REPROVADA_REPARO_REALIZADO",
  // Informar que a VA não está disponível para agenamento.
  VA_NAO_REALIZADA_RECISAO_CONTRATO: "VA_NAO_REALIZADA_RECISAO_CONTRATO",
  // Liberar para agendamento da VA
  VA_NAO_REALIZADA_SEM_CONTATO: "VA_NAO_REALIZADA_SEM_CONTATO",
  VA_ENCERRADA_DUPLICIDADE: "VA_ENCERRADA_DUPLICIDADE",
  VA_ENCERRADA_ERRO_PROCESSO: "VA_ENCERRADA_ERRO_PROCESSO"
};

export const DOCUSIGN_STATUS_TERM = {
  TERM_SIGNED_SUCCESSFULLY: "signing_complete",
  TERM_VIEWING_COMPLETE: "viewing_complete"
};

// Home Utils
// *DM -> DAY MONTH -> String -> "DD/MM"
export const INITIAL_INCOME_TAX_PERIOD_DM =
  env.REACT_APP_INITIAL_INCOME_TAX_PERIOD_DM || null;
export const FINAL_INCOME_TAX_PERIOD_DM =
  env.REACT_APP_FINAL_INCOME_TAX_PERIOD_DM || null;
export const SHOW_INCOME_TAX = env.REACT_APP_SHOW_INCOME_TAX === "true";

const ENV_DEV_TO_QAS = REACT_APP_ENV === "DEV" ? "QAS" : REACT_APP_ENV;

// Coupon Utils
export const COUPON_TERMS_URL = `https://saacompanharrelapi${ENV_DEV_TO_QAS}.blob.core.windows.net/termos/regulamento-indicacao-premiada.pdf`;

export const COUPON_ILLUSTRATION_URL = `https://saacompanharrelapi${ENV_DEV_TO_QAS}.blob.core.windows.net/termos/campanha-indicador.png`;

export const COUPON_INDICATOR_ILLUSTRATION_URL = `https://saacompanharrelapi${ENV_DEV_TO_QAS}.blob.core.windows.net/termos/campanha-indicador.png`;

// Menu redirect links
export const MENU_REDIRECT_LINKS = {
  PROFILE: "cadastro",
  MARIA_ROSA:
    "https://api.whatsapp.com/send?phone=553199009000&text=Ol%C3%A1%2C%20como%20pode%20me%20ajudar%3F",
  CONTACT_FORM: "fale-conosco",
  ASSISTENCE: `${ASSISTANCE_API_URL}/chamados`,
  RECOMMEND: "areaindicacao",
  MRV_PLUS: "https://mrvmais.mrv.com.br/",
  BUILDING_PLANS: "plantas-vistas",
  BALANCE_SHEETS: "balancetes",
  GUIDE: "manualproprietario/Paginas/Default.aspx",
  DOCUMENTS: "documentacao",
  MESSAGES: "mensagens-ao-proprietario",
  OCCURRENCES: "ocorrencias",
  OUVIDORIA: "OuvidoriaISE"
};

export const EMAIL_REGEXP = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;

// Track work cards type
export const CARD_TYPE = {
  FUNDACAO: "fundacao",
  ALVENARIA: "alvenaria",
  ESTRUTURA: "estrutura",
  ACABAMENTO: "acabamento",
  AREA_COMUM: "areaComum"
};

export const CARD_STATUS = {
  COMPLETED: 1,
  NEW: 2,
  NORMAL: 3,
  COMING_SOON: 4
};

export const FEED_ITEM_TYPE = {
  FOTO: "FOTO",
  DISCLAIMER: "DISCLAIMER"
};

export const FEED_STATUS = {
  INITIAL: 0,
  FINISH: -1
};

export const STATUS_FINANCE = {
  COMPLETED: "QUITADO",
  ON_TIME: "EM_DIA",
  SUCCESS: "EM_DIA_PAGO",
  LIGHT_DEBT: "ATRASADO",
  CRITICAL_DEBT: "ATRASADO_CRITICO",
  NEGOTIATED: "NEGOCIACAO"
};

// VA Utils
export const GUIA_URL = env.REACT_APP_GUIA_AGENDAMENTO_YOUTUBE;
export const MIA_AGENDAMENTO = env.REACT_APP_MIA_AGENDAMENTO;
