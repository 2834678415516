import React from "react";
import PropTypes from "prop-types";
import {
  AlertProvider,
  PromptProvider,
  GtmProvider
} from "@company-mrv/mrv-design-system/components";
import { HomeContextProvider } from "contexts/home";
import { TrackWorkContextProvider } from "contexts/trackWork";
import { ContractContextProvider } from "contexts/contract";
import { GeneralContextProvider } from "contexts/general";
import { FinanceContextProvider } from "contexts/finance";
import { TermPendingContextProvider } from "contexts/finance/termPending";
import { AuthContextProvider } from "contexts/auth";
import { InfinityScrollContextProvider } from "contexts/infinityScroll";
import { DocumentsProvider } from "contexts/documents";
import { AnimatedBorderClickedContextProvider } from "components/animatedBorder/animatedBorderClickedContext";
import { useLocation } from "react-router-dom";
import { isRoutePublic } from "utils/functions";
import TagManager from "react-gtm-module";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "services/appInsights";

const MainContexts = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <GtmProvider
        onSendToDataLayer={dataLayerObject => {
          TagManager.dataLayer({
            dataLayer: dataLayerObject,
            dataLayerName: "PageDataLayer"
          });
        }}
        onSendToDataLayerGeneric={dataLayerObject => {
          TagManager.dataLayer({
            dataLayer: dataLayerObject,
            dataLayerName: "PageDataLayer"
          });
        }}
      >
        <AlertProvider>
          <PromptProvider>
            <AuthContextProvider enabled={!isRoutePublic(pathname)}>
              <GeneralContextProvider>
                <ContractContextProvider>
                  <InfinityScrollContextProvider>
                    <AnimatedBorderClickedContextProvider>
                      <TrackWorkContextProvider>
                        <DocumentsProvider>
                          <FinanceContextProvider>
                            <TermPendingContextProvider>
                              <HomeContextProvider>
                                {children}
                              </HomeContextProvider>
                            </TermPendingContextProvider>
                          </FinanceContextProvider>
                        </DocumentsProvider>
                      </TrackWorkContextProvider>
                    </AnimatedBorderClickedContextProvider>
                  </InfinityScrollContextProvider>
                </ContractContextProvider>
              </GeneralContextProvider>
            </AuthContextProvider>
          </PromptProvider>
        </AlertProvider>
      </GtmProvider>
    </AppInsightsContext.Provider>
  );
};

MainContexts.propTypes = {
  children: PropTypes.node.isRequired
};

export default MainContexts;
