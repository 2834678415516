import React from "react";
import PropTypes from "prop-types";

const CircledX = ({ viewBox, className, height, width, fill, id }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox={viewBox}
    id={id}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M14.781 15.813c-.285 0-.542-.116-.729-.302L11 12.459 7.948 15.51a1.031 1.031 0 1 1-1.459-1.459L9.542 11 6.49 7.948A1.031 1.031 0 1 1 7.949 6.49L11 9.543l3.052-3.052a1.031 1.031 0 1 1 1.459 1.459L12.459 11l3.052 3.052a1.031 1.031 0 0 1-.73 1.76M11 2.063a8.938 8.938 0 1 0 0 17.876 8.938 8.938 0 0 0 0-17.875M11 22C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11"
    />
  </svg>
);

CircledX.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  id: PropTypes.string.isRequired
};

CircledX.defaultProps = {
  className: "",
  height: "22",
  width: "22",
  viewBox: "0 0 22 22",
  fill: "#3E4B46"
};

export default CircledX;
