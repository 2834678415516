import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  useAlert,
  redirect as redirectGeneral
} from "@company-mrv/mrv-design-system/components";
import { getRedirectToken } from "services/redirect";
import {
  SHAREPOINT_REDIRECT_BASE_URL,
  ROUTES,
  CONTRACT_TYPE
} from "utils/constants";
import { getThemeName } from "utils/pluginHelpers";
import { stringify } from "query-string";
import { trackException, trackTraceException } from "services/trackInsights";

const GeneralContext = React.createContext({
  generalLoading: false,
  setGeneralLoading: () => {},
  financialLink: ""
});

const redirect = (url, sameTab) => {
  if (sameTab) {
    redirectGeneral(url);
  } else {
    redirectGeneral(url, false);
  }
};

export const GeneralContextProvider = ({ children }) => {
  const [generalLoading, setGeneralLoading] = useState(false);
  const [financialLink, setFinancialLink] = useState("");

  const { showAlert } = useAlert();

  const showErrorAlert = () => {
    showAlert({
      title: "Ops!",
      text: "Algo inesperado ocorreu, tente novamente mais tarde.",
      buttonText: "Ok, entendi!",
      theme: getThemeName()
    });
  };

  const redirectSharepoint = async (
    page,
    selectedContract,
    action,
    windowRef
  ) => {
    try {
      setGeneralLoading(true);
      const redirectToken = await getRedirectToken();
      setGeneralLoading(false);
      if (action) {
        action();
      }
      const path = `${SHAREPOINT_REDIRECT_BASE_URL}&token=${redirectToken.data.data}&pagina=${page}&empreendimentoId=${selectedContract.empreendimentoId}`;
      redirect(path, true, windowRef);
    } catch (error) {
      trackException(error);
      trackTraceException("redirectSharepoint", error);
      if (windowRef) {
        windowRef.close();
      }
      setGeneralLoading(false);
      showErrorAlert();
    }
  };

  const setRedirectFinancialLink = selectedContract => {
    if (!selectedContract) return "";
    const redirectData = {
      contractId: selectedContract.unidadeId || selectedContract.numeroContrato,
      contractType: selectedContract.isGarageContract
        ? CONTRACT_TYPE.GARAGE
        : CONTRACT_TYPE.NORMAL
    };
    const params = stringify(redirectData);
    setFinancialLink(`${ROUTES.FINANCIAL}/?${params}`);
    return financialLink;
  };

  const value = React.useMemo(
    () => ({
      generalLoading,
      setGeneralLoading,
      financialLink
    }),
    [generalLoading, financialLink]
  );

  const functions = {
    showErrorAlert,
    redirect,
    redirectSharepoint,
    setRedirectFinancialLink
  };

  return (
    <GeneralContext.Provider value={{ ...value, ...functions }}>
      {children}
    </GeneralContext.Provider>
  );
};

GeneralContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default GeneralContext;
