import styled from "styled-components/macro";
import colors from "@company-mrv/mrv-design-system/colors";
import tokens from "@company-mrv/mrv-design-system/tokens";
import typography from "@company-mrv/mrv-design-system/typography";

import {
  Check as CheckIcon,
  GrayCircle as GrayCircleIcon,
  RedX as RedXIcon
} from "assets/svg";

export const Check = styled(CheckIcon)`
  border-radius: ${tokens.borderRadiusCircle};
  background-color: ${colors.greenRegular};
  color: ${colors.greenRegular};
`;

export const GrayCircle = styled(GrayCircleIcon)`
  border-radius: ${tokens.borderRadiusCircle};
  background-color: ${colors.greyRegular};
  color: ${colors.greyRegular};
`;

export const RedX = styled(RedXIcon)`
  border-radius: ${tokens.borderRadiusCircle};
  background-color: ${colors.coralDark};
  color: ${colors.coralDark};
`;

export const Container = styled.div`
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  border-radius: ${tokens.borderRadiusCircle};
`;

export const TextError = styled.p`
  color: ${props =>
    (props.active === null && colors.greyRegular) ||
    (props.active === true && colors.greenRegular) ||
    (props.active === false && colors.coralDark)};
  ${typography.mini.styles};
  margin-left: ${props => (props.noMargin ? "0" : "10px")};
`;

export default {
  Container,
  Check,
  GrayCircle,
  RedXIcon
};
