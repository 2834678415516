import React from "react";
import PropTypes from "prop-types";

const GrayCircle = ({ viewBox, className, height, width, fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    width={width}
    height={height}
    className={className}
    viewBox={viewBox}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill="#D8DDDB"
    />
  </svg>
);

GrayCircle.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  id: PropTypes.string.isRequired
};

GrayCircle.defaultProps = {
  className: "",
  height: "10",
  width: "10",
  viewBox: "0 0 10 10",
  fill: "none"
};

export default GrayCircle;
