import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const Tag = ({ color, children }) => (
  <Styled.Tag color={color} data-testid="tag">
    {children}
  </Styled.Tag>
);

Tag.propTypes = {
  color: PropTypes.oneOf(["primary", "orange", "default"]),
  children: PropTypes.node.isRequired
};

Tag.defaultProps = {
  color: "default"
};

export default Tag;
