import React, { useContext } from "react";
import PropTypes from "prop-types";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { Warning as WarningIcon } from "@company-mrv/mrv-design-system/icons";
import { MediaContext } from "react-media-query-hoc";

import * as Styled from "./styles";

export const Alert = ({
  icon,
  title,
  text,
  buttonText,
  secondaryButtonText,
  isOpen,
  onRequestClose,
  onSecondaryButtonClick,
  secondaryButtonLoading,
  ...props
}) => {
  const media = useContext(MediaContext);

  return (
    <Styled.Modal isOpen={isOpen} onRequestClose={onRequestClose} {...props}>
      <Styled.IconWraper>{icon}</Styled.IconWraper>

      <Styled.Title>{title}</Styled.Title>
      {text && <Styled.Description>{text}</Styled.Description>}

      <Styled.Button
        onClick={onRequestClose}
        size={media.forMobileOnly ? "small" : "normal"}
      >
        {buttonText}
      </Styled.Button>
      {secondaryButtonText && (
        <Styled.Button
          onClick={onSecondaryButtonClick}
          size={media.forMobileOnly ? "small" : "normal"}
          variant="underline"
          loading={secondaryButtonLoading}
        >
          {secondaryButtonText}
        </Styled.Button>
      )}
    </Styled.Modal>
  );
};

Alert.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.func,
  secondaryButtonLoading: PropTypes.bool
};

Alert.defaultProps = {
  icon: (
    <WarningIcon a11yTitle="" color={tokens.colorSupportAlert} size="40px" />
  ),
  secondaryButtonText: "",
  onSecondaryButtonClick: () => {},
  secondaryButtonLoading: false
};

export default Alert;
