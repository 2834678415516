import styled from "styled-components/macro";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { getThemePropertyValue } from "utils/pluginHelpers";

export const Container = styled.div`
  position: relative;
  overflow-x: scroll;
  height: auto;
  scrollbar-width: none;
  background-color: ${tokens.colorNeutralXxlight};
  &::-webkit-scrollbar {
    display: none;
  }
  border-bottom: ${getThemePropertyValue(
    "tabs.Container.borderBottom",
    "none"
  )};
  border-bottom-left-radius: 32px;
`;

export const TabsContainer = styled.div`
  width: auto;
  display: flex;
`;

export const Space = styled.div``;

export const Indicator = styled.div`
  background-color: ${getThemePropertyValue(
    "tabs.Indicator.backgroundColor",
    tokens.colorBrandPrimaryPure
  )};
  position: absolute;
  left: 0px;
  height: 2px;
  bottom: 0px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
