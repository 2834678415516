/* eslint-disable */
import { breakpoints as baseBreakpoints } from "@company-mrv/mrv-design-system/metrics";

const breakpointsKeys = {};
Object.keys(baseBreakpoints).forEach(key => {
  breakpointsKeys[key] = key;
});

const horizontalContainerPadding = "87px";

const gridValues = {
  extraSmall: {
    wrapper: 312,
    gutter: 16,
    qtdColumn: 4,
    columnSize: 66
  },
  small: {
    wrapper: 440,
    gutter: 16,
    qtdColumn: 4,
    columnSize: 98
  },
  medium: {
    wrapper: 944,
    gutter: 16,
    qtdColumn: 12,
    columnSize: 64
  },
  large: {
    wrapper: 1032,
    gutter: 24,
    qtdColumn: 12,
    columnSize: 64
  }
};

const pxToNumber = sizePx => {
  return parseFloat(sizePx.split("px")[0]);
};

const gridQueries = {
  extraSmall: `(max-width: ${pxToNumber(baseBreakpoints.small) - 1}px)`,
  small: `(min-width: ${baseBreakpoints.small}) and (max-width: ${pxToNumber(
    baseBreakpoints.medium
  ) - 1}px)`,
  medium: `(min-width: ${baseBreakpoints.medium}) and (max-width: ${pxToNumber(
    baseBreakpoints.large
  ) - 1}px)`,
  large: `(min-width: ${baseBreakpoints.large})`
};

const gridMedia = {
  extraSmall: `@media ${gridQueries.extraSmall}`,
  small: `@media ${gridQueries.small}`,
  medium: `@media ${gridQueries.medium}`,
  large: `@media ${gridQueries.large}`
};

const matchMedias = {};
let currentBreakpoint = "";

const matchMediaHandler = breakpointKey => ({ matches }) => {
  // eslint-disable-next-line no-use-before-define
  if (matches) currentBreakpoint = breakpointKey;
};

window.onload = () => {
  Object.keys(baseBreakpoints).forEach(breakpointKey => {
    matchMedias[breakpointKey] = window.matchMedia
      ? window.matchMedia(gridQueries[breakpointKey])
      : null;
    if (matchMedias[breakpointKey]) {
      const handleMatchMedia = matchMediaHandler(breakpointKey);
      matchMedias[breakpointKey].addListener(handleMatchMedia);
      if (matchMedias[breakpointKey].matches) {
        currentBreakpoint = breakpointKey;
      }
    }
  });
};

const wrapper = breakpoint => {
  if (breakpoint && gridValues[breakpoint]) {
    return gridValues[breakpoint].wrapper;
  }
  if (currentBreakpoint) {
    return gridValues[currentBreakpoint].wrapper;
  }
  return 0;
};

const cols = (qtdCols, breakpoint) => {
  if (!Number.isInteger(qtdCols)) return 0;
  if (breakpoint && gridValues[breakpoint]) {
    return gridValues[breakpoint].columnSize * qtdCols;
  }
  if (currentBreakpoint) {
    return gridValues[currentBreakpoint].columnSize * qtdCols;
  }
  return 0;
};

const gutters = (qtd, breakpoint) => {
  if (!Number.isInteger(qtd)) return 0;
  if (breakpoint && gridValues[breakpoint]) {
    return gridValues[breakpoint].gutter * qtd;
  }
  if (currentBreakpoint) {
    return gridValues[currentBreakpoint].gutter * qtd;
  }
  return 0;
};

const colsWithInternalGutters = (qtdCols, breakpoint) => {
  return (
    cols(qtdCols, breakpoint) +
    (qtdCols > 1 ? gutters(qtdCols - 1, breakpoint) : 0)
  );
};

const colsWithInternalGuttersForStyles = (breakpoint, qtdCols) => {
  return colsWithInternalGutters(qtdCols, breakpoint);
};

const colsWithOutterGutter = (qtdColsGutters, breakpoint) => {
  return cols(qtdColsGutters, breakpoint) + gutters(qtdColsGutters, breakpoint);
};

const colsWithOutterGutterForStyles = (breakpoint, qtdColsGutters) => {
  return colsWithOutterGutter(qtdColsGutters, breakpoint);
};

const generateStyle = (breakpoint, selector, maxWidth, qtdCols, func) => {
  return `${gridMedia[breakpoint]} {
    ${selector || "&"} {
      ${maxWidth ? "max-width" : "width"}: ${func(breakpoint, qtdCols)}px;
    }
  }\n`;
};

/**
 * Return "width" styles wrapped into media queries
 * @param {*} params All params should be contained in an object of type {selector: ".cssSelector", maxWidth: boolean, breakpoints: "a breakpoint string" [or an array of breakpoint strings]}.
 * selector - The internal css selector. If not provided, defaults to "&";
 * maxWidth - If provided and true, the property "max-width" will be used instead of default "width";
 * breakpoints - If not provided, media queries will be generated for all breakpoints. If provided, just the provided breakpoints.
 * will be generated;
 * qtdCols - number of cols the style is constructed with, using a "col" function
 * @param {function} func The size function to be called
 */
const buildStyles = (params, func) => {
  let style = "";
  if (
    params.breakpoints &&
    typeof params.breakpoints === "string" &&
    gridMedia[params.breakpoints]
  ) {
    style += generateStyle(
      params.breakpoints,
      params.selector,
      params.maxWidth,
      params.qtdCols,
      func
    );
  } else if (params.breakpoints && Array.isArray(params.breakpoints)) {
    params.breakpoints.forEach(breakpointKey => {
      if (gridValues[breakpointKey]) {
        style += generateStyle(
          breakpointKey,
          params.selector,
          params.maxWidth,
          params.qtdCols,
          func
        );
      }
    });
  } else {
    Object.keys(baseBreakpoints).forEach(breakpointKey => {
      style += generateStyle(
        breakpointKey,
        params.selector,
        params.maxWidth,
        params.qtdCols,
        func
      );
    });
  }
  return style;
};

const wrapperWidthStyles = (breakpoints, selector) => {
  return buildStyles({ breakpoints, selector, maxWidth: false }, wrapper);
};

const wrapperMaxWidthStyles = (breakpoints, selector) => {
  return buildStyles({ breakpoints, selector, maxWidth: true }, wrapper);
};

const colsWithInternalGuttersWidthStyles = (qtdCols, breakpoints, selector) => {
  return buildStyles(
    { qtdCols, breakpoints, selector, maxWidth: false },
    colsWithInternalGuttersForStyles
  );
};

const colsWithInternalGuttersMaxWidthStyles = (
  qtdCols,
  breakpoints,
  selector
) => {
  return buildStyles(
    { qtdCols, breakpoints, selector, maxWidth: true },
    colsWithInternalGuttersForStyles
  );
};

const colsWithOutterGutterWidthStyles = (qtdCols, breakpoints, selector) => {
  return buildStyles(
    { qtdCols, breakpoints, selector, maxWidth: false },
    colsWithOutterGutterForStyles
  );
};

const colsWithOutterGutterMaxWidthStyles = (qtdCols, breakpoints, selector) => {
  return buildStyles(
    { qtdCols, breakpoints, selector, maxWidth: true },
    colsWithOutterGutterForStyles
  );
};

const wrapperStylesFuncs = (...params) => {
  return {
    width: () => wrapperWidthStyles(...params),
    maxWidth: () => wrapperMaxWidthStyles(...params)
  };
};

const colsWithInternalGuttersStylesFuncs = (...params) => {
  return {
    width: () => colsWithInternalGuttersWidthStyles(...params),
    maxWidth: () => colsWithInternalGuttersMaxWidthStyles(...params)
  };
};

const colsWithOutterGutterStylesFuncs = (...params) => {
  return {
    width: () => colsWithOutterGutterWidthStyles(...params),
    maxWidth: () => colsWithOutterGutterMaxWidthStyles(...params)
  };
};

/**
 * Get a module with size and media queries styles for the wrapper
 * @param {string} breakpoints A breakpoint string or an array of breakpoints, to build media queries styles for the breakpoints provided.
 * If not provided, all breakpoints will be built in styles.
 * @param {string} selector The CSS selector used in styles
 */
const wrapperModule = (...params) => {
  return {
    size: () => wrapper(...params),
    styles: wrapperStylesFuncs(...params)
  };
};

/**
 * Get a module with size and styles for the grid columns with internal gutters
 * @param {number} qtdCols Number of cols desired
 * @param {string} breakpoints A breakpoint string or an array of breakpoints, to build media queries styles for the breakpoints provided.
 * If not provided, all breakpoints will be built in styles.
 * @param {string} selector The CSS selector used in styles
 */
const colsModule = (...params) => {
  return {
    size: () => colsWithInternalGutters(...params),
    styles: colsWithInternalGuttersStylesFuncs(...params)
  };
};

/**
 * Get a module with size and styles for the grid columns with internal gutters and one outter gutter
 * @param {number} qtdCols Number of cols desired
 * @param {string} breakpoints A breakpoint string or an array of breakpoints, to build media queries styles for the breakpoints provided.
 * If not provided, all breakpoints will be built in styles.
 * @param {string} selector The CSS selector used in styles
 */
const colsWithOutterGutterModule = (...params) => {
  return {
    size: () => colsWithOutterGutter(...params),
    styles: colsWithOutterGutterStylesFuncs(...params)
  };
};

/**
 * Returns the current breakpoint string
 */
const breakpoint = () => currentBreakpoint;

export {
  wrapperModule as wrapper,
  colsModule as cols,
  colsWithOutterGutterModule as colsWithOutterGutter,
  breakpoint as currentBreakpoint,
  breakpointsKeys,
  horizontalContainerPadding,
  gridValues,
  gridQueries,
  gridMedia
};
