import React from "react";
import PropTypes from "prop-types";

const CircledCheck = ({ viewBox, className, height, width, fill, id }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    width={width}
    height={height}
    className={className}
    viewBox={viewBox}
    id={id}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8544 8.97944L10.3544 14.4794C10.1678 14.6661 9.90997 14.7812 9.625 14.7812C9.34037 14.7812 9.08222 14.6661 8.89591 14.4794L5.80216 11.3857C5.6155 11.199 5.5 10.9412 5.5 10.6562C5.5 10.0867 5.96166 9.625 6.53125 9.625C6.81622 9.625 7.07403 9.7405 7.26069 9.92716L9.625 12.2915L14.3959 7.52091C14.5822 7.33425 14.8404 7.21875 15.125 7.21875C15.6946 7.21875 16.1562 7.68041 16.1562 8.25C16.1562 8.53497 16.0408 8.79278 15.8544 8.97944ZM11 2.06284C6.06409 2.06284 2.0625 6.06409 2.0625 11C2.0625 15.9359 6.06409 19.9378 11 19.9378C15.9359 19.9378 19.9375 15.9359 19.9375 11C19.9375 6.06409 15.9359 2.06284 11 2.06284ZM11 22.0003C4.92491 22.0003 0 17.0751 0 11C0 4.92491 4.92491 0 11 0C17.0751 0 22 4.92491 22 11C22 17.0751 17.0751 22.0003 11 22.0003Z"
      fill="#079D56"
    />
  </svg>
);

CircledCheck.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  id: PropTypes.string.isRequired
};

CircledCheck.defaultProps = {
  className: "",
  height: "22",
  width: "22",
  viewBox: "0 0 22 22",
  fill: "#079D56"
};

export default CircledCheck;
