import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const Card = ({ children, ...props }) => {
  return <Styled.Container {...props}>{children}</Styled.Container>;
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Card;
