import React from "react";
import colors from "@company-mrv/mrv-design-system/colors";
import tokens from "@company-mrv/mrv-design-system/tokens";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const CurveWrapper = ({
  curveColor,
  backgroundColor,
  curvePositionDesktop,
  curvePositionTablet,
  curvePositionMobile,
  children,
  ...props
}) => (
  <Styled.CurveContainer
    curveColor={curveColor}
    backgroundColor={backgroundColor}
    curvePositionDesktop={curvePositionDesktop}
    curvePositionTablet={curvePositionTablet}
    curvePositionMobile={curvePositionMobile}
    {...props}
  >
    <Styled.ChildContainer>{children}</Styled.ChildContainer>
  </Styled.CurveContainer>
);

CurveWrapper.propTypes = {
  curveColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  curvePositionMobile: PropTypes.string,
  curvePositionTablet: PropTypes.string,
  curvePositionDesktop: PropTypes.string,
  children: PropTypes.node.isRequired
};

CurveWrapper.defaultProps = {
  curveColor: colors.white,
  backgroundColor: tokens.colorBackground01,
  curvePositionMobile: "50%",
  curvePositionTablet: "50%",
  curvePositionDesktop: "50%"
};

export default CurveWrapper;
