import React, { useEffect, useState } from 'react';
import { IllustrationKit, ErrorSvg } from 'assets/svg';
import { useGtmEventTrigger } from '@company-mrv/mrv-design-system/components';
import { Spinner } from 'components';
import * as Styled from './styles';
import PropTypes from "prop-types";

const Index = ({
  open,
  onRequestClose,
  onRefreshPage,
  onTryAgain,
  onRedirect,
  error,
  urlEventDispatch
}) => {
  const gtmTrigger = useGtmEventTrigger();
  const gtmHandle = (labelEvent, type = 'redirect') => {
    gtmTrigger({
      label: labelEvent,
      fallbackLabel: '<Redirect desconhecido>',
      type
    });
  };

  const [activeTryAgain, setActiveTryAgain] = useState(false);

  const close = () => {
    gtmHandle('Close modal');
    onRequestClose();
  };

  useEffect(() => {
    if (open && !!urlEventDispatch) {
      gtmHandle('Open modal');
      setTimeout(() => {
        gtmHandle('Success');
        onRedirect();
        setActiveTryAgain(true);
      }, 1000);
    }
  }, [urlEventDispatch, open]);

  const content = () => {
    if (error) {
      gtmHandle('Modal error');
      return (
        <>
          <Styled.ContainerImage h={117} w={143} pt={66} iv>
            <Styled.IllustrationImage src={ErrorSvg} />
          </Styled.ContainerImage>
          <Styled.Title>
            Ops, parece que algo
            <br /> deu errado!
          </Styled.Title>
          <Styled.Text>
            Já estamos resolvendo isso.
            <br /> Por favor, tente atualizar a página.
          </Styled.Text>
          <Styled.Footer>
            <Styled.ButtonRefresh
              onClick={() => {
                gtmHandle('Refresh Page', 'click');
                onRefreshPage();
              }}
            >
              Atualizar Página
            </Styled.ButtonRefresh>
          </Styled.Footer>
        </>
      );
    }

    return (
      <>
        <Styled.ContainerImage h={141} w={121} pt={42}>
          <Styled.IllustrationImage src={IllustrationKit} />
        </Styled.ContainerImage>
        <Styled.Title>
          Você será redirecionado
          <br /> para o ambiente da DocuSign®
        </Styled.Title>
        <Styled.Text>
          Não se preocupe, isso faz parte
          <br /> do processo :)
        </Styled.Text>

        {urlEventDispatch ? (
          <Styled.Footer>
            Caso isso não aconteça,
            <br />{' '}
            <Styled.Link
              data-testid="link-clique-aqui"
              onClick={() => {
                if (activeTryAgain) {
                  gtmHandle('Try Again', 'click');
                  onTryAgain();
                }
              }}
            >
              Clique aqui
            </Styled.Link>
          </Styled.Footer>
        ) : (
          <Spinner />
        )}
      </>
    );
  };

  return (
    <Styled.Modal
      isOpen={open}
      onRequestClose={close}
    >
      <Styled.Container>
        <Styled.Content>{content()}</Styled.Content>
      </Styled.Container>
    </Styled.Modal>
  );
};

Index.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  onTryAgain: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  urlEventDispatch: PropTypes.func.isRequired,
};

export default Index;
