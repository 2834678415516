import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InfinityScrollContext from "contexts/infinityScroll";
import * as Styled from "./styles";

const AppContent = ({ children }) => {
  const { scrollListener } = React.useContext(InfinityScrollContext);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      scrollListener();
    });
  }, [scrollListener]);

  return (
    <Styled.Container id="app-content">
      <Styled.Content>{children}</Styled.Content>
    </Styled.Container>
  );
};

AppContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppContent;
