import React from "react";
import PropTypes from "prop-types";
import StyledIcon from "./styledIcon";

const Skyscraper = ({ fill, ...props }) => (
  <StyledIcon viewBox="0 0 24 24" {...props}>
    <rect width="24" height="24" fill="white" fillOpacity="0.01" />
    <path d="M9.75 8.25V9.76H8.25V8.25H9.75Z" fill="black" />
    <path d="M9.75 11.25H8.25V12.76H9.75V11.25Z" fill="black" />
    <path d="M9.75 14.25V15.76H8.25V14.25H9.75Z" fill="black" />
    <path d="M9.75 17.25H8.25V18.76H9.75V17.25Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3943 2.36201C13.6154 2.49867 13.75 2.74007 13.75 3V6.02292L13.792 5.95994L20.416 10.376C20.6247 10.5151 20.75 10.7492 20.75 11V20.25H21.75V21.75H2.25V20.25H4.25V7C4.25 6.71592 4.4105 6.45622 4.66459 6.32918L12.6646 2.32918C12.8971 2.21293 13.1732 2.22536 13.3943 2.36201ZM19.25 20.25V11.4014L13.75 7.73472V20.25H19.25ZM12.25 4.21353V20.25H5.75V7.46353L12.25 4.21353Z"
      fill={fill}
    />
  </StyledIcon>
);

Skyscraper.propTypes = {
  fill: PropTypes.string
};

Skyscraper.defaultProps = {
  fill: "black"
};

export default Skyscraper;
