import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { H1, Card, Page } from "components";
import { Button } from "@company-mrv/mrv-design-system/components";
import * as Styled from "./styles";

const CardMessage = ({
  title,
  message,
  textButton,
  handleClick,
  variant,
  loading,
  ...props
}) => {
  return (
    <Page {...props}>
      <Styled.Container>
        <Helmet>
          <title>{title} </title>
        </Helmet>
        <Card>
          <Styled.ContainerMessage>
            <Styled.ContainerH1>
              <H1 id="card-message-title" data-testid="card-message-title">
                {title}
              </H1>
            </Styled.ContainerH1>
            <Styled.TextMessage
              id="card-message-text"
              data-testid="card-message-text"
            >
              {message}
            </Styled.TextMessage>
            <Styled.ContainerButton>
              <Button
                id="card-message-button"
                data-testid="card-message-button"
                loading={loading}
                variant={variant}
                disabled={false}
                onClick={handleClick}
              >
                {textButton}
              </Button>
            </Styled.ContainerButton>
          </Styled.ContainerMessage>
        </Card>
      </Styled.Container>
    </Page>
  );
};

CardMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  textButton: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  variant: PropTypes.oneOf(["normal", "ghost", "underline"]),
  loading: PropTypes.bool
};

CardMessage.defaultProps = {
  handleClick: () => {},
  variant: "normal",
  loading: false
};

export default CardMessage;
