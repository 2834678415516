import {
  LOGIN_AUTHORITY_SCOPE,
  STATUS_TERM_PENDING,
  STATUS_VA
} from "utils/constants";

const getAuthFromCPF = cpf => {
  return {
    access_token:
      "eyJhbGciOiJSUzI1NiIsImtpZCI6InJuRFdndU54bm5YMzg2RTBYNng5LUEiLCJ0eXAiOiJhdCtqd3QifQ.eyJuYmYiOjE2OTI3MDg4MzYsImV4cCI6MTY5MjcxMjQzNiwiaXNzIjoiaHR0cHM6Ly9tcnYtaWRlbnRpdHlzZXJ2ZXItYnJhemlsc291dGgtc3RhZ2luZy5tcnYuY29tLmJyIiwiYXVkIjpbImNtZC5jb21lcmNpYWxkaWdpdGFsIiwiaWRlbnRpdHkuc2VydmVyIl0sImNsaWVudF9pZCI6IkIyRTM4QjYxLUJCOTAtNEM4RS1BOEJGLUREM0ZBOEIzNjkxMSIsInN1YiI6IjBmZGVjMWIzLWIzMjAtNDE2NC05MmRlLWFjZWMxNDFjNDUzYiIsImF1dGhfdGltZSI6MTY5MTc3OTQ5OSwiaWRwIjoibG9jYWwiLCJuYW1lIjoiQ2FybHRvbiBXZWhuZXIiLCJtcnYiOiJkZWZhdWx0X3VzZXIiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiIyNzkuNDg0LjY4OC00NiIsInNjb3BlIjpbIm9wZW5pZCIsInByb2ZpbGUiLCJjbWQuZmluYW5jZWlybyIsImNtZC5yZXNvbHZlci5hZ2VuZGFtZW50byIsImNtZC5yZXNvbHZlci5jaGFtYWRvIiwiY21kLnJlc29sdmVyLmRpYWdub3N0aWNvIiwiY21kLnJlc29sdmVyLmRpcmVjaW9uYW1lbnRvIiwiY21kLnJlc29sdmVyLnVuaWRhZGVzIiwiY21kLnZlbmRhZGVhcm1hcmlvIiwiY21kLnJlbGFjaW9uYW1lbnRvIiwiY21kLmF0ZW5kaW1lbnRvIiwiY21kLmNvbnZpdmVyIiwiaWRwLmJmZmxvZ2luIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdfQ.0mRZL6SRPbCRxakbvRsw-qmCKpxEo6ph7XU18WQynCuYJAg0_lwdGVDRpoACo985FOc2uimnhH56Hi_Y5bb50rvwP8bF8L9m8ke6aB5R6LtbH6goD0eH6dcs3hXHBIBSrYQ-t8cZnSHDcX83vp_vqBf0qH6MX4KKE2v8xN48RpZKFLfif0MLrMXYzCqZ5SupDXYk3S5Fkg06Sj7iglVZOODJp7pXCNyfJyUFdMAc-CF-oMFrAQO3BimEBYXL8ZofSCO_Y6MBHBG8yayvvPXQMz5k5iIa9IvqtfGz4JjY3xXz7JrHGYN94bF-PYUQpYFDDw_-ycFmT-KxHKDiFYlrJg",
    expires_at: 1607627660,
    id_token: cpf,
    profile: {
      s_hash: "t9xXmL_pBogmWewfLTmOSA",
      sid: "2lx4DQ-Pi7kVB85p2vNGIA",
      sub: "e8e434fe-38c9-428b-a541-8794796fa84f",
      auth_time: 1609187449,
      idp: "local",
      amr: ["pwd"],
      mrv_beta_relacionamento: "1",
      name: "LUIZ FELIPE SILVA TEIXEIRA",
      preferred_username: cpf
    },
    expired: false,
    refresh_token: cpf,
    scope: LOGIN_AUTHORITY_SCOPE,
    session_state: "",
    token_type: "Bearer"
  };
};

const base = {
  auth: getAuthFromCPF("000.000.000-01"),
  vagasDeGaragem: [],
  unidades: {
    success: true,
    data: [
      {
        unidadeId: "c11c8c60-0256-dd11-bc22-001b78b9d994",
        empreendimentoId: "bd9bb51e-32da-dc11-abcf-001b7845b4a6",
        clienteId: "fd018cae-6349-dd11-bc22-001b78b9d994",
        nomeEmpreendimento: "AQUARELA",
        endereco: "----",
        bloco: "BLOCO 1",
        apartamento: "AQUARELA - BLOCO 1 - 2 Q - APTO 402",
        cpfCnpj: "012.984.526-43",
        blocoId: "9a0e2124-3cf0-dc11-a2f9-001b7845b4a6",
        oportunidadeId: "ebf128ea-2158-dd11-bc22-001b78b9d994",
        nomeCompletoUnidade: "BLOCO 1 - AQUARELA - BLOCO 1 - 2 Q - APTO 402",
        contratoSap: "23798afd-87fa9s"
      },
      {
        unidadeId: "35027296-1266-e011-bdcf-001f2909e810",
        empreendimentoId: "d57d524b-0c94-df11-b20e-001f2909e810",
        clienteId: "fd018cae-6349-dd11-bc22-001b78b9d994",
        nomeEmpreendimento: "SPAZIO ECO VITTA",
        endereco: "----",
        bloco: "BLOCO 1",
        apartamento: "SPAZIO ECO VITTA - BLOCO 1 - 2 Q S COB - APTO 1903",
        cpfCnpj: "012.984.526-43",
        blocoId: "9aa57a84-1266-e011-bdcf-001f2909e810",
        oportunidadeId: "c173f039-a72b-e711-8b2a-009c02b0cb18",
        nomeCompletoUnidade:
          "BLOCO 1 - SPAZIO ECO VITTA - BLOCO 1 - 2 Q S COB - APTO 1903",
        contratoSap: "23798afd-87fa9s"
      },
      {
        unidadeId: "60027296-1266-e011-bdcf-001f2909e810",
        empreendimentoId: "d57d524b-0c94-df11-b20e-001f2909e810",
        clienteId: "fd018cae-6349-dd11-bc22-001b78b9d994",
        nomeEmpreendimento: "SPAZIO ECO VITTA",
        endereco: "----",
        bloco: "BLOCO 2",
        apartamento: "SPAZIO ECO VITTA - BLOCO 2 - 2 Q S - APTO 703",
        cpfCnpj: "012.984.526-43",
        blocoId: "48027296-1266-e011-bdcf-001f2909e810",
        oportunidadeId: "8800aa23-014b-e411-b20b-009c02b0cb18",
        nomeCompletoUnidade:
          "BLOCO 2 - SPAZIO ECO VITTA - BLOCO 2 - 2 Q S - APTO 703",
        contratoSap: "23798afd-87fa9s"
      }
    ]
  },
  onboarding: {
    status: 200,
    data: { onboarding: false, indicacao: true, novidadeIndicacao: false }
  },
  destaques: [
    {
      title: "FIQUE POR DENTRO",
      featured: [
        {
          label: "Indicação premiada: ganhe até R$1.000,00",
          button: [
            {
              label: "Quero indicar!",
              action:
                "https://meuapedev.mrv.com.br/relacionamento/indicacao-premiada?contractId="
            }
          ],
          image: [
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/indicacao_desktop@1x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/indicacao_desktop@2x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/indicacao_mobile@1x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/indicacao_mobile@2x.png"
            }
          ]
        },
        {
          label: "Acompanhe seus pagamentos",
          button: [
            {
              label: "Mostrar detalhes",
              action: "https://meuapedev.mrv.com.br/financeiro"
            }
          ],
          image: [
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/financeiro_desktop@1x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/financeiro_desktop@2x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/financeiro_mobile@1x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/financeiro_mobile@2x.png"
            }
          ]
        },
        {
          label: "Confira a evolução do seu mundo!",
          button: [
            {
              label: "Acompanhar a obra",
              action:
                "https://meuapedev.mrv.com.br/relacionamento/acompanhe-sua-obra"
            }
          ],
          image: [
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/obra_desktop@1x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/obra_desktop@2x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/obra_mobile@1x.png"
            },
            {
              imageLink:
                "https://saacompanharrelapidev.blob.core.windows.net/destaques/obra_mobile@2x.png"
            }
          ]
        }
      ]
    }
  ],
  macroMarcos: [
    {
      id: 1,
      status: 3,
      name: "Contrato",
      details: null
    },
    {
      id: 2,
      status: 3,
      name: "Financiamento",
      details: null
    },
    {
      id: 3,
      status: 3,
      name: "Visita",
      details: {
        reason: null,
        isReagendamento: null,
        date: "0001-01-01T00:00:00",
        visitaMeuSensia: true
      }
    },
    {
      id: 4,
      status: 2,
      name  : "Vistoria",
      details: {
        reason: null,
        isReagendamento : false,
        date: null,
        visitaMeuSensia: null
      }
    },
    {
      id: 5,
      status: 1,
      name: "Entrega",
      details: null
    }
  ],
  contagem: { contagem: 2 },
  marco: {
    success: true,
    data: [
      {
        id: 3,
        status: 1,
        name: "Visita",
        details: {
          date: "2024-10-16T09:00:00",
          reason: null,
          isReagendamento: null,
          visitaMeuSensia: true
        }
      },
      {
        id: 4,
        status: 1,
        name: "Vistoria",
        details: {
          reason: STATUS_VA.VA_AGENDADA,
          isReagendamento: false,
          date: null,
          visitaMeuSensia: null
        }
      },
      { id: 5, status: 1, name: "Entrega" }
    ]
  },
  percentual: {
    success: true,
    data: {
      blocoId: "9a0e2124-3cf0-dc11-a2f9-001b7845b4a6",
      lastUpdated: "2019-12-03T15:45:57.9066667",
      fundacao: {
        news: false,
        numeroIlustracao: "5",
        percentual: 100.0,
        completed: true
      },
      estrutura: {
        news: false,
        numeroIlustracao: "5",
        percentual: 100.0,
        completed: true
      },
      alvenaria: {
        news: false,
        numeroIlustracao: "5",
        percentual: 100.0,
        completed: true
      },
      acabamento: {
        news: false,
        numeroIlustracao: "5",
        percentual: 100.0,
        completed: true
      },
      areaComum: {
        news: false,
        numeroIlustracao: "5",
        percentual: 100.0,
        completed: true
      },
      total: { numeroIlustracao: "4", percentual: 100.0, completed: false }
    }
  },
  percentualGeral: {
    success: true,
    data: [
      {
        dataCriacao: "2019-12-03T15:45:57.9066667",
        percentual: 95.0,
        tempoVerbal: "presente",
        texto:
          "Veja onde chegamos! Com 95% estamos finalizando o interior do seu apê. Do lado de fora, os toques finais: iluminação e paisagismo. Os testes para a liberação das suas chaves estão em andamento.",
        numeroIlustracao: "4",
        titulo: "Dezembro 2019",
        intervaloPercentual: "75a99"
      },
      {
        dataCriacao: "0001-01-01T00:00:00",
        percentual: 0.0,
        tempoVerbal: "futuro",
        texto:
          "Falta muito pouco para você conhecer um novo mundo. Estamos quase lá!",
        numeroIlustracao: "6",
        titulo: "Em breve",
        intervaloPercentual: "100"
      }
    ]
  },
  feed: {
    pagina: 1,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a401",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2021-05-07T14:00:20_Teste homologação 1.JPG",
        visualizado: true
      },
      {
        id: "8c2f6799-5644-4eaf-d6d7-08d9118a0a402",
        dataPublicacao: "2017-05-25T17:51:05",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:05_BLOCO 21_1086.jpg",
        visualizado: true
      },
      {
        id: "b1260781-87a4-4611-d6d8-08d9118a0a403",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1068.jpg",
        visualizado: true
      },
      {
        id: "a8859ebd-d3d2-418f-d6d9-08d9118a0a404",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1084.jpg",
        visualizado: true
      },
      {
        id: "9af1cbb1-b777-428d-d6da-08d9118a0a405",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1067.jpg",
        visualizado: true
      },
      {
        id: "acc39e02-4c09-43a6-d6db-08d9118a0a406",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1069.jpg",
        visualizado: true
      }
    ]
  },
  feed2: {
    pagina: 2,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a4011",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://upload.wikimedia.org/wikipedia/commons/0/02/Kenny_Tete.jpg",
        visualizado: true
      },
      {
        id: "8c2f6799-5644-4eaf-d6d7-08d9118a0a4022",
        dataPublicacao: "2017-05-25T17:51:05",
        tipo: "FOTO",
        conteudo:
          "https://i.pinimg.com/originals/5b/bd/3f/5bbd3fa450b6e2436ddd29199ad55281.jpg",
        visualizado: true
      },
      {
        id: "b1260781-87a4-4611-d6d8-08d9118a0a403",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1068.jpg",
        visualizado: true
      },
      {
        id: "a8859ebd-d3d2-418f-d6d9-08d9118a0a4044",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://imba.blogs.ie.edu/files/2015/11/082615_3-1024x683.jpg",
        visualizado: true
      },
      {
        id: "9af1cbb1-b777-428d-d6da-08d9118a0a4055",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1067.jpg",
        visualizado: true
      },
      {
        id: "acc39e02-4c09-43a6-d6db-08d9118a0a4066",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1069.jpg",
        visualizado: true
      }
    ]
  },
  feed3: {
    pagina: 3,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a4016",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://i.pinimg.com/originals/54/ac/b0/54acb0577e158cd8f8eb6da7dc558301.jpg",
        visualizado: true
      },
      {
        id: "8c2f6799-5644-4eaf-d6d7-08d9118a0a4026",
        dataPublicacao: "2017-05-25T17:51:05",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:05_BLOCO 21_1086.jpg",
        visualizado: true
      },
      {
        id: "b1260781-87a4-4611-d6d8-08d9118a0a4036",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1068.jpg",
        visualizado: true
      },
      {
        id: "a8859ebd-d3d2-418f-d6d9-08d9118a0a4046",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1084.jpg",
        visualizado: true
      },
      {
        id: "9af1cbb1-b777-428d-d6da-08d9118a0a4056",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1067.jpg",
        visualizado: true
      },
      {
        id: "acc39e02-4c09-43a6-d6db-08d9118a0a4066",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1069.jpg",
        visualizado: true
      }
    ]
  },
  feed4: {
    pagina: 4,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a4017",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://img.elo7.com.br/product/original/24A8F61/tete-a-tete-em-linho-cru-1-00-trilho-liso.jpg",
        visualizado: true
      },
      {
        id: "8c2f6799-5644-4eaf-d6d7-08d9118a0a4027",
        dataPublicacao: "2017-05-25T17:51:05",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:05_BLOCO 21_1086.jpg",
        visualizado: true
      },
      {
        id: "b1260781-87a4-4611-d6d8-08d9118a0a4037",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1068.jpg",
        visualizado: true
      },
      {
        id: "a8859ebd-d3d2-418f-d6d9-08d9118a0a4047",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1084.jpg",
        visualizado: true
      },
      {
        id: "9af1cbb1-b777-428d-d6da-08d9118a0a4057",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1067.jpg",
        visualizado: true
      },
      {
        id: "acc39e02-4c09-43a6-d6db-08d9118a0a4067",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1069.jpg",
        visualizado: true
      }
    ]
  },
  feed5: {
    pagina: 5,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a4017",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://img.elo7.com.br/product/original/24A8F61/tete-a-tete-em-linho-cru-1-00-trilho-liso.jpg",
        visualizado: true
      },
      {
        id: "8c2f6799-5644-4eaf-d6d7-08d9118a0a4027",
        dataPublicacao: "2017-05-25T17:51:05",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:05_BLOCO 21_1086.jpg",
        visualizado: true
      },
      {
        id: "b1260781-87a4-4611-d6d8-08d9118a0a4037",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1068.jpg",
        visualizado: true
      },
      {
        id: "a8859ebd-d3d2-418f-d6d9-08d9118a0a4047",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1084.jpg",
        visualizado: true
      },
      {
        id: "9af1cbb1-b777-428d-d6da-08d9118a0a4057",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1067.jpg",
        visualizado: true
      },
      {
        id: "acc39e02-4c09-43a6-d6db-08d9118a0a4067",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1069.jpg",
        visualizado: true
      }
    ]
  },
  feed6: {
    pagina: 6,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a4017",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://img.elo7.com.br/product/original/24A8F61/tete-a-tete-em-linho-cru-1-00-trilho-liso.jpg",
        visualizado: true
      },
      {
        id: "8c2f6799-5644-4eaf-d6d7-08d9118a0a4027",
        dataPublicacao: "2017-05-25T17:51:05",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:05_BLOCO 21_1086.jpg",
        visualizado: true
      },
      {
        id: "b1260781-87a4-4611-d6d8-08d9118a0a4037",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1068.jpg",
        visualizado: true
      },
      {
        id: "a8859ebd-d3d2-418f-d6d9-08d9118a0a4047",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1084.jpg",
        visualizado: true
      },
      {
        id: "9af1cbb1-b777-428d-d6da-08d9118a0a4057",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1067.jpg",
        visualizado: true
      },
      {
        id: "acc39e02-4c09-43a6-d6db-08d9118a0a4067",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1069.jpg",
        visualizado: true
      }
    ]
  },
  feed7: {
    pagina: 7,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a4017",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://img.elo7.com.br/product/original/24A8F61/tete-a-tete-em-linho-cru-1-00-trilho-liso.jpg",
        visualizado: true
      },
      {
        id: "8c2f6799-5644-4eaf-d6d7-08d9118a0a4027",
        dataPublicacao: "2017-05-25T17:51:05",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:05_BLOCO 21_1086.jpg",
        visualizado: true
      },
      {
        id: "b1260781-87a4-4611-d6d8-08d9118a0a4037",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1068.jpg",
        visualizado: true
      },
      {
        id: "a8859ebd-d3d2-418f-d6d9-08d9118a0a4047",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1084.jpg",
        visualizado: true
      },
      {
        id: "9af1cbb1-b777-428d-d6da-08d9118a0a4057",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1067.jpg",
        visualizado: true
      },
      {
        id: "acc39e02-4c09-43a6-d6db-08d9118a0a4067",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1069.jpg",
        visualizado: true
      }
    ]
  },
  feed8: {
    pagina: 8,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a4017",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://img.elo7.com.br/product/original/24A8F61/tete-a-tete-em-linho-cru-1-00-trilho-liso.jpg",
        visualizado: true
      },
      {
        id: "8c2f6799-5644-4eaf-d6d7-08d9118a0a4027",
        dataPublicacao: "2017-05-25T17:51:05",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:05_BLOCO 21_1086.jpg",
        visualizado: true
      },
      {
        id: "b1260781-87a4-4611-d6d8-08d9118a0a4037",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1068.jpg",
        visualizado: true
      },
      {
        id: "a8859ebd-d3d2-418f-d6d9-08d9118a0a4047",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 20_1084.jpg",
        visualizado: true
      },
      {
        id: "9af1cbb1-b777-428d-d6da-08d9118a0a4057",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1067.jpg",
        visualizado: true
      },
      {
        id: "acc39e02-4c09-43a6-d6db-08d9118a0a4067",
        dataPublicacao: "2017-05-25T17:51:04",
        tipo: "FOTO",
        conteudo:
          "https://saacompanharrelapiqas.blob.core.windows.net/fotosobra/2017-05-25T17:51:04_BLOCO 21_1069.jpg",
        visualizado: true
      }
    ]
  },
  feed9: {
    pagina: 9,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: [
      {
        id: "af4bde92-6b51-4f20-d6d6-08d9118a0a4017",
        dataPublicacao: "2021-05-07T14:00:20",
        tipo: "FOTO",
        conteudo:
          "https://img.elo7.com.br/product/original/24A8F61/tete-a-tete-em-linho-cru-1-00-trilho-liso.jpg",
        visualizado: true
      }
    ]
  },
  feed10: {
    pagina: 10,
    totalPaginas: 9,
    totalPosts: 49,
    paginacao: 6,
    idade: "Fotografado há 3 anos atrás.",
    posts: []
  },
  indicacoes: {
    success: true,
    data: [
      {
        id: "0001",
        status: 5,
        validade: "2019-06-21T00:00:00",
        indicado: {
          name: "Luan Pereira Lima",
          cpf: null,
          email: "ju@hotmail.com"
        },
        descricao:
          "Sua indicação foi enviada, mas o seu amigo ainda não preencheu o cupom",
        statusTitulo: "Enviado",
        showCard: false,
        tipo: 1,
        dataCriacao: "2019-04-22T00:00:00"
      },
      {
        id: "0002",
        status: 7,
        validade: "2013-05-30T00:00:00-03:00",
        indicado: { name: "TEST", cpf: null, email: "TEST@222TEST.COM" },
        descricao:
          "Sua indicação foi enviada, mas o seu amigo ainda não preencheu o cupom",
        statusTitulo: "Enviado",
        showCard: false,
        tipo: 1,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      },
      {
        id: "0003",
        status: 7,
        validade: "2013-05-30T00:00:00-03:00",
        indicado: { name: "TEST", cpf: null, email: "TEST@TEST.COM" },
        descricao:
          "Sua indicação foi enviada, mas o seu amigo ainda não preencheu o cupom",
        statusTitulo: "Enviado",
        showCard: false,
        tipo: 1,
        dataCriacao: "2019-10-28T14:33:18-03:00"
      },
      {
        id: "0004",
        status: 1,
        validade: "2013-05-30T00:00:00-03:00",
        indicado: {
          name: "TEST",
          cpf: "000.000.000-01",
          email: "534343FDFSDF@TEST.COM"
        },
        descricao:
          "Seu cupom foi utilizado em em 28/10/2020 por um amigo(a) para iniciar a compra de um MRV. Suas chances aumentaram!",
        statusTitulo: "Utilizado",
        showCard: false,
        tipo: 1,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      },
      {
        id: "0005",
        status: 3,
        validade: "2013-05-30T00:00:00-03:00",
        indicado: {
          name: "TEST",
          cpf: "000.000.000-01",
          email: "TEST20@TEST.COM"
        },
        descricao:
          "Infelizmente o(a) amigo(a) indicado não atendeu os pré-requisitos para participar. Você pode compartilhar um novo cupom.",
        statusTitulo: "Inválido",
        showCard: false,
        tipo: 1,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      },
      {
        id: "00055",
        status: 3,
        validade: "2013-05-30T00:00:00-03:00",
        indicado: {
          cpf: "000.000.000-01"
        },
        descricao:
          "Infelizmente o(a) amigo(a) indicado não atendeu os pré-requisitos para participar. Você pode compartilhar um novo cupom.",
        statusTitulo: "Inválido",
        showCard: false,
        tipo: 1,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      },
      {
        id: "ccc8047d-7e94-ea11-9bdd-005056a7283d",
        status: 1,
        validade: "2023-10-28T14:33:18-03:00",
        indicado: {
          name: "ADINILSON",
          cpf: "000.000.000-014",
          email: "test@test.COM"
        },
        descricao:
          "Seu cupom foi utilizado em em 10/6/2020 por um amigo(a) para iniciar a compra de um MRV. Suas chances aumentaram!",
        statusTitulo: "Utilizado",
        showCard: false,
        tipo: 2,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      },
      {
        id: "cee2c2fd-2eab-ea11-a11a-005056a7283d",
        status: 3,
        validade: "2023-10-28T14:33:18-03:00",
        indicado: {
          name: "MARIA",
          cpf: "67000.000.000-01",
          email: "TEST@MRV.COM.BR"
        },
        descricao:
          "Esse cupom perdeu o prazo de validade, infelizmente. Mas você pode compartilhar um novo cupom a qualquer momento.",
        statusTitulo: "Inválido",
        showCard: false,
        tipo: 2,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      },
      {
        id: "cee2c2fd-2eab-ea11-a11a-005056a7283d22",
        status: 3,
        validade: "2023-10-28T14:33:18-03:00",
        indicado: {
          cpf: "67000.000.000-01"
        },
        descricao:
          "Esse cupom perdeu o prazo de validade, infelizmente. Mas você pode compartilhar um novo cupom a qualquer momento.",
        statusTitulo: "Inválido",
        showCard: false,
        tipo: 2,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      },
      {
        id: "38ac7d03-e1d1-ea11-9679-005056a7283d",
        status: 0,
        validade: "2023-10-28T14:33:18-03:00",
        indicado: {
          name: "TESTE 4",
          cpf: "000.000.000-01",
          email: "teste@test.COM.BR"
        },
        descricao:
          "Esse cupom perdeu o prazo de validade, infelizmente. Mas você pode compartilhar um novo cupom a qualquer momento.",
        statusTitulo: "Inválido",
        showCard: false,
        tipo: 2,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      },
      {
        id: "bd0768ac-fc33-eb11-88a4-005056a7283d",
        status: 2,
        validade: "2021-01-30T13:43:15-03:00",
        indicado: { name: null, cpf: null, email: null },
        descricao:
          "Por enquanto, nenhum dos seus amigos utilizou seu cupom para inciar a compra de um MRV.",
        statusTitulo: "Válido",
        showCard: false,
        tipo: 2,
        dataCriacao: "2023-10-28T14:33:18-03:00"
      }
    ]
  },
  indicacoesPost: {
    success: true,
    data: {
      link: "/indicacoes/link/bd0768ac-fc33-eb11-88a4-005056a7283d",
      valor: "500",
      chequeId: "bd0768ac-fc33-eb11-88a4-005056a7283d",
      tipo: "1",
      status: "1",
      validade: "2021-01-30T13:43:15-03:00",
      cpfcnpjIndicador: "012.984.526-43"
    }
  },
  indicacoesLink: {
    success: true,
    data: {
      link: "/indicacoes/link/000-0000-000"
    }
  },
  indicacoesEmail: {
    success: true
  },
  termo: { success: true, data: true },
  valores: {
    success: true,
    data: { valorIndicado: "R$500,00", valorIndicador: "R$2.000,00" }
  },
  comunicadosContagem: { contagem: 0 },
  comunicados: {
    comunicados: [
      {
        id: 40919,
        titulo: "Atualização das fotos",
        dataPublicacao: "2020-08-18T00:00:00",
        lido: true,
        texto:
          "Olá!\r\n\r\nComo você sabe, as fotos do seu residencial são sempre atualizadas aqui na Plataforma MRV.\r\n\r\nCom a situação da pandemia, o nosso fotógrafo está com dificuldade para se deslocar entre as várias regiões do Brasil.\r\n\r\nPor isso, as atualizações podem sofrer algum atraso. Mas não se preocupe, estamos buscando outras formas para que você continue acompanhando de perto a evolução do seu novo mundo.\r\n\r\nVale lembrar que o percentual de construção da sua obra continua sendo atualizado normalmente.\r\n\r\nContamos com a sua compreensão e em caso de dúvidas, você pode entrar em contato conosco pelos canais de atendimento:\r\n\r\n● Whatsapp (31) 9900-9000\r\n● Fale Conosco aqui na Plataforma MRV\r\n● Redes Sociais: facebook.com/mrv.responde ou twitter.com/mrvresponde\r\n\r\nEquipe MRV.",
        imagem: "03B0BBAD-47B9-439C-905F-A1F610EF02B0",
        extensao: "jpg"
      },
      {
        id: 40192,
        titulo: "Indique um amigo e ganhe desconto no saldo devedor ??",
        dataPublicacao: "2020-04-03T00:00:00",
        lido: true,
        texto: ".",
        imagem: "03B0BBAD-47B9-439C-905F-A1F610EF02B0",
        extensao: "jpg"
      },
      {
        id: 40072,
        titulo: "",
        dataPublicacao: "2020-03-19T00:00:00",
        lido: true,
        texto:
          "Considerando a evolução dos casos de coronavírus (COVID-19) em nosso país e as consequências que já estão sendo sentidas pela população, a MRV vem a público informar que não medirá esforços nas ações de prevenção e contenção da doença, no engajamento em campanhas voltadas para o atendimento aos cidadãos, nas iniciativas que visam a conscientização e informação da sociedade em geral, sem, contudo, descuidar do seu propósito social maior de prover a moradia para os brasileiros.\r\n\r\nPara nos adaptarmos a esse cenário, criamos um Comitê de Gestão de Crise formado por executivos que se reúnem diariamente para avaliar as novas diretrizes e as medidas preventivas necessárias. Felizmente, nossa operação, que se utiliza de tecnologias avançadas e crescente mecanização da produção, segue sem grandes intercorrências. Estamos conscientes de nosso papel para com a sociedade brasileira, especialmente: com os nossos 30 mil colaboradores diretos e 100 mil indiretos, suas famílias, com os nossos 1,3 milhão de clientes que vivem em um MRV, com os nossos 26 mil acionistas que acreditam em nosso propósito e que investiram em nós.\r\n\r\nEntendemos que este é um momento de união e consciência coletiva e diversas medidas do Comitê de Gestão de Crise já foram por nós adotadas, dentre as quais destacamos: \r\n \r\nClientes\r\n\r\n- Cuidar dos nossos clientes sempre foi uma prioridade. Para os mais de 1,3 milhão que vivem em um MRV, estamos preparando orientações de convivência segura nos condomínios.\r\n\r\n- Para aq",
        imagem: "03B0BBAD-47B9-439C-905F-A1F610EF02B0",
        extensao: "jpg"
      },
      {
        id: 38861,
        titulo: "Novas informações",
        dataPublicacao: "2020-02-07T00:00:00",
        lido: true,
        texto:
          "Olá!\r\n\r\nCom o compromisso de mantê-lo atualizado, informamos que fizemos a remoção de terra e a limpeza do estacionamento onde houve a movimentação do talude. \r\n\r\nEstamos mantendo o local protegido por lona assim evitando que possa evoluir a movimentação no terreno, sempre que possível estamos retirando uma parte da lona no período sem chuvas para que a secagem do terreno ocorra mais rapidamente.   \r\n\r\nEsclarecemos que o consultor especialista de solo e estrutura já realizou a visita no empreendimento e estamos aguardando o laudo para verificarmos quais serão os próximos passos.\r\n\r\nMais novidades, informaremos por aqui, OK? Mantemos à sua disposição todos os nossos canais de atendimento. \r\n\r\n•\tWhatsapp: (31) 9 9900-9000\r\n•\tFale Conosco pelo Portal de Relacionamento e aplicativo Meu MRV\r\n•\tRedes Sociais: facebook.com/mrv.responde ou twitter.com/mrvresponde\r\n\r\nEquipe MRV\r\n\r\n\r\nEm caso de dúvidas, estou a disposição.",
        imagem: "03B0BBAD-47B9-439C-905F-A1F610EF02B0",
        extensao: "jpg"
      }
    ]
  },
  comunicado: {
    comunicado: {
      id: 40919,
      titulo: "Atualização das fotos",
      dataPublicacao: "2020-08-18T00:00:00",
      lido: true,
      texto:
        "Olá!\r\n\r\nComo você sabe, as fotos do seu residencial são sempre atualizadas aqui na Plataforma MRV.\r\n\r\nCom a situação da pandemia, o nosso fotógrafo está com dificuldade para se deslocar entre as várias regiões do Brasil.\r\n\r\nPor isso, as atualizações podem sofrer algum atraso. Mas não se preocupe, estamos buscando outras formas para que você continue acompanhando de perto a evolução do seu novo mundo.\r\n\r\nVale lembrar que o percentual de construção da sua obra continua sendo atualizado normalmente.\r\n\r\nContamos com a sua compreensão e em caso de dúvidas, você pode entrar em contato conosco pelos canais de atendimento:\r\n\r\n● Whatsapp (31) 9900-9000\r\n● Fale Conosco aqui na Plataforma MRV\r\n● Redes Sociais: facebook.com/mrv.responde ou twitter.com/mrvresponde\r\n\r\nEquipe MRV.",
      imagem:
        "https://mrvappclientestorageqa.blob.core.windows.net/imagens-comunicados/03b0bbad-47b9-439c-905f-a1f610ef02b0.jpg",
      extensao: null
    }
  },
  visualizarComunicado: { data: { lido: true } },
  cupom: {
    success: true,
    data: {
      valido: true,
      mensagem: "",
      valor: 500.0,
      validade: "2021-02-27T13:12:51",
      nome: null,
      email: null,
      telefone: null
    }
  },
  cupomEmail: {
    success: true,
    data: {
      token: "3ac80c32-f749-eb11-88a4-005056a7283d",
      nome: "TESTANDO",
      email: "TESTANDO123@TESTANDO.COM",
      telefone: "(55) 55555-5555"
    }
  },
  utilizeCupom: { data: { sucesso: true, mensagem: "Sucesso" } },
  utilizeCupomEmail: {
    success: true,
    data: { sucesso: true, mensagem: "", codigo: 200 }
  },
  enterprises: [
    {
      id: "0c863b63-3c76-de11-af72-001f2909e83c",
      dataContrato: "03/26/2021 17:36:39",
      numeroContratoSAP: "40053100",
      idEmpreendimento: "bd9bb51e-32da-dc11-abcf-001b7845b4a6",
      unidade: {
        nome: "Aquarela",
        id: "c11c8c60-0256-dd11-bc22-001b78b9d994",
        bloco: "1",
        apartamento: "402",
        quartos: "2"
      },
      idCliente: "fd018cae-6349-dd11-bc22-001b78b9d994"
    },
    {
      id: "44ef662b-332f-e711-b3b8-009c02b0cb58",
      dataContrato: "04/05/2017 03:00:00",
      numeroContratoSAP: "41730601",
      idEmpreendimento: "d57d524b-0c94-df11-b20e-001f2909e810",
      unidade: {
        nome: "Spazio Eco Vitta",
        id: "35027296-1266-e011-bdcf-001f2909e810",
        bloco: "1",
        apartamento: "1903",
        quartos: "2 S COB"
      },
      idCliente: "fd018cae-6349-dd11-bc22-001b78b9d994"
    },
    {
      id: "7ecb30e7-c54b-e411-b03c-80c16e075108",
      dataContrato: "10/05/2014 03:00:00",
      numeroContratoSAP: "40917470",
      idEmpreendimento: "d57d524b-0c94-df11-b20e-001f2909e810",
      unidade: {
        nome: "Spazio Eco Vitta",
        id: "60027296-1266-e011-bdcf-001f2909e810",
        bloco: "2",
        apartamento: "703",
        quartos: "2 S"
      },
      idCliente: "fd018cae-6349-dd11-bc22-001b78b9d994"
    }
  ],
  extract: {},
  documentsType: [
    {
      idTipoDocumento: 1,
      tipo: "contrato",
      subtipo: null,
      nome: "Contrato de Compra e Venda",
      descricao: "Contrato de compra e venda do seu Sensia.",
      container: "contrato",
      nomeMetaDado: "tipoArquivo",
      metaDadoValor:
        "&metadadoNome.equals=idUnidade&metadadoValor.equals={unidadeId}&metadadoNome1.equals={nomeMetaDado}&metadadoValor1.equals={tipoDocumento}"
    },
    {
      idTipoDocumento: 2,
      tipo: "planta",
      subtipo: null,
      nome: "Planta Baixa",
      descricao: "Planta humanizada do seu imóvel",
      container: "empreendimento",
      nomeMetaDado: "tipoAnexo",
      metaDadoValor:
        "&metadadoNome.equals=idUnidade&metadadoValor.equals={empreendimentoId}&metadadoValorArray.equals={unidadeId}&metadadoNome1.equals={nomeMetaDado}&metadadoValor1.equals={tipoDocumento}&metadadoNome2.equals=idEmpreendimento&metadadoValor2.equals={empreendimentoId}"
    },
    {
      idTipoDocumento: 3,
      tipo: "planta",
      subtipo: "croqui",
      nome: "Croqui",
      descricao: "Croqui do seu empreendimento disponibilizados aqui.",
      container: "empreendimento",
      nomeMetaDado: "tipoAnexo",
      metaDadoValor:
        "&metadadoNome1.equals={nomeMetaDado}&metadadoValor1.equals={tipoDocumento}&metadadoNome2.equals=idEmpreendimento&metadadoValor2.equals={empreendimentoId}"
    },
    {
      idTipoDocumento: 4,
      tipo: "memorial",
      subtipo: null,
      nome: "Memorial descritivo",
      descricao:
        "Memorial descritivo do seu apartamento disponibilizados aqui.",
      container: "empreendimento",
      nomeMetaDado: "tipoAnexo",
      metaDadoValor:
        "&metadadoNome1.equals={nomeMetaDado}&metadadoValor1.equals={tipoDocumento}&metadadoNome2.equals=idEmpreendimento&metadadoValor2.equals={empreendimentoId}"
    }
  ],
  documents: [
    {
      nome: "Bl01_0203_36651711059.pdf",
      url:
        // "https://sacomqualidadecreditoqas.blob.core.windows.net/empreendimento/917225be-7f06-43ca-bf76-d0ffdaf60725.pdf?sig=Af2nST9JKDpx4GOp8BHsOKucE4OLBBUUBjQ8yj7Wj6M%3D&se=2021-08-26T13%3A37%3A42Z&sv=2018-11-09&sp=r&sr=b",
        "https://www.dundeecity.gov.uk/sites/default/files/publications/civic_renewal_forms.zip",
      dataAtualizacao: "2021-08-18T18:31:44.25",
      tipoDocumento: "planta"
    }
  ],
  milestoneOpportunities: {
    stage: 5,
    state: 2,
    name: "Entrega de chaves - Liberada",
    description: "Entre no seu apê com o pé direito. Deixe tudo preparado:",
    image: "logoMdc.png",
    url:
      "https://meuapeqas.mrv.com.br/mundodacasa/ffcbec76-e78c-e611-b1e9-009c02b0cb58",
    eventCode: "E000000124",
    eventName: "Conheça o MDC - Chaves liberada",
    cards: [
      {
        order: 4,
        eventCode: "E000000160",
        eventName: "Teste de estilos - Vistoria liberada",
        icon: "Style",
        name: "Teste de estilo",
        content: "Descubra seu Estilo",
        url:
          "https://meuapeqas.mrv.com.br/mundodacasa/539cc410-33a7-e911-ac10-005056856875#testedeestilos",
        redirectSameTabWeb: true,
        redirectSameTabApp: true
      },
      {
        order: 5,
        eventCode: "E000000147",
        eventName: "Energia limpa - Vistoria liberada",
        icon: "Light",
        name: "Energia Limpa 1",
        content: "Economize na conta de luz",
        url: "https://floraenergia.com.br/mrv-cliente/",
        redirectSameTabWeb: false,
        redirectSameTabApp: false
      },
      {
        order: 3,
        eventCode: "E000000142",
        eventName: "Descontos exclusivos - Vistoria liberada",
        icon: "Refrigerator",
        name: "Descontos exclusivos 2",
        content: "Equipe seu apê com desconto",
        url: "https://meuapeqas.mrv.com.br/mundodacasa/descontos-exclusivos",
        redirectSameTabWeb: true,
        redirectSameTabApp: true
      }
    ]
  },
  perfil: {
    abertos: [],
    atrasados: [],
    boletoDigital: null,
    campanha: [],
    campanhaCobranca: {
      campanhaEspecialCovid: false,
      dataProrrogacao: "0001-01-01T00:00:00",
      dataVencimento: "0001-01-01T00:00:00",
      elegivel: false,
      prorrogacao: false
    },
    codigoCampanha: null,
    elegivelParaCampanhas: false,
    historico: [],
    negociacao: [],
    parcelasPagas: 12,
    parcelasTotais: 77,
    pdfQuitacao: "http://www.orimi.com/pdf-test.pdf",
    perfilContrato: null,
    porcentagemPaga: 17,
    statusCampanha: null,
    possuiConfissao: true,
    statusFinanceiro: "EM_DIA",
    ultimaAlteracao: "2021-12-14T18:03:44.0831579+00:00",
    valorAtrasado: 0,
    valorPago: 3887.33,
    valorRestante: 18021.48,
    valoresGerais: {
      valorEntrada: 18482.999999999985,
      valorFinanciamento: 117416.99,
      valorImovel: 135899.99
    },
    futuros: [
      {
        bloqueado: false,
        valor: 1849.86,
        valorAcrescimo: 0.0,
        valorAnterior: null,
        valorDesconto: 0.0,
        valorOriginal: 1499.51,
        valorPago: 0.0,
        valorReajuste: 350.35,
        valorRestante: 1849.86,
        instanciaTotal: 70,
        parcelaReferente: 1,
        nomeHumanizado: {
          apelido: "Anual",
          nome: "A001 Anual",
          categoriaMae: "MRV",
          titulo: "Financiamento MRV&CO",
          slug: "FINANC"
        },
        anoExercicio: "2020",
        codigoEmpresa: "MRVH",
        codigoParcela: "A",
        codigoParcelaCompleto: "A001",
        dataPagamento: null,
        dataVencimento: "2022-01-08T12:00:00-0300",
        descricaoParcela: "A001 INTERMEDIÁRIA",
        documento: "5207241658",
        flag_CampCob: "N",
        id: "5207241658001A001",
        item: "001",
        linhaDigitavel: null,
        nome: "INTERMEDIÁRIA",
        status: "ED",
        unificado: null
      },
      {
        bloqueado: false,
        valor: 227.32,
        valorAcrescimo: 0.0,
        valorAnterior: null,
        valorDesconto: 0.0,
        valorOriginal: 184.27,
        valorPago: 0.0,
        valorReajuste: 43.05,
        valorRestante: 227.32,
        instanciaTotal: 70,
        parcelaReferente: 5,
        nomeHumanizado: {
          apelido: "Mensal",
          nome: "M005 Mensal",
          categoriaMae: "MRV",
          titulo: "Financiamento MRV&CO",
          slug: "FINANC"
        },
        anoExercicio: "2020",
        codigoEmpresa: "MRVH",
        codigoParcela: "M",
        codigoParcelaCompleto: "M005",
        dataPagamento: null,
        dataVencimento: "2022-01-08T12:00:00-0300",
        descricaoParcela: "M005 MENSAL",
        documento: "5207240492",
        flag_CampCob: "N",
        id: "5207240492001M005",
        item: "001",
        linhaDigitavel: null,
        nome: "MENSAL",
        status: "ED",
        unificado: null
      },
      {
        bloqueado: false,
        valor: 227.32,
        valorAcrescimo: 0.0,
        valorAnterior: null,
        valorDesconto: 0.0,
        valorOriginal: 184.27,
        valorPago: 0.0,
        valorReajuste: 43.05,
        valorRestante: 227.32,
        instanciaTotal: 70,
        parcelaReferente: 24,
        nomeHumanizado: {
          apelido: "Mensal",
          nome: "M024 Mensal",
          categoriaMae: "MRV",
          titulo: "Financiamento MRV&CO",
          slug: "FINANC"
        },
        anoExercicio: "2020",
        codigoEmpresa: "MRVH",
        codigoParcela: "M",
        codigoParcelaCompleto: "M024",
        dataPagamento: null,
        dataVencimento: "2022-01-08T12:00:00-0300",
        descricaoParcela: "M024 MENSAL",
        documento: "5207241623",
        flag_CampCob: "N",
        id: "5207241623001M024",
        item: "001",
        linhaDigitavel: null,
        nome: "MENSAL",
        status: "ED",
        unificado: null
      }
    ],
    preview: [
      {
        bloqueado: false,
        valor: 1849.86,
        valorAcrescimo: 0.0,
        valorAnterior: null,
        valorDesconto: 0.0,
        valorOriginal: 1499.51,
        valorPago: 0.0,
        valorReajuste: 350.35,
        valorRestante: 1849.86,
        instanciaTotal: 70,
        parcelaReferente: 1,
        nomeHumanizado: {
          apelido: "Anual",
          nome: "A001 Anual",
          categoriaMae: "MRV",
          titulo: "Financiamento MRV&CO",
          slug: "FINANC"
        },
        anoExercicio: "2020",
        codigoEmpresa: "MRVH",
        codigoParcela: "A",
        codigoParcelaCompleto: "A001",
        dataPagamento: null,
        dataVencimento: "2022-01-08T12:00:00-0300",
        descricaoParcela: "A001 INTERMEDIÁRIA",
        documento: "5207241658",
        flag_CampCob: "N",
        id: "5207241658001A001",
        item: "001",
        linhaDigitavel: null,
        nome: "INTERMEDIÁRIA",
        status: "ED",
        unificado: null
      },
      {
        bloqueado: false,
        valor: 227.32,
        valorAcrescimo: 0.0,
        valorAnterior: null,
        valorDesconto: 0.0,
        valorOriginal: 184.27,
        valorPago: 0.0,
        valorReajuste: 43.05,
        valorRestante: 227.32,
        instanciaTotal: 70,
        parcelaReferente: 5,
        nomeHumanizado: {
          apelido: "Mensal",
          nome: "M005 Mensal",
          categoriaMae: "MRV",
          titulo: "Financiamento MRV&CO",
          slug: "FINANC"
        },
        anoExercicio: "2020",
        codigoEmpresa: "MRVH",
        codigoParcela: "M",
        codigoParcelaCompleto: "M005",
        dataPagamento: null,
        dataVencimento: "2022-01-08T12:00:00-0300",
        descricaoParcela: "M005 MENSAL",
        documento: "5207240492",
        flag_CampCob: "N",
        id: "5207240492001M005",
        item: "001",
        linhaDigitavel: null,
        nome: "MENSAL",
        status: "ED",
        unificado: null
      },
      {
        bloqueado: false,
        valor: 227.32,
        valorAcrescimo: 0.0,
        valorAnterior: null,
        valorDesconto: 0.0,
        valorOriginal: 184.27,
        valorPago: 0.0,
        valorReajuste: 43.05,
        valorRestante: 227.32,
        instanciaTotal: 70,
        parcelaReferente: 24,
        nomeHumanizado: {
          apelido: "Mensal",
          nome: "M024 Mensal",
          categoriaMae: "MRV",
          titulo: "Financiamento MRV&CO",
          slug: "FINANC"
        },
        anoExercicio: "2020",
        codigoEmpresa: "MRVH",
        codigoParcela: "M",
        codigoParcelaCompleto: "M024",
        dataPagamento: null,
        dataVencimento: "2022-01-08T12:00:00-0300",
        descricaoParcela: "M024 MENSAL",
        documento: "5207241623",
        flag_CampCob: "N",
        id: "5207241623001M024",
        item: "001",
        linhaDigitavel: null,
        nome: "MENSAL",
        status: "ED",
        unificado: null
      },
      {
        bloqueado: false,
        valor: 227.32,
        valorAcrescimo: 0.0,
        valorAnterior: null,
        valorDesconto: 0.0,
        valorOriginal: 184.27,
        valorPago: 0.0,
        valorReajuste: 43.05,
        valorRestante: 227.32,
        instanciaTotal: 70,
        parcelaReferente: 6,
        nomeHumanizado: {
          apelido: "Mensal",
          nome: "M006 Mensal",
          categoriaMae: "MRV",
          titulo: "Financiamento MRV&CO",
          slug: "FINANC"
        },
        anoExercicio: "2020",
        codigoEmpresa: "MRVH",
        codigoParcela: "M",
        codigoParcelaCompleto: "M006",
        dataPagamento: null,
        dataVencimento: "2022-02-08T12:00:00-0300",
        descricaoParcela: "M006 MENSAL",
        documento: "5207240548",
        flag_CampCob: "N",
        id: "5207240548001M006",
        item: "001",
        linhaDigitavel: null,
        nome: "MENSAL",
        status: "AD",
        unificado: null
      },
      {
        bloqueado: false,
        valor: 227.32,
        valorAcrescimo: 0.0,
        valorAnterior: null,
        valorDesconto: 0.0,
        valorOriginal: 184.27,
        valorPago: 0.0,
        valorReajuste: 43.05,
        valorRestante: 227.32,
        instanciaTotal: 70,
        parcelaReferente: 25,
        nomeHumanizado: {
          apelido: "Mensal",
          nome: "M025 Mensal",
          categoriaMae: "MRV",
          titulo: "Financiamento MRV&CO",
          slug: "FINANC"
        },
        anoExercicio: "2020",
        codigoEmpresa: "MRVH",
        codigoParcela: "M",
        codigoParcelaCompleto: "M025",
        dataPagamento: null,
        dataVencimento: "2022-02-08T12:00:00-0300",
        descricaoParcela: "M025 MENSAL",
        documento: "5207241704",
        flag_CampCob: "N",
        id: "5207241704001M025",
        item: "001",
        linhaDigitavel: null,
        nome: "MENSAL",
        status: "AD",
        unificado: null
      }
    ]
  },
  empreendimentos: [
    {
      dataContrato: "11/05/2019 06:00:00",
      id: "dcc5caab-a304-ea11-96a0-005056856875",
      idCliente: "030b52f1-2502-ea11-8a6f-009c02b0cb58",
      idEmpreendimento: "bd9bb51e-32da-dc11-abcf-001b7845b4a6",
      numeroContratoSAP: "42281901",
      unidade: {
        apartamento: "404",
        bloco: "11",
        id: "f8208e57-8eb2-e711-b989-80c16e075108",
        nome: "Parque Atlântico Sul",
        quartos: "2"
      }
    }
  ],
  usuario: {
    codigoSAP: "1000769724",
    documento: "087.065.266-40",
    email: "mockEmail8@mockEmail.com",
    id: "030b52f1-2502-ea11-8a6f-009c02b0cb58",
    nome: "DOUGLAS ANDRE DA SILVA RIBEIRO"
  },
  term: {
    dataRealizacao: "2023-02-03T00:00:00.0000000-03:00",
    statusRenegociacao: STATUS_TERM_PENDING.CONCLUIDO,
    statusTermo: "AG_ASS_CLI",
    statusBoleto: "CONCLUIDO",
    tipoOperacao: "DESLIGAMENTO - Confissão de divida",
    tipoNegociacao: "CARTEIRA_TAXA",
    cancelamentoVisualizado: false,
    documentoSAP: {
      empresa: "MRVH",
      documento: "8000320840",
      exercicio: "2023",
      item: "001"
    },
    termo: {
      codRenTermo: "RENE-SAP-000365177",
      validadeDocumento: "2050-09-24",
      idEnvelope: "3050e9fa-397f-4f8c-a1b7-233b81330f60",
      urlAssinatura: null,
      dataAssinaturaTermo: "00000000",
      horaAssinaturaTermo: "000000",
      signatarios: [
        {
          tipoAssinatura: "E",
          clientePrincipal: true,
          nome: "ROSEANE DO SOCORRO PALHETA ALMEIDA",
          email: null,
          telefone: null,
          cpfCnpj: "97215732215",
          assinado: false,
          dataAssinatura: "00000000",
          horaAssinatura: "000000",
          isMrv: false
        },
        {
          tipoAssinatura: "D",
          clientePrincipal: false,
          nome: "Gustavo Freitas Dutra Nicacio",
          email: "gustavof@mrv.com.br",
          telefone: null,
          cpfCnpj: "04021981667",
          assinado: false,
          dataAssinatura: "00000000",
          horaAssinatura: "000000",
          isMrv: true
        },
        {
          tipoAssinatura: "D",
          clientePrincipal: false,
          nome: "Gustavo Rodrigues Cardoso",
          email: "gustavo.cardoso@mrv.com.br",
          telefone: null,
          cpfCnpj: "05193514600",
          assinado: false,
          dataAssinatura: "00000000",
          horaAssinatura: "000000",
          isMrv: true
        },
        {
          tipoAssinatura: "D",
          clientePrincipal: false,
          nome: "Igara Pessoa",
          email: "igara@mrv.com.br",
          telefone: null,
          cpfCnpj: "61404942653",
          assinado: false,
          dataAssinatura: "00000000",
          horaAssinatura: "000000",
          isMrv: true
        }
      ]
    }
  },
  termUrlAssinatura: {
    id_envelope: "asdsdwewgdf434534",
    url_assinatura: "https://google.com.br"
  }
};

export default {
  "000.000.000-01": base,
  "000.000.000-02": {
    ...base,
    perfil: { ...base.perfil, statusFinanceiro: "ATRASADO_CRITICO" }
  },
  "000.000.000-03": {
    ...base,
    perfil: { ...base.perfil, statusFinanceiro: "EM_DIA" }
  },
  "000.000.000-04": {
    ...base,
    perfil: { ...base.perfil, statusFinanceiro: "EM_DIA_PAGO" }
  },
  "000.000.000-05": {
    ...base,
    perfil: { ...base.perfil, statusFinanceiro: "QUITADO" }
  },
  "000.000.000-77": {
    ...base,
    auth: getAuthFromCPF("000.000.000-77"),
    term: {
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.PENDENTE,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "Emaildb22b6a8-ec84-4794-afbf-329f4c893b0e",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: "Emaila32cf127-4b6d-4b10-8aad-5aa8a6e2e18f",
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: "Email09c99270-8423-4bb0-b87e-a16aae2d1c99",
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: "Email846189c7-7f78-460d-8865-1e170cddac36",
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-80": {
    ...base,
    auth: getAuthFromCPF("000.000.000-80"),
    term: {
      dataRealizacao: "2023-01-17T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.EM_PROCESSAMENTO,
      statusTermo: "AG_ASS_CLI",
      statusBoleto: "CONCLUIDO",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "CARTEIRA_TAXA",
      cancelamentoVisualizado: false,
      documentoSAP: {
        empresa: "Q009",
        documento: "8000317717",
        exercicio: "2023",
        item: "001"
      },
      termo: {
        codRenTermo: "RENE-SAP-000361159",
        validadeDocumento: "2050-10-03",
        idEnvelope: "a8d32f5e-96c6-4660-b3fa-37e03e693143",
        urlAssinatura: null,
        dataAssinaturaTermo: "00000000",
        horaAssinaturaTermo: "000000",
        signatarios: [
          {
            tipoAssinatura: "E",
            clientePrincipal: true,
            nome: "GUILHERME OLIVEIRA DA SILVA",
            email: null,
            telefone: null,
            cpfCnpj: "34469856894",
            assinado: true,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: false
          },
          {
            tipoAssinatura: "E",
            clientePrincipal: false,
            nome: "GRACIELE CAROLINE FIDELIS LOURENCO",
            email: null,
            telefone: "(14) 98745-8612",
            cpfCnpj: "55687315861",
            assinado: true,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Gustavo Freitas Dutra Nicacio",
            email: "gustavof@mrv.com.br",
            telefone: null,
            cpfCnpj: "04021981667",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Gustavo Rodrigues Cardoso",
            email: "gustavo.cardoso@mrv.com.br",
            telefone: null,
            cpfCnpj: "05193514600",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-79": {
    ...base,
    auth: getAuthFromCPF("000.000.000-79"),
    term: {
      cancelamentoVisualizado: false,
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.CONCLUIDO,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "luan_kk12@hotmail.com",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: "luan_kk12@hotmail.com",
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: null,
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: null,
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: true,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-81": {
    ...base,
    auth: getAuthFromCPF("000.000.000-81"),
    term: {
      cancelamentoVisualizado: false,
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.CONCLUIDO_FISICO,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "luan_kk12@hotmail.com",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: "luan_kk12@hotmail.com",
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: null,
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: null,
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-82": {
    ...base,
    auth: getAuthFromCPF("000.000.000-82"),
    term: {
      dataRealizacao: "2023-01-17T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.PENDENTE,
      statusTermo: "AG_ASS_CLI",
      statusBoleto: "CONCLUIDO",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "CARTEIRA_TAXA",
      cancelamentoVisualizado: false,
      documentoSAP: {
        empresa: "Q009",
        documento: "8000317717",
        exercicio: "2023",
        item: "001"
      },
      termo: {
        codRenTermo: "RENE-SAP-000361159",
        validadeDocumento: "2050-10-03",
        idEnvelope: "a8d32f5e-96c6-4660-b3fa-37e03e693143",
        urlAssinatura: null,
        dataAssinaturaTermo: "00000000",
        horaAssinaturaTermo: "000000",
        signatarios: [
          {
            tipoAssinatura: "E",
            clientePrincipal: true,
            nome: "GUILHERME OLIVEIRA DA SILVA",
            email: null,
            telefone: null,
            cpfCnpj: "34469856894",
            assinado: true,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Gustavo Freitas Dutra Nicacio",
            email: "gustavof@mrv.com.br",
            telefone: null,
            cpfCnpj: "04021981667",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Gustavo Rodrigues Cardoso",
            email: "gustavo.cardoso@mrv.com.br",
            telefone: null,
            cpfCnpj: "05193514600",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-83": {
    ...base,
    auth: getAuthFromCPF("000.000.000-83"),
    term: {
      dataRealizacao: "2023-01-17T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.PENDENTE_FISICO,
      statusTermo: "AG_ASS_CLI",
      statusBoleto: "CONCLUIDO",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "CARTEIRA_TAXA",
      cancelamentoVisualizado: false,
      documentoSAP: {
        empresa: "Q009",
        documento: "8000317717",
        exercicio: "2023",
        item: "001"
      },
      termo: {
        codRenTermo: "RENE-SAP-000361159",
        validadeDocumento: "2050-10-03",
        idEnvelope: "a8d32f5e-96c6-4660-b3fa-37e03e693143",
        urlAssinatura: null,
        dataAssinaturaTermo: "00000000",
        horaAssinaturaTermo: "000000",
        signatarios: [
          {
            tipoAssinatura: "E",
            clientePrincipal: true,
            nome: "GUILHERME OLIVEIRA DA SILVA",
            email: null,
            telefone: null,
            cpfCnpj: "34469856894",
            assinado: true,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Gustavo Freitas Dutra Nicacio",
            email: "gustavof@mrv.com.br",
            telefone: null,
            cpfCnpj: "04021981667",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Gustavo Rodrigues Cardoso",
            email: "gustavo.cardoso@mrv.com.br",
            telefone: null,
            cpfCnpj: "05193514600",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-84": {
    ...base,
    auth: getAuthFromCPF("000.000.000-84"),
    term: {
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.PENDENTE,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "Emaildb22b6a8-ec84-4794-afbf-329f4c893b0e@gmail.com",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: null,
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: null,
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: null,
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-85": {
    ...base,
    auth: getAuthFromCPF("000.000.000-85"),
    term: {
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.PENDENTE,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "Emaildb22b6a8-ec84-4794-afbf-329f4c893b0e@gmail.com",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: "email2@gmail.com",
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: "Email09c99270-8423-4bb0-b87e-a16aae2d1c99@gmail.com",
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: "Email846189c7-7f78-460d-8865-1e170cddac36@gmail.com",
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-86": {
    ...base,
    auth: getAuthFromCPF("000.000.000-86"),
    term: {
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.PENDENTE,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "Emaildb22b6a8-ec84-4794-afbf-329f4c893b0e@gmail.com",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: "email2@gmail.com",
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: "Email09c99270-8423-4bb0-b87e-a16aae2d1c99@gmail.com",
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: null,
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-87": {
    ...base,
    auth: getAuthFromCPF("000.000.000-87"),
    term: {
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.PENDENTE,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "Emaildb22b6a8-ec84-4794-afbf-329f4c893b0e@gmail.com",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: "email2@gmail.com",
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: "Email09c99270-8423-4bb0-b87e-a16aae2d1c99@gmail.com",
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: "emailincorrect",
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-88": {
    ...base,
    auth: getAuthFromCPF("000.000.000-88"),
    term: {
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.PENDENTE_FISICO,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "Emaildb22b6a8-ec84-4794-afbf-329f4c893b0e@gmail.com",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: "email2@gmail.com",
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: "Email09c99270-8423-4bb0-b87e-a16aae2d1c99@gmail.com",
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: "emailincorrect",
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: false,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: false,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-89": {
    ...base,
    auth: getAuthFromCPF("000.000.000-89"),
    term: {
      dataRealizacao: "2023-04-08T00:00:00.0000000-03:00",
      statusRenegociacao: STATUS_TERM_PENDING.CONCLUIDO_FISICO,
      statusTermo: "AG_TMP_EST",
      tipoOperacao: "DESLIGAMENTO - Confissão de divida",
      tipoNegociacao: "TipoNegociacaoae4a5ad1-4c56-4ea6-abef-30154b628738",
      documentoSAP: {
        empresa: "Empresa9bf4f26d-75c0-48c3-ab0c-0a5bd0737db8",
        documento: "78427064;0",
        exercicio: "Exercicio31670966-7206-4afc-9821-ab6bd1dddf37",
        item: "Itemf88837d3-07dc-4217-8dc9-36e3624e99b4"
      },
      termo: {
        codRenTermo: "CodRenTermo6c51f69e-e0f1-4106-873e-3724a4505f1d",
        validadeDocumento: "2023-05-18",
        idEnvelope: "IdEnvelopedc77b05b-8ca8-460e-bc13-cce914bba2f8",
        urlAssinatura: "UrlAssinaturaf0ff373f-a6e8-49fd-b8fa-6f9064788c93",
        dataAssinaturaTermo:
          "DataAssinaturaTermocf9d9e7e-2e09-4f53-8532-4a58a108e8a8",
        horaAssinaturaTermo:
          "HoraAssinaturaTermoa818f232-7bd5-4993-acae-13185ad51bcb",
        signatarios: [
          {
            tipoAssinatura:
              "TipoAssinaturab94e9fe1-aca6-4ac0-be35-bd123d1f570f",
            clientePrincipal: true,
            nome: "Nomee85ffe88-24f7-4123-a3b2-384b46fecb3c",
            email: "Emaildb22b6a8-ec84-4794-afbf-329f4c893b0e@gmail.com",
            telefone: "Telefone3fd2c3c9-f034-4f91-a038-aec2982ccb3b",
            cpfCnpj: "CpfCnpja06ed01e-68a0-4f4a-9bfb-b612b24babd3",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura24045a49-4039-42b3-8a1e-07c8fac0d0e9",
            clientePrincipal: false,
            nome: "Nomee653c1db-1726-4427-874e-f9132a3ffec2",
            email: "email2@gmail.com",
            telefone: "Telefone6a4fa721-5755-45aa-87d7-7bc0331b4922",
            cpfCnpj: "CpfCnpjfbef396d-2ece-48c5-8ade-f8476d7a1c79",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinatura9984d078-52d0-4864-b7b8-8fef19a77a83",
            clientePrincipal: false,
            nome: "Nomef66e8187-96b6-4102-a6c5-64799809a6a8",
            email: "Email09c99270-8423-4bb0-b87e-a16aae2d1c99@gmail.com",
            telefone: "Telefone714c4935-acc3-4bf2-83a2-1d2a74308635",
            cpfCnpj: "CpfCnpj6c3c1159-c562-438f-ab74-3a3f58ccbc3c",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura:
              "TipoAssinaturabce82eda-3016-43c1-b9ed-1a9624b10f7e",
            clientePrincipal: false,
            nome: "Nome5f18f7e3-c060-4702-9ad3-e667da04e53f",
            email: "emailincorrect",
            telefone: "Telefonee9138f1c-b2eb-4913-a9a5-1d7e348aec2e",
            cpfCnpj: "CpfCnpje547733b-d49c-4783-8de0-8f37125cb4cd",
            assinado: true,
            dataAssinatura: null,
            horaAssinatura: null,
            isMrv: false
          },
          {
            tipoAssinatura: "D",
            clientePrincipal: false,
            nome: "Igara Pessoa",
            email: "igara@mrv.com.br",
            telefone: null,
            cpfCnpj: "61404942653",
            assinado: true,
            dataAssinatura: "00000000",
            horaAssinatura: "000000",
            isMrv: true
          }
        ]
      }
    },
    termUrlAssinatura: {
      id_envelope: "asdsdwewgdf434534",
      url_assinatura: "https://google.com.br"
    }
  },
  "000.000.000-90": {
    ...base,
    auth: getAuthFromCPF("000.000.000-90"),
    onboarding: {
      status: 200,
      data: { onboarding: false, indicacao: true, novidadeIndicacao: true }
    }
  }
};
